import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './Navigation.css';
import '.././SupplierPay/TreatmentComponent.css';
import './OtManagement.css';
import '../SupplierPay/Prescription.css'


const SupplierGrn = () => {
    const dispatchvalue = useDispatch();
    const toast = useSelector(state => state.userRecord?.toast);
    const UrlLink = useSelector(state => state.userRecord?.UrlLink);
    const Supplierpaydata = useSelector(state => state.Inventorydata?.Supplierpaydata);
    const navigate = useNavigate();

    const [supplierdetails, setSupplierdetails] = useState({
        suppliercode: '',
        suppliername: '',
        contactperson: '',
        contactnumber: '',
        mailid: '',
    });

    const [grndata, setGrndata] = useState([]);
    const [debitdata, setDebitdata] = useState([]);
    console.log("debitdata", debitdata);
    const [InvoiceTotalAmount, setInvoiceTotalAmount] = useState({
        TotalInvoiceAmount: 0,
    });
    const [TotalDebit, setTotalDebit] = useState(0);
    const [BalanceAmount, setBalanceAmount] = useState(0);




    useEffect(() => {

        const fetchGrnData = async () => {
            try {
                if ((Supplierpaydata && Object.keys(Supplierpaydata).length !== 0)) {
                    const response = await axios.get(`${UrlLink}Inventory/get_supplier_payment_details?suppliercode=${Supplierpaydata.SupplierCode}`);
                    const data = response.data;
                    console.log("datagrn", data)
                    if (Array.isArray(data)) {
                        setGrndata(data);
                    }
                    else {
                        setGrndata([])
                    }
                }
            } catch (error) {
                console.error("Error fetching grn data:", error);
            }

        };
        fetchGrnData();


    }, [UrlLink, Supplierpaydata])

    useEffect(() => {
        const fetchDebitData = async () => {
            try {
                if ((Supplierpaydata && Object.keys(Supplierpaydata).length !== 0)) {
                    const response = await axios.get(`${UrlLink}Inventory/get_supplier_paid_payment_details?suppliercode=${Supplierpaydata.SupplierCode}`);
                    let Objeget = response.data
                    console.log("debitdata", Objeget)
                    if (Objeget && Object.keys(Objeget).length !== 0) {
                        let getIncomeArr = Objeget.Debit || []
                        setDebitdata(getIncomeArr);
                    }
                    else {
                        setDebitdata([]);
                    }
                }

            } catch (error) {
                console.error("Error fetching debit data:", error);
            }
        }
        fetchDebitData();

    }, [UrlLink, Supplierpaydata])



    useEffect(() => {
        const fetchData = async () => {
            try {
                if (Supplierpaydata && Object.keys(Supplierpaydata).length !== 0) {
                    const response = await axios.get(`${UrlLink}Inventory/get_supplier_details?suppliercode=${Supplierpaydata.SupplierCode}`);
                    const data = response.data;

                    if (Array.isArray(data)) {
                        setSupplierdetails({
                            suppliercode: data[0].suppliercode || '',
                            suppliername: data[0].SupplierName || '',
                            contactperson: data[0].Contact_Person || '',
                            contactnumber: data[0].Contact_Number || '',
                            mailid: data[0].Email_Address || '',

                        });
                    } else {
                        setSupplierdetails({
                            suppliercode: '',
                            suppliername: '',
                            contactperson: '',
                            contactnumber: '',
                            mailid: '',

                        });
                    }
                } else {

                    navigate("/Home/CashierFolder");
                    dispatchvalue({ type: "HrFolder", value: "SupplierPayList" });


                }
            } catch (error) {
                console.error("Error fetching supplier data:", error);
            }
        };

        fetchData();
    }, [Supplierpaydata, UrlLink, navigate, dispatchvalue]);

    useEffect(() => {
        if (grndata && grndata.length !== 0) {
            setInvoiceTotalAmount({
                TotalInvoiceAmount: grndata.reduce((num, ele) => num += +ele.GRN_Invoice_Amount, 0).toFixed(2),
            })
        }

    }, [grndata])

    useEffect(() => {
        if (debitdata && debitdata.length !== 0) {
            const total = debitdata.reduce((sum, item) => {
                const ledgerTotal = item.ledgerarr.reduce((ledgerSum, ledger) => {
                    return ledgerSum + parseFloat(ledger.Amount || 0); // Convert Amount to a number
                }, 0);
                return sum + ledgerTotal;
            }, 0);
            setTotalDebit(total); // Set the total debit amount
        }
    }, [debitdata]);

    useEffect(() => {
        let balance = 0;
        if (TotalDebit !== 0 && InvoiceTotalAmount.TotalInvoiceAmount !== 0) {
            balance = InvoiceTotalAmount.TotalInvoiceAmount - TotalDebit;
        } else if (InvoiceTotalAmount.TotalInvoiceAmount) {
            balance = InvoiceTotalAmount.TotalInvoiceAmount;
        }

        // Ensure BalanceAmount is a number
        setBalanceAmount(Number(balance) || 0); // Fallback to 0 if balance is not a number
    }, [TotalDebit, InvoiceTotalAmount.TotalInvoiceAmount]);

    return (
        <div className="Main_container_app">
            <h3>Supplier Details</h3>
            <br />
            <div className="RegisFormcon_1">
                <div className="RegisForm_1">
                    <label>Supplier Code <span>:</span> </label>
                    <input name='suppliercode' value={supplierdetails.suppliercode} readOnly />
                </div>
                <div className="RegisForm_1">
                    <label>Supplier Name <span>:</span> </label>
                    <input name='suppliername' value={supplierdetails.suppliername} readOnly />
                </div>
                <div className="RegisForm_1">
                    <label>Contact Person <span>:</span> </label>
                    <input name='contactperson' value={supplierdetails.contactperson} readOnly />
                </div>
                <div className="RegisForm_1">
                    <label>Contact Number <span>:</span> </label>
                    <input name='contactnumber' value={supplierdetails.contactnumber} readOnly />
                </div>
                <div className="RegisForm_1">
                    <label>Email Address <span>:</span> </label>
                    <input name='mailid' value={supplierdetails.mailid} readOnly />
                </div>

            </div>
            <br />
            <div style={{ display: 'flex' }}>
                {
                    grndata.length !== 0 && <div className="for">
                        <div className="Selected-table-container">
                            <table className="selected-medicine-table2">
                                <thead>
                                    <tr>
                                        <th id="slectbill_ins" style={{ width: "100px", height: '30px' }}>S.No</th>
                                        <th id="slectbill_ins" style={{ width: "130px", height: '30px' }}>InvoiceDate</th>
                                        <th id="slectbill_ins" style={{ width: "130px", height: '30px' }}>InvoiceNo</th>
                                        <th id="slectbill_ins" style={{ width: "300px", height: '30px' }}>Particulars</th>
                                        <th id="slectbill_ins" style={{ width: "130px", height: '30px' }}>Credit</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {grndata.length !== 0 && grndata.map((item, index) => (
                                        <tr key={index}>
                                            <td style={{ width: "100px", height: '30px' }}>{item.id}</td>
                                            <td style={{ width: "130px", height: '30px' }}>{item.GRN_Invoice_Date}</td>
                                            <td style={{ width: "130px", height: '30px' }} className="cell_btn"
                                            //   onClick={() => ViewVocherDetailes(item)} 
                                            >{item.GRN_Invoice_No}</td>
                                            <td style={{ width: "300px", height: '30px' }}>{item.Particulars}</td>
                                            <td style={{ width: "130px", height: '30px' }}>{item.GRN_Invoice_Amount}</td>



                                        </tr>
                                    ))

                                    }
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td colSpan="4" style={{ textAlign: "right", fontWeight: "bold" }}>Total Amount:</td>
                                        <td style={{ width: "140px", fontWeight: "bold", height: '30px' }}>
                                            {InvoiceTotalAmount.TotalInvoiceAmount}
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>




                    </div>}
                {debitdata.length !== 0 && (
                    <div className="for">
                        <div className="Selected-table-container">
                            <table className="selected-medicine-table2">
                                <thead>
                                    <tr>
                                        <th id="slectbill_ins" style={{ width: "100px", height: '30px' }}>S.No</th>
                                        <th id="slectbill_ins" style={{ width: "130px", height: '30px' }}>InvoiceDate</th>
                                        <th id="slectbill_ins" style={{ width: "130px", height: '30px' }}>InvoiceNo</th>
                                        <th id="slectbill_ins" style={{ width: "300px", height: '30px' }}>Particulars</th>
                                        <th id="slectbill_ins" style={{ width: "130px", height: '30px' }}>Debit</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        debitdata.flatMap((item, index) =>
                                            item.ledgerarr.map((ledger, ledgerIndex) => {
                                                // Create a continuous index across all ledger items
                                                const continuousIndex = (debitdata.slice(0, index).reduce((acc, currentItem) => acc + currentItem.ledgerarr.length, 0)) + ledgerIndex + 1;

                                                return (
                                                    <tr key={`${index}-${ledgerIndex}`}>
                                                        <td style={{ width: "140px", height: '30px' }}>{continuousIndex}</td>
                                                        <td style={{ width: "140px", height: '30px' }}>{ledger.InvoiceDate}</td>
                                                        <td style={{ width: "140px", height: '30px' }}>{ledger.InvoiceNo}</td>
                                                        <td style={{ width: "300px", height: '30px' }}>{item.Particulars}</td>
                                                        <td style={{ width: "130px", height: '30px' }}>{ledger.Amount}</td>
                                                    </tr>
                                                );
                                            })
                                        )
                                    }
                                </tbody>


                                <tfoot>
                                    <tr>
                                        <td colSpan="4" style={{ textAlign: "right", fontWeight: "bold" }}>Total Amount:</td>
                                        <td style={{ width: "140px", fontWeight: "bold", height: '30px' }}>
                                            {TotalDebit.toFixed(2) || '0.00'}
                                        </td>

                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                )}





            </div>
            <br />

            <p style={{ width: "100%", fontWeight: "bold", height: '30px', alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                Balance Amount <span> :</span> {BalanceAmount ? BalanceAmount.toFixed(2) : '0.00'}
            </p>


        </div>
    );
};

export default SupplierGrn;
