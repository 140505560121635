import React, { useState, useEffect } from "react";
import HospitalDetials from "./HospitalMaster/HospitalDetials";
import UserRegistrationList from "./UserRegister/UserRegistrationList";
import { useDispatch, useSelector } from "react-redux";
import MedicinerackMaster from "./InventoryMaster/MedicinerackMaster";
import TrayManagementList from "./InventoryMaster/TrayManagementList";
import Productcategory from "./InventoryMaster/Productcategory";
import InventorySubMasters from "./InventoryMaster/InventorySubMasters";
import ProductMasterList from "./InventoryMaster/ProductMasterList";
import SupplierMasterList from "./InventoryMaster/SupplierMasterList";
import CustomerMasterList from "./InventoryMaster/CustomerMasterList";
import UserRegistration from "./UserRegister/UserRegistration";
import TrayManagement from "./InventoryMaster/TrayManagement";
import Productmaster from "./InventoryMaster/Productmaster";
import SupplierMaster from "./InventoryMaster/SupplierMaster";
import SupplierProductMap from "./InventoryMaster/SupplierProductMap";
import CustomerMaster from "./InventoryMaster/CustomerMaster";



const MasterFolder = () => {

  const activeHrFolder = useSelector((state) => state.userRecord?.HrFolder);
  const showMenu = useSelector((state) => state.userRecord?.showMenu);
  const dispatchvalue = useDispatch()

  const [showFolders, setShowFolders] = useState(false);


  const UserData = useSelector(state => state.userRecord?.UserData)

  const [subAccess, setSubAccess] = useState([])


  const Allpage=[
    { Id: 'A1-1', label: 'HospitalClinicMaster'},
    { Id: 'A1-2', label: 'UserRegistrationList'},
    { Id: 'A1-3', label: 'MedicinerackMaster'},
    { Id: 'A1-4', label: 'TrayManagementList'},
    { Id: 'A1-5', label: 'Productcategory'},
    { Id: 'A1-6', label: 'InventorySubMasters'},
    { Id: 'A1-7', label: 'ProductMasterList'},
    { Id: 'A1-8', label: 'SupplierMasterList'},
    { Id: 'A1-9', label: 'CustomerMasterList'},
  ]


  useEffect(() => {
    console.log(UserData, 'UserData')

    if (UserData) {

      const setAccess2 =
        (UserData.AccessTwo &&
          UserData.AccessTwo.split(',').map(item => item.trim())) ||
        []

      setSubAccess(setAccess2) 
    }
  }, [UserData]) // Dependency array

  const activeFolder = useSelector((state) => state.userRecord?.activeFolder);

  const handleFolderClick = (folderName) => { 

    dispatchvalue({ type: "setPreviousFolder", value: activeFolder }); // Save current folder
    dispatchvalue({ type: "setActiveFolder", value: folderName }); // Navigate to new folder
    dispatchvalue({ type: "showMenu", value: false });  // Close menu when navigating to homepage

  };


  // useEffect(() => {



  //   if (activeHrFolder !== "" && subAccess && subAccess.length !== 0) {

  //     console.log('activeHrFolder',activeHrFolder);

  //     const filteredLabels = Allpage
  //   .filter((page) => subAccess.includes(page.Id)) // Match IDs
  //   .map((page) => page.label);

  //   // console.log('subAccess',filteredLabels);

  //   let Openpage = ''
  //   if (filteredLabels.length !== 0){

  //     Openpage=filteredLabels[0] 
    
  //   }
  //   console.log('Openpage',Openpage);


  //     dispatchvalue({ type: "setActiveFolder", value: activeHrFolder });
  //     dispatchvalue({ type: "HrFolder", value: Openpage }); 
  //   }
  //   setShowFolders(true);

  // }, [subAccess, activeHrFolder ,dispatchvalue]);


  useEffect(() => {
 
    console.log(activeHrFolder, 'activeHrFolder');
 
    if (activeHrFolder !== "") {
      dispatchvalue({ type: "setActiveFolder", value: activeHrFolder });
      dispatchvalue({ type: "HrFolder", value: "" }); // Reset HrFolder
    }
    setShowFolders(true);
 
  }, [activeHrFolder, dispatchvalue]);

  const renderFolderContent = () => {
    switch (activeFolder) {
      case "HospitalClinicMaster":
        return <HospitalDetials />;
      case "UserRegistration":
        return <UserRegistration />;
      case "UserRegistrationList":
        return <UserRegistrationList />;
      case "MedicinerackMaster":
        return <MedicinerackMaster />;
      case "TrayManagementList":
        return <TrayManagementList />;
      case "TrayManagement":
        return <TrayManagement />;
      case "Productcategory":
        return <Productcategory />;
      case "InventorySubMasters":
        return <InventorySubMasters />;
      case "ProductMasterList":
        return <ProductMasterList />;
      case "Productmaster":
        return <Productmaster />;
      case "SupplierMasterList":
        return <SupplierMasterList />;
      case "SupplierMaster":
        return <SupplierMaster />;
      case "SupplierProductMap":
        return <SupplierProductMap />;
      case "CustomerMasterList":
        return <CustomerMasterList />;
      case "CustomerMaster":
        return <CustomerMaster />;
      default: return <HospitalDetials />;
    }
  };
  return (
    <div className="folder-container">


      <div className={`folder-box-container ${showFolders ? "animate-show" : ""}`}>



        {showMenu && subAccess.includes('A1-1') && (

          <div
            onClick={() => handleFolderClick("HospitalClinicMaster")}
            className="folder-box"
          >
            Warehouse Master
          </div>
        )}


        {showMenu && subAccess.includes('A1-2') && (
          <div
            onClick={() => handleFolderClick("UserRegistrationList")}
            className="folder-box"
          >
            User Registration
          </div>
        )}


        {showMenu && subAccess.includes('A1-3') && (
          <div
            onClick={() => handleFolderClick("MedicinerackMaster")}
            className="folder-box"
          >
            Medicine Rack Master
          </div>
        )}


        {showMenu && subAccess.includes('A1-4') && (
          <div
            onClick={() => handleFolderClick("TrayManagementList")}
            className="folder-box"
          >
            Shelf Management
          </div>
        )}

        {showMenu && subAccess.includes('A1-5') && (
          <div
            onClick={() => handleFolderClick("Productcategory")}
            className="folder-box"
          >
            Productcategory
          </div>
        )}

        {showMenu && subAccess.includes('A1-6') && (
          <div
            onClick={() => handleFolderClick("InventorySubMasters")}
            className="folder-box"
          >
            Product Field Master
          </div>
        )}


        {showMenu && subAccess.includes('A1-7') && (
          <div
            onClick={() => handleFolderClick("ProductMasterList")}
            className="folder-box"
          >
            Product Master
          </div>
        )}


        {showMenu && subAccess.includes('A1-8') && (
          <div
            onClick={() => handleFolderClick("SupplierMasterList")}
            className="folder-box"
          >
            Supplier Master
          </div>
        )}
        {showMenu && subAccess.includes('A1-9') && (
          <div
            onClick={() => handleFolderClick("CustomerMasterList")}
            className="folder-box"
          >
            Customer Master
          </div>
        )}



      </div>



      {renderFolderContent()}
    </div>


  )
};

export default MasterFolder;
