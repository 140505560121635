import React, { useCallback, useEffect, useRef, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import debounce from "lodash.debounce";
import Homepage from "./Homepage/Homepage";
import NotFound from "./Homepage/404Page";
import LoginPage from "./Homepage/LoginPage/LoginPage";
import Footer from "./Homepage/Footer/Footer";
import NewHeader from "./Homepage/Header/NewHeader";
import HomePage from "./Homepage/Homepage";
import MasterFolder from "./Masters/MasterFolder";
import './FolderBox.css';

import UserRegistration from "./Masters/UserRegister/UserRegistration";
import UserRegistrationList from "./Masters/UserRegister/UserRegistrationList";
import TrayManagement from "./Masters/InventoryMaster/TrayManagement";
import TrayManagementList from "./Masters/InventoryMaster/TrayManagementList";
import Productcategory from "./Masters/InventoryMaster/Productcategory";
import InventorySubMasters from "./Masters/InventoryMaster/InventorySubMasters";

import ProductMasterList from "./Masters/InventoryMaster/ProductMasterList";
import Productmaster from "./Masters/InventoryMaster/Productmaster";

import SupplierMaster from "./Masters/InventoryMaster/SupplierMaster";
import SupplierMasterList from "./Masters/InventoryMaster/SupplierMasterList";
import SupplierProductMap from "./Masters/InventoryMaster/SupplierProductMap";

import CustomerMasterList from "./Masters/InventoryMaster/CustomerMasterList";
import CustomerMaster from "./Masters/InventoryMaster/CustomerMaster";


import InventoryFolder from "./Inventory/InventoryFolder";

import PurchaseOrder from "./Inventory/PurchaseOrder/PurchaseOrder";
import PurchaseOrderList from "./Inventory/PurchaseOrder/PurchaseOrderList";
import ItemwisePurchaseOrder from "./Inventory/PurchaseOrder/ItemwisePurchaseOrder";

import GoodsReceiptNoteList from "./Inventory/GRN/GoodsReceiptNoteList";
import GoodsReceiptNote from "./Inventory/GRN/GoodsReceiptNote";
import QuickGoodsRecieptNote from "./Inventory/GRN/QuickGoodsRecieptNote";

import PurchaseReturn from "./Inventory/PurchaseReturn/PurchaseReturn";
import PurchaseReturnList from "./Inventory/PurchaseReturn/PurchaseReturnList";

import SerialNoQuelist from "./Inventory/SerialNoForProduct/SerialNoQuelist";
import SerialNoReport from "./Inventory/SerialNoForProduct/SerialNoReport";
import SerialNoAssignPage from "./Inventory/SerialNoForProduct/SerialNoAssignPage";


import SupplierPay from "./Inventory/SupplierPay/SupplierPay";
import SupplierPayList from "./Inventory/SupplierPay/SupplierPayList";
import SupplierPaidList from "./Inventory/SupplierPay/SupplierPaidList";


import CurrentStocklist from "./Inventory/StockDetials/CurrentStocklist";

import CashierFolder from "./Cashier/CashierFolder";
import PharmacyBillingNew from "./Cashier/Billing/PharmacyBillingNew";
import SupplierGrn from "./Inventory/SupplierPay/SupplierGrn";
import BillingList from "./Cashier/Billing/BillingList";
import CustomerPay from "./Cashier/CustomerPay/CustomerPay";
import CustomerPayList from "./Cashier/CustomerPay/CustomerPayList";
import SalesReturnList from "./Inventory/SalesReturn/SalesReturnList";
import SalesReturn from "./Inventory/SalesReturn/SalesReturn";


function App() {

  const inputsRef = useRef([]);
  const dispatchvalue = useDispatch();


  const navigate = useNavigate();
  const location = useLocation();
  const isLoginPage = location.pathname === "/";


  const [sessiontokenid, setSessionid] = useState(null);

  const UrlLink = useSelector((state) => state.userRecord?.UrlLink);


  
  const getsessionid = useCallback(() => {
    const storedToken = localStorage.getItem("Chrrtoken");

    // Allow unrestricted access to Home/Registration Ownregistration
    if (location.pathname === "/Home/Ownregistration") {
      console.log("QR scan path detected, bypassing token check.");

      return; // Skip token validation
    }

    if (storedToken) {
      const decodedToken = (token) => {
        const payloadBase64 = token.split(".")[1];
        const decodedPayload = atob(payloadBase64);
        return JSON.parse(decodedPayload);
      };
      const decodedTokenid = decodedToken(storedToken);
      setSessionid(decodedTokenid.session_id);
      console.log("decodedTokenData", decodedTokenid);
      dispatchvalue({ type: "Usersessionid", value: decodedTokenid });
    } else {
      if (location.pathname !== "/") {
        navigate("/"); // Redirect to login page if no token
      }
    }
  }, [navigate, location.pathname, dispatchvalue]);


  const getuserrecord = useCallback(() => {
    console.log(sessiontokenid);
    if (sessiontokenid) {
      console.log("jksjsjsjk");
      axios
        .get(`${UrlLink}Masters/get_data?sessionid=${sessiontokenid}`)
        .then((response) => {
          const data1 = response.data.data;

          // Assuming data1 is the token

          dispatchvalue({ type: "UserData", value: data1 });
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          // Handle error here, maybe set an error state or show a message
        });
    } else {
      if (location.pathname !== "/") {
        navigate("/");
      }
    }
  }, [sessiontokenid, UrlLink, navigate, location.pathname, dispatchvalue]);


  useEffect(() => {
    axios
      .get(`${UrlLink}Masters/Hospital_Detials_link`)
      .then((response) => {
        if (response.data) {
          const { hospitalName, hospitalLogo, HospitalId } = response.data;
          if (hospitalName && hospitalLogo && HospitalId) {
            const datass = {
              id: HospitalId,
              Cname: hospitalName,
              Clogo: `data:image/*;base64,${hospitalLogo}`,
            };
            dispatchvalue({ type: "ClinicDetails", value: datass });
          } else {
            console.log("Data is null or incomplete");
          }
        } else {
          console.log("Response data is null");
          const datass = {
            id: "",
            Cname: "",
            Clogo: null,
          };
          dispatchvalue({ type: "ClinicDetails", value: datass });
        }
      })
      .catch((error) => console.error("Error fetching data: ", error));
  }, [dispatchvalue, UrlLink]);

  useEffect(() => {
    const loc = location.pathname;

    if (loc === "/") {
      localStorage.clear();
    }

    getsessionid();

    if (loc === "/Home/Ownregistration") {
      console.log("Navigating to Registration page.");
      navigate("/Home/Ownregistration");

    }
    else if (loc === '/Feedbackform') {
      navigate("/Feedbackform");
    }
    else if (sessiontokenid) {
      getuserrecord();
    }
  }, [
    location.pathname,
    navigate,
    getsessionid,
    getuserrecord,
    sessiontokenid,
  ]);

  useEffect(() => {
    const inputs = inputsRef.current.querySelectorAll("input");
    inputs.forEach((input) => {
      // Disable autocomplete
      input.setAttribute("autocomplete", "off");

      // Prevent copying
      input.addEventListener("copy", (event) => {
        event.preventDefault();
      });

      // Prevent text selection
      input.addEventListener("selectstart", (event) => {
        event.preventDefault();
      });
    });

    // Clean up event listeners on component unmount
    return () => {
      inputs.forEach((input) => {
        input.removeEventListener("copy", (event) => {
          event.preventDefault();
        });

        input.removeEventListener("selectstart", (event) => {
          event.preventDefault();
        });
      });
    };
  }, []);

  useEffect(() => {
    const handleResize = debounce(() => {
      if (inputsRef.current) {
        // Adjusting the grid ref without forceUpdate
        const width = inputsRef.current.offsetWidth;
        dispatchvalue({ type: "pagewidth", value: width });
      }
    }, 100);

    const currentGridRef = inputsRef.current;
    const resizeObserver = new ResizeObserver(handleResize);
    if (currentGridRef) {
      resizeObserver.observe(currentGridRef);
    }

    return () => {
      if (currentGridRef) {
        resizeObserver.unobserve(currentGridRef);
      }
      resizeObserver.disconnect();
    };
  }, []);


  return (
   <div ref={inputsRef} className="app-container-newM">
      {(!isLoginPage) && <NewHeader />}

      <div className="content-container-newM">
      <Routes>

        <Route path="/Home" element={<HomePage />} />
        <Route path="/Home/Master" element={<MasterFolder />} />



        {/* {Master} */}
       <Route path="/Home/UserRegistration" element={<UserRegistration />} />
       <Route path="/Home/UserRegistrationList" element={<UserRegistrationList />} />

       <Route path="/Home/TrayManagement" element={<TrayManagement />} />
       <Route path="/Home/TrayManagementList" element={<TrayManagementList />} />

       <Route path="/Home/Productcategory" element={<Productcategory />} />
       <Route path="/Home/InventorySubMasters" element={<InventorySubMasters />} />

       <Route path="/Home/ProductMasterList" element={<ProductMasterList />} />
       <Route path="/Home/Productmaster" element={<Productmaster />} />
      
       <Route path="/Home/SupplierMasterList" element={<SupplierMasterList />} />
       <Route path="/Home/SupplierMaster" element={<SupplierMaster />} />
       <Route path="/Home/SupplierProductMap" element={<SupplierProductMap />} />

       <Route path="/Home/CustomerMasterList" element={<CustomerMasterList />} />
       <Route path="/Home/CustomerMaster" element={<CustomerMaster />} />


       <Route path="/Home/InventoryMaster" element={<InventoryFolder />} />

       <Route path="/Home/PurchaseOrder" element={<PurchaseOrder />} />
       <Route path="/Home/PurchaseOrderList" element={<PurchaseOrderList />} />
       <Route path="/Home/ItemwisePurchaseOrder" element={<ItemwisePurchaseOrder />} />
        
       <Route path="/Home/GoodsReceiptNoteList" element={<GoodsReceiptNoteList />} />
       <Route path="/Home/GoodsReceiptNote" element={<GoodsReceiptNote />} />
       <Route path="/Home/QuickGoodsRecieptNote" element={<QuickGoodsRecieptNote />} />
        
       <Route path="/Home/PurchaseReturn" element={<PurchaseReturn />} />
       <Route path="/Home/PurchaseReturnList" element={<PurchaseReturnList />} />

       <Route path="/Home/SerialNoQuelist" element={<SerialNoQuelist />} />
       <Route path="/Home/SerialNoReport" element={<SerialNoReport />} />
       <Route path="/Home/SerialNoAssignPage" element={<SerialNoAssignPage />} />
       
       <Route path="/Home/SupplierPay" element={<SupplierPay />} />
       <Route path="/Home/SupplierPayList" element={<SupplierPayList />} />
       <Route path="/Home/SupplierPaidList" element={<SupplierPaidList />} />


       <Route path="/Home/SupplierGrn" element={<SupplierGrn />} />

       <Route path="/Home/CurrentStocklist" element={<CurrentStocklist />} />



      <Route path="/Home/CashierFolder" element={<CashierFolder />} />
      <Route path="/Home/PharmacyBillingNew" element={<PharmacyBillingNew />} />
      <Route path="/Home/BillingList" element={<BillingList />} />

      <Route path="/Home/CustomerPay" element={<CustomerPay />} />
      <Route path="/Home/CustomerPayList" element={<CustomerPayList />} />

      

      <Route path="/Home/SalesReturnList" element={<SalesReturnList />} />
      <Route path="/Home/SalesReturn" element={<SalesReturn />} />




          <Route path="/" element={<LoginPage />} />
          {/* NotFound */}
          <Route path="*" element={<NotFound />} />
      </Routes>

      </div>
      {(!isLoginPage) && <Footer />}
    </div>
  );
}

export default App;
