import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import PharmacyBillingNew from "./Billing/PharmacyBillingNew";
import BillingList from "./Billing/BillingList";
import SupplierPayList from "../Inventory/SupplierPay/SupplierPayList";
import SupplierPaidList from "../Inventory/SupplierPay/SupplierPaidList";
import SupplierPay from "../Inventory/SupplierPay/SupplierPay";
import SupplierGrn from "../Inventory/SupplierPay/SupplierGrn";
import CustomerPayList from "./CustomerPay/CustomerPayList";
import CustomerPay from "./CustomerPay/CustomerPay";
import CustomerPaidList from "./CustomerPay/CustomerPaidList";


const CashierFolder = () => {
  const activeHrFolder = useSelector((state) => state.userRecord?.HrFolder)
  const showMenu = useSelector((state) => state.userRecord?.showMenu);

  const dispatchvalue = useDispatch()

  // const [activeFolder, setActiveFolder] = useState("CashierDashboard");
  const [showFolders, setShowFolders] = useState(false); // State to control folder display animation

  const UserData = useSelector(state => state.userRecord?.UserData)

  const [subAccess, setSubAccess] = useState([])

  const Allpage=[
    { Id: 'C3-1', label: 'BillingList'},
    { Id: 'C3-2', label: 'SupplierPayList'},
    { Id: 'C3-3', label: 'SupplierPaidList'},
    { Id: 'C3-4', label: 'CustomerPayList'},
    { Id: 'C3-5', label: 'CustomerPaidList'}
]

  useEffect(() => {
    console.log(UserData, 'UserData')

    if (UserData) {

      const setAccess2 =
        (UserData.AccessTwo &&
          UserData.AccessTwo.split(',').map(item => item.trim())) ||
        []

      setSubAccess(setAccess2) // Update state for sub access
    }
  }, [UserData]) // Dependency array



  const activeFolder = useSelector((state) => state.userRecord?.activeFolder);

  const handleFolderClick = (folderName) => {

    dispatchvalue({ type: "setPreviousFolder", value: activeFolder }); // Save current folder
    dispatchvalue({ type: "setActiveFolder", value: folderName }); // Navigate to new folder
    dispatchvalue({ type: "showMenu", value: false });  // Close menu when navigating to homepage

  };

  // useEffect(() => {

  //   console.log(activeHrFolder, 'activeHrFolder');

  //   if (Allpage && subAccess && subAccess.length !== 0) {

  //     const filteredLabels = Allpage
  //     .filter((page) => subAccess.includes(page.Id))
  //     .map((page) => page.label);

  //       let Openpage = 'BillingList'
  //       if (filteredLabels.length !== 0){

  //         Openpage=filteredLabels[0] 
        
  //       }

  //     dispatchvalue({ type: "setActiveFolder", value: Openpage });
  //     dispatchvalue({ type: "HrFolder", value: "" }); // Reset HrFolder
  //   }
  //   setShowFolders(true);

  // }, [subAccess, Allpage , dispatchvalue]);


  useEffect(() => {
 
    console.log(activeHrFolder, 'activeHrFolder');
 
    if (activeHrFolder !== "") {
      dispatchvalue({ type: "setActiveFolder", value: activeHrFolder });
      dispatchvalue({ type: "HrFolder", value: "" }); // Reset HrFolder
    }
    setShowFolders(true);
 
  }, [activeHrFolder, dispatchvalue]);

  const renderFolderContent = () => {
    switch (activeFolder) {
      case "BillingList":
        return <BillingList />;
      case "PharmacyBillingNew":
        return <PharmacyBillingNew />;
      case "SupplierPayList":
        return <SupplierPayList />;
      case "SupplierPay":
        return <SupplierPay />;
      case "SupplierPaidList":
        return <SupplierPaidList />;
      case "SupplierGrn":
        return <SupplierGrn />;
      case "CustomerPayList":
        return <CustomerPayList />;
      case "CustomerPay":
        return <CustomerPay />;
      case "CustomerPaidList":
        return <CustomerPaidList />;
      default:
        return <BillingList />;
    }
  };
  return (

    <div className="folder-container">

      <div className={`folder-box-container ${showFolders ? "animate-show" : ""}`}>




        {showMenu && subAccess.includes('C3-1') && (
          <div
            onClick={() => handleFolderClick("BillingList")}
            className="folder-box"
          >
            Billing
          </div>
        )}

        {showMenu && subAccess.includes('C3-2') && (


          <div onClick={() => handleFolderClick("SupplierPayList")}
            className="folder-box" >Supplier Pay List</div>

        )}
        {showMenu && subAccess.includes('C3-3') && (


          <div onClick={() => handleFolderClick("SupplierPaidList")}
            className="folder-box" >Supplier Paid List</div>

        )}

{showMenu && subAccess.includes('C3-4') && (


<div onClick={() => handleFolderClick("CustomerPayList")}
  className="folder-box" >Customer Pay List</div>

)}


{showMenu && subAccess.includes('C3-5') && (


<div onClick={() => handleFolderClick("CustomerPaidList")}
className="folder-box" >Customer Paid List</div>

)}







      </div>

      <br />
      {renderFolderContent()}
    </div>
  );
};

export default CashierFolder;
