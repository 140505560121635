import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ToastAlert from '../../OtherComponent/ToastContainer/ToastAlert';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import EditIcon from "@mui/icons-material/Edit";
import Button from "@mui/material/Button";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ReactGrid from '../../OtherComponent/ReactGrid/ReactGrid';


const SalesReturn = () => {

    const dispatchvalue = useDispatch();

    const navigate = useNavigate();

    const today = new Date();
    const currentDate = today.toISOString().split('T')[0];

    const UrlLink = useSelector(state => state.userRecord?.UrlLink);
    const toast = useSelector(state => state.userRecord?.toast);
    const userRecord = useSelector((state) => state.userRecord?.UserData);

    const EdittData = useSelector((state) => state.Inventorydata?.SalesReturnList);

    // console.log('EdittData',EdittData);

    const [Customerarr, setCustomerarr] = useState([])


    const [BillDetailState, setBillDetailState] = useState([])

    const [BillItemState, setBillItemState] = useState([])

    const [ReturnItemList, setReturnItemList] = useState([])

    // console.log('ReturnItemList',ReturnItemList);

    const [CustomerDetailes, setCustomerDetailes] = useState({
        ReturnDate: currentDate,
        CustomerCode: '',
        CustomerName: '',
        CustomerMailId: '',
        CustomerContactNumber: '',
        CustomerContactPerson: '',
    })

    const [ReturnItemState, setReturnItemState] = useState({
        BillInvoiceNo: '',
        BillDate: '',
        Reason: '',
        ItemCode: '',
        ItemName: '',

    })

    const [ReturnQtyState, setReturnQtyState] = useState({
        SalesAmount: '',
        AvailablePackQuantity: '',
        ReturnQuantity: '',
        ReturnPackQuantity: '',
        ReturnQuantityAmount: '',
        Remarks: '',
    })

    const [FinalState, setFinalState] = useState({
        ReturnTotalItem: '',
        ReturnTotalQuantity: '',
        ReturnTotalAmount: '',
    })

    const Clear_setReturnItemState = () => {

        setReturnItemState({
            BillInvoiceNo: '',
            BillDate: '',
            Reason: '',
            ItemCode: '',
            ItemName: '',
        })

    }

    const Clear_setReturnQtyState = () => {

        setReturnQtyState({
            SalesAmount: '',
            AvailablePackQuantity: '',
            ReturnQuantity: '',
            ReturnPackQuantity: '',
            ReturnQuantityAmount: '',
            Remarks: '',
        })
    }

    const clear_finalstate = () => {

        setReturnItemList([])
        setFinalState({
            ReturnTotalItem: '',
            ReturnTotalQuantity: '',
            ReturnTotalAmount: '',
        })
    }


    const formatLabel = (label) => {

        if (/[a-z]/.test(label) && /[A-Z]/.test(label) && !/\d/.test(label)) {
            return label
                .replace(/([a-z])([A-Z])/g, "$1 $2")
                .replace(/^./, (str) => str.toUpperCase());
        } else {
            return label;
        }
    };


    useEffect(() => {
        if (ReturnItemList && ReturnItemList.length !== 0) {

            let TotalQty = ReturnItemList.reduce((acc, item) => acc + (+item.ReturnQuantity || 0), 0)

            let TotalNetAmount = ReturnItemList.reduce((acc, item) => acc + (+item.ReturnQuantityAmount || 0), 0)

            setFinalState({
                ReturnTotalItem: ReturnItemList.length,
                ReturnTotalQuantity: TotalQty,
                ReturnTotalAmount: TotalNetAmount,
            })
        }
        else {
            setFinalState({
                ReturnTotalItem: '',
                ReturnTotalQuantity: '',
                ReturnTotalAmount: '',
            })
        }
    }, [ReturnItemList])



    useEffect(() => {
        axios.get(`${UrlLink}Inventory/Customer_Master_Link`)
            .then((res)=>{
                
                let getdata=res.data.customers;
                
                console.log('getdata------',getdata);
                
                setCustomerarr(getdata && Array.isArray(getdata) ? getdata : [])
            })
            .catch((err)=>{
                console.log(err);            
            })


    }, [UrlLink])




    useEffect(() => {

        if (CustomerDetailes?.CustomerCode !== '') {

            axios.get(`${UrlLink}Inventory/Bill_Details_GET_For_PurchseReturn?CustomerCode=${CustomerDetailes.CustomerCode}`)
                .then((res) => {
                    console.log(res.data);
                    let data = res.data
                    setBillDetailState(data && Array.isArray(data) ? data : [])
                })
                .catch((err) => {
                    console.log(err);
                })
        }

    }, [CustomerDetailes.CustomerCode])






    useEffect(() => {

        if (ReturnItemState.BillInvoiceNo !== '' && ReturnItemState.Reason !== '') {
            axios.get(`${UrlLink}Inventory/Bill_Details_GET_For_PurchseReturn?BillInvoiceNo=${ReturnItemState.BillInvoiceNo}&Reason=${ReturnItemState.Reason}`)
                .then((res) => {
                    console.log(res.data);
                    let data = res.data
                    setBillItemState(data && Array.isArray(data) ? data : [])
                })
                .catch((err) => {
                    console.log(err);
                })
        }
    }, [ReturnItemState.BillInvoiceNo, ReturnItemState.Reason])



    //   ------------


    useEffect(() => {
        if (EdittData && Object.keys(EdittData).length !== 0) {
            console.log('EdittData', EdittData);

            setCustomerDetailes({
                // SalesReturnNumber:EdittData.id,
                ReturnDate: EdittData.ReturnDate,
                CustomerCode: EdittData.CustomerCode,
                CustomerName: EdittData.CustomerName,
                CustomerMailId: EdittData.CustomerMailId,
                CustomerContactNumber: EdittData.CustomerContactNumber,
                CustomerContactPerson: EdittData.CustomerContactPerson,
            })

            setFinalState({
                ReturnTotalItem: EdittData.ReturnTotalItem,
                ReturnTotalQuantity: EdittData.ReturnTotalQuantity,
                ReturnTotalAmount: EdittData.ReturnTotalAmount,
            })


            setReturnItemList(EdittData.Items ? EdittData.Items : [])
        }
    }, [EdittData])



    

    const HandleonchangeCustomerState = async (e) => {

        const { name, value } = e.target;

        Clear_setReturnItemState()
        Clear_setReturnQtyState()
        clear_finalstate()

        if (name === 'CustomerCode') {

            setCustomerDetailes((prev) => ({
                ...prev,
                [name]: value,
                CustomerName: '',
                CustomerMailId: '',
                CustomerContactNumber: '',
                CustomerContactPerson: '',
            }))


        }
        else if (name === 'CustomerName') {

            setCustomerDetailes((prev) => ({
                ...prev,
                [name]: value,
                CustomerCode: '',
                CustomerMailId: '',
                CustomerContactNumber: '',
                CustomerContactPerson: '',
            }))

        }
        else {
            setCustomerDetailes((prev) => ({
                ...prev,
                [name]: value,
            }))
        }

    }



    const HandelOnchaneItemState = (e) => {
        const { name, value } = e.target
        Clear_setReturnQtyState()

        if (name === 'Reason') {

            setReturnItemState((prev) => ({
                BillInvoiceNo: prev.BillInvoiceNo,
                BillDate: prev.BillDate,
                [name]: value,
                ItemCode: '',
                ItemName: '',
            }))

        }
        else if (name === 'BillInvoiceNo') {



            setReturnItemState({
                BillInvoiceNo: value,
                BillDate: '',
                Reason: '',
                ItemCode: '',
                ItemName: '',
            })

        }

        else if (name === 'ItemCode') {

            setReturnItemState((prev) => ({
                BillInvoiceNo: prev.BillInvoiceNo,
                BillDate: prev.BillDate,
                Reason: prev.Reason,
                [name]: value,
                ItemName: '',
            }))


        }

        else if (name === 'ItemName') {

            setReturnItemState((prev) => ({
                BillInvoiceNo: prev.BillInvoiceNo,
                BillDate: prev.BillDate,
                Reason: prev.Reason,
                ItemCode: '',
                [name]: value,
            }))

        }

        else {
            setReturnItemState((prev) => ({
                ...prev,
                [name]: value
            }))
        }

    }


    const HandelOnchaneReturnQty = (e) => {
        const { name, value } = e.target;

        if (name === 'ReturnQuantity') {

            if (+value * +ReturnItemState?.PackQuantity > ReturnQtyState.AvailablePackQuantity) {
                const tdata = {
                    message: `Return Quantity Greater than Available Quantity`,
                    type: 'warn',
                }
                dispatchvalue({ type: 'toast', value: tdata });

            }
            else {
                setReturnQtyState((prev) => ({
                    ...prev,
                    [name]: value,
                    ReturnPackQuantity: +value * +ReturnItemState?.PackQuantity,
                    ReturnQuantityAmount: (+value * +ReturnQtyState?.SalesAmount).toFixed(2),
                }))
            }

        } else {
            setReturnQtyState((prev) => ({
                ...prev,
                [name]: value,
            }))
        }
    }


    const handleSearchCustomerlist = async (conditions) => {

        if (conditions === 'CustomerCode') {

            let find = Customerarr.find(ele => ele.CustomerId === CustomerDetailes.CustomerCode);

            console.log('findddd',find);
            

            if (find) {
               
                setCustomerDetailes((prev) => ({
                    ...prev,
                    CustomerName: find.CustomerName,
                    CustomerMailId: find?.EmailAddress || '',
                    CustomerContactNumber: find?.ContactNumber || '',
                    CustomerContactPerson: find?.ContactPerson || '',
                }))
            }
            else {

                const tdata = {
                    message: `Customer Was Not Found`,
                    type: 'warn',
                }
                dispatchvalue({ type: 'toast', value: tdata });

            }
        }

        else if (conditions === 'CustomerName') {

            let find = Customerarr.find(ele => ele.CustomerName === CustomerDetailes.CustomerName);

            if (find) {

                setCustomerDetailes((prev) => ({
                    ...prev,
                    CustomerCode: find.id,
                    CustomerMailId: find?.EmailAddress || '',
                    CustomerContactNumber: find?.ContactNumber || '',
                    CustomerContactPerson: find?.ContactPerson || '',
                }))
            }
            else {

                const tdata = {
                    message: `Customer Was Not Found`,
                    type: 'warn',
                }
                dispatchvalue({ type: 'toast', value: tdata });

            }

        }

    }


    const handleSearchBilldetailes = () => {

        let find = BillDetailState.find(ele => '' + ele.BillNumber === '' + ReturnItemState.BillInvoiceNo)

        if (find) {

            setReturnItemState({
                BillInvoiceNo: find.BillNumber,
                BillDate: find.BillDate,
                Reason: '',
                ItemCode: '',
                ItemName: '',
            })

        }
        else {

            const tdata = {
                message: `Bill Was Not Found`,
                type: 'warn',
            }
            dispatchvalue({ type: 'toast', value: tdata });

        }
    }

    const handleSearchItemDetails = (condition) => {

        if (condition === 'ItemCode') {

            let find = BillItemState.find(ele => '' + ele.ItemCode === '' + ReturnItemState.ItemCode)

            if (find) {

                const { ItemCode, ItemName, AvailableQuantity, SingleProductSalesAmount, ...prevlist } = find

                setReturnItemState((prev) => ({
                    BillInvoiceNo: prev.BillInvoiceNo,
                    BillDate: prev.BillDate,
                    Reason: prev.Reason,
                    ItemCode: ItemCode,
                    ItemName: ItemName,
                    ...prevlist,
                }))

                if (AvailableQuantity) {
                    setReturnQtyState((prev) => ({
                        ...prev,
                        SalesAmount: SingleProductSalesAmount,
                        AvailablePackQuantity: AvailableQuantity,
                    }))
                }


            }
            else {

                const tdata = {
                    message: `Bill Item Was Not Found`,
                    type: 'warn',
                }
                dispatchvalue({ type: 'toast', value: tdata });

            }

        }
        else if (condition === 'ItemName') {

            let find = BillItemState.find(ele => ele.ItemName === ReturnItemState.ItemName)

            if (find) {
                const { ItemCode, ItemName, AvailableQuantity, SingleProductSalesAmount, ...prevlist } = find

                setReturnItemState((prev) => ({
                    BillInvoiceNo: prev.BillInvoiceNo,
                    BillDate: prev.BillDate,
                    Reason: prev.Reason,
                    ItemCode: ItemCode,
                    ItemName: ItemName,
                    ...prevlist,
                }))

                if (AvailableQuantity) {
                    setReturnQtyState((prev) => ({
                        ...prev,
                        SalesAmount: SingleProductSalesAmount,
                        AvailablePackQuantity: AvailableQuantity,
                    }))
                }
            }
            else {

                const tdata = {
                    message: `Bill Item Was Not Found`,
                    type: 'warn',
                }
                dispatchvalue({ type: 'toast', value: tdata });

            }

        }


    }

    const SaveSalesReturnItem = () => {

        let requiredFields = [
            'SalesAmount',
            'AvailablePackQuantity',
            'ReturnQuantity',
            'ReturnPackQuantity',
            'ReturnQuantityAmount',
            'Remarks',
        ]
        let missingFields = requiredFields.filter(
            (field) => !ReturnQtyState[field]
        )

        const CheckDub = ReturnItemList.some(
            (product) => '' + product.ItemCode === '' + ReturnItemState.ItemCode
                && product?.id !== ReturnItemState?.id && product?.BillInvoiceNo === ReturnItemState.BillInvoiceNo
                && product.BatchNo === ReturnItemState?.BatchNo)

        if (missingFields.length !== 0) {

            const tdata = {
                message: `Please fill out all required fields: ${missingFields.join(", ")}`,
                type: 'warn',
            }
            dispatchvalue({ type: 'toast', value: tdata });

        }
        else if (CheckDub) {

            const tdata = {
                message: `This Item Already Entered BillInvoiceNo :${ReturnItemState.BillInvoiceNo},ItemName :${ReturnItemState.ItemName} and BatchNo:${ReturnItemState.BatchNo}`,
                type: 'warn',
            }
            dispatchvalue({ type: 'toast', value: tdata });
        }
        else {

            if (ReturnItemState.id) {

                setReturnItemList((prev) =>
                    prev.map((product) =>
                        product.id === ReturnItemState.id ? { ...ReturnItemState, ...ReturnQtyState } : product)
                )

                Clear_setReturnItemState()
                Clear_setReturnQtyState()

            }

            else {

                setReturnItemList((prev) =>
                    [...prev, { ...ReturnItemState, ...ReturnQtyState, id: prev.length + 1 }]
                )

                Clear_setReturnItemState()
                Clear_setReturnQtyState()

            }



        }

    }


    const handleDeleteItem = (row) => {

        Clear_setReturnItemState()
        Clear_setReturnQtyState()

        const updatedArray = ReturnItemList.filter((ele) => ele.id !== row.id);

        const reindexedArray = updatedArray.map((item, index) => ({
            ...item,
            id: index + 1,
        }));

        setReturnItemList(reindexedArray)

        const tdata = {
            message: `${row.ItemName} has been deleted successfully.`,
            type: 'success'
        };
        dispatchvalue({ type: 'toast', value: tdata });

    }


    const HandelEditdata = (row) => {

        const { id, SalesAmount,
            AvailablePackQuantity,
            ReturnQuantity,
            ReturnPackQuantity,
            ReturnQuantityAmount,
            Remarks, ...rest } = row;

        setReturnItemState({
            id: id,
            ...rest
        })

        setReturnQtyState({
            SalesAmount: SalesAmount,
            AvailablePackQuantity: AvailablePackQuantity,
            ReturnQuantity: ReturnQuantity,
            ReturnPackQuantity: ReturnPackQuantity,
            ReturnQuantityAmount: ReturnQuantityAmount,
            Remarks: Remarks,
        })


    }

    const ProductListColumn = [

        {
            key: 'id',
            name: 'S.No',
            frozen: true
        },
        {
            key: 'BillInvoiceNo',
            name: 'BillInvoiceNo',
            frozen: true
        },
        {
            key: 'ItemCode',
            name: 'Item Code',
            frozen: true
        },
        {
            key: 'ItemName',
            name: 'Item Name',
            frozen: true
        },
        {
            key: 'GenericName',
            name: 'Generic Name'
        },
        {
            key: 'CompanyName',
            name: 'Manufacturer Name'
        },
        {
            key: 'HSNCode',
            name: 'HSN Code'
        },
        {
            key: 'Strength',
            name: 'Strength'
        },
        {
            key: 'Volume',
            name: 'Volume',
        },
        {
            key: 'PackType',
            name: 'Pack Type',
        },
        {
            key: 'PackQuantity',
            name: 'Pack Quantity',
        },
        {
            key: 'SalesRateWithTax',
            name: 'Sales Rate With Tax'
        },
        {
            key: 'SalesQuantity',
            name: 'Sales Quantity'
        },
        {
            key: 'TotalAmount',
            name: 'Total Amount'
        },
        {
            key: 'SalesAmount',
            name: 'SalesAmount',

        },
        {
            key: 'ReturnQuantity',
            name: 'Return Quantity',
        },
        {
            key: 'ReturnQuantityAmount',
            name: 'ReturnQuantityAmount'
        },
        {
            key: 'Action',
            name: 'Action',
            renderCell: (Params) => (
                <>
                    <Button className="cell_btn"
                        onClick={() => HandelEditdata(Params.row)}
                    >
                        <EditIcon className="check_box_clrr_cancell" />
                    </Button>
                    <Button className="cell_btn"
                        onClick={() => handleDeleteItem(Params.row)}
                    >
                        <DeleteOutlineIcon className="check_box_clrr_cancell" />
                    </Button>
                </>
            )
        }


    ]



    const SaveSalesReturn = () => {

        console.log('ReturnItemList', ReturnItemList);

        let requiredFields = [
            'ReturnDate',
            'CustomerCode',
        ]

        const missingFields = requiredFields.filter(
            (field) => !CustomerDetailes[field]
        )


        let requiredFields2 = [
            'ReturnTotalItem',
            'ReturnTotalQuantity',
            'ReturnTotalAmount',
        ]

        const missingFields2 = requiredFields2.filter(
            (field) => !FinalState[field]
        )

        if (missingFields.length > 0 || missingFields2.length > 0) {

            let allmissingFields = [...missingFields, ...missingFields2]

            const tdata = {
                message: `Please fill out all required fields: ${allmissingFields.join(", ")}`,
                type: 'warn',
            }
            dispatchvalue({ type: 'toast', value: tdata });

        }
        else if (ReturnItemList.length === 0) {
            const tdata = {
                message: `Please Entry Return Items`,
                type: 'warn',
            }
            dispatchvalue({ type: 'toast', value: tdata });
        }
        else {

            let SendItems

            if (EdittData && Object.keys(EdittData).length !== 0) {

                SendItems = {
                    CustomerDetailes: { ...CustomerDetailes, ...FinalState, SalesReturnNumber: EdittData.id },
                    ReturnItemList: ReturnItemList,
                    Create_by: userRecord?.username,
                    StoreLocation: 1
                }

            }
            else {
                SendItems = {
                    CustomerDetailes: { ...CustomerDetailes, ...FinalState },
                    StoreLocation: 1,
                    ReturnItemList: ReturnItemList,
                    Create_by: userRecord?.username,
                }
            }


            // console.log('SendItems',SendItems);

            axios.post(`${UrlLink}Inventory/PurcchaseReturn_Link`, SendItems)
                .then((res) => {
                    console.log(res.data);

                    let resdata = res.data
                    let type = Object.keys(resdata)[0]
                    let mess = Object.values(resdata)[0]
                    const tdata = {
                        message: mess,
                        type: type,
                    }
                    dispatchvalue({ type: 'toast', value: tdata });
                    if (type === 'success') {

                        navigate("/Home/InventoryMaster");
                        dispatchvalue({ type: "HrFolder", value: "SalesReturnList" });
                        dispatchvalue({ type: 'SalesReturnList', value: {} })
                    }
                })
                .catch((err) => {
                    console.log(err);
                })

        }


    }

    return (
        <>
            <div className="Main_container_app">
                <h3>Sales Return</h3>

                <br />

                <div className="RegisFormcon_1">

                    {
                        Object.entries(CustomerDetailes).map(([StateName, value], index) => (
                            <div className="RegisForm_1" key={index + 'key'}>
                                <label htmlFor={StateName}>{formatLabel(StateName)} <span>:</span></label>


                                <div className='Search_patient_icons'>


                                    <input
                                        type={StateName === 'ReturnDate' ? 'date' : 'text'}
                                        id={StateName}
                                        name={StateName}
                                        value={CustomerDetailes[StateName]}
                                        list={StateName + 'list'}
                                        onChange={HandleonchangeCustomerState}
                                        disabled={['CustomerMailId', 'CustomerContactNumber', 'CustomerContactPerson'].includes(StateName)}
                                    />
                                    {
                                        ['CustomerCode', 'CustomerName'].includes(StateName) ?
                                            <>
                                                <datalist id={StateName + 'list'}>
                                                    {
                                                        Customerarr.map((ele, ind) => (
                                                            <option key={ind + 'key'} value={StateName === 'CustomerCode' ? ele.CustomerId : ele.CustomerName}></option>
                                                        ))
                                                    }
                                                </datalist>
                                                <span onClick={(e) => handleSearchCustomerlist(StateName === 'CustomerCode' ? 'CustomerCode' : 'CustomerName')}>
                                                    <ManageSearchIcon />
                                                </span>
                                            </>
                                            : <></>
                                    }
                                </div>





                            </div>
                        ))
                    }



                </div>

                <br />

                <div className="common_center_tag">
                    <span>ITEM DETAILES</span>
                </div>
                <br />

                <div className="RegisFormcon_1">
                    {
                        Object.keys(ReturnItemState).filter(ele => ele !== 'id').map((StateName, index) => (
                            <div className="RegisForm_1" key={index + 'key'}>
                                <label htmlFor={StateName}>{formatLabel(StateName)} <span>:</span></label>


                                {StateName === 'Reason' ?

                                    <select
                                        id={StateName}
                                        name={StateName}
                                        value={ReturnItemState[StateName]}
                                        onChange={HandelOnchaneItemState}
                                    >
                                        <option value=''>Select</option>
                                        <option value='QualityIssue'>Quality Issue</option>
                                        <option value='ExcessItems'>Excess Items</option>
                                        <option value='Damage'>Damage</option>
                                        <option value='ExpiredReturn'>Expired Return</option>
                                    </select>

                                    :

                                    <div className='Search_patient_icons'>
                                        <input
                                            type={['BillDate'].includes(StateName) ? 'date' : 'text'}
                                            id={StateName}
                                            name={StateName}
                                            value={ReturnItemState[StateName]}
                                            disabled={!['BillInvoiceNo', 'ItemCode', 'ItemName'].includes(StateName) ||
                                                (ReturnItemState.Reason === '' && ['ItemCode', 'ItemName'].includes(StateName))
                                            }
                                            onChange={HandelOnchaneItemState}
                                            list={`${StateName}list`}
                                            autoComplete='off'

                                        />

                                        {StateName === 'BillInvoiceNo' ? (
                                            <>
                                                <datalist id={`${StateName}list`}>
                                                    {BillDetailState.map((ele, ind) => (
                                                        <option key={`${ind}key`} value={`${ele.BillNumber}`}>
                                                            {`Bill Date: ${ele.BillDate} `}
                                                        </option>
                                                    ))}
                                                </datalist>
                                                <span onClick={handleSearchBilldetailes}>
                                                    <ManageSearchIcon />
                                                </span>
                                            </>
                                        ) : ['ItemCode', 'ItemName'].includes(StateName) ? (
                                            <>
                                                <datalist id={`${StateName}list`}>
                                                    {BillItemState.map((ele, ind) => (
                                                        <option key={`${ind}key`} value={StateName === 'ItemCode' ? ele.ItemCode : ele.ItemName}>
                                                            {StateName === 'ItemCode' ? `Item Name: ${ele.ItemName} | BatchNo: ${ele.BatchNo} | Received Quantity: ${ele.SalesQuantity}`
                                                                : `Item Code: ${ele.ItemCode} | BatchNo: ${ele.BatchNo} | Received Quantity: ${ele.SalesQuantity}`}
                                                        </option>
                                                    ))}
                                                </datalist>
                                                <span onClick={(e) => { handleSearchItemDetails(StateName === 'ItemCode' ? 'ItemCode' : 'ItemName') }}>
                                                    <ManageSearchIcon />
                                                </span>
                                            </>
                                        ) : null}
                                    </div>
                                }

                            </div>
                        ))
                    }
                </div>

                <br />

                {ReturnQtyState.AvailablePackQuantity !== '' && <div className="RegisFormcon_1">
                    {
                        Object.entries(ReturnQtyState).map(([StateName, value], index) => (
                            <div className="RegisForm_1" key={index + 'key'}>
                                <label htmlFor={StateName}>{formatLabel(StateName)} <span>:</span></label>

                                {

                                    StateName === 'Remarks' ?

                                        <textarea
                                            id={StateName}
                                            name={StateName}
                                            value={ReturnQtyState[StateName]}
                                            onChange={HandelOnchaneReturnQty}
                                        >

                                        </textarea>

                                        :
                                        <>
                                            <input
                                                type='number'
                                                id={StateName}
                                                name={StateName}
                                                value={ReturnQtyState[StateName]}
                                                onChange={HandelOnchaneReturnQty}
                                                disabled={!['ReturnQuantity'].includes(StateName)}
                                            />
                                        </>


                                }
                            </div>
                        ))
                    }
                </div>}


                <br />
                {ReturnQtyState.AvailablePackQuantity &&
                    <div className="Main_container_Btn">
                        <button onClick={SaveSalesReturnItem}>
                            {ReturnItemState.id ? 'Update' : 'Add'}
                        </button>
                    </div>
                }
                <br />


                {ReturnItemList.length !== 0 &&
                    <div className='RegisFormcon_1 jjxjx_'>
                        <ReactGrid columns={ProductListColumn} RowData={ReturnItemList} />
                    </div>
                }

                <br />

                {
                    ReturnItemList.length !== 0 &&
                    <div className="RegisFormcon_1">
                        {
                            Object.keys(FinalState).map((StateName, index) => (
                                <div className="RegisForm_1" key={index + 'key'}>
                                    <label htmlFor={StateName}>{formatLabel(StateName)} <span>:</span></label>
                                    <input
                                        type='number'
                                        id={StateName}
                                        name={StateName}
                                        value={FinalState[StateName]}
                                        disabled
                                    />
                                </div>
                            ))
                        }
                    </div>
                }

                <br />

                {ReturnItemList.length !== 0 &&
                    <div className="Main_container_Btn">
                        <button onClick={SaveSalesReturn} >
                            {Object.keys(EdittData).length !== 0 ? 'Update' : 'Save'}
                        </button>
                    </div>
                }

            </div>
            <ToastAlert Message={toast.message} Type={toast.type} />

        </>
    )
}

export default SalesReturn;

