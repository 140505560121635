import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactGrid from '../../OtherComponent/ReactGrid/ReactGrid';
import ModelContainer from "../../OtherComponent/ModelContainer/ModelContainer";
import Button from "@mui/material/Button";
import ToastAlert from "../../OtherComponent/ToastContainer/ToastAlert";
import PhotoCameraBackIcon from "@mui/icons-material/PhotoCameraBack";


import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';


const CurrentStocklist = () => {
    const UrlLink = useSelector((state) => state.userRecord?.UrlLink);
    const toast = useSelector((state) => state.userRecord?.toast);
    const dispatchvalue = useDispatch();
    const userRecord = useSelector((state) => state.userRecord?.UserData);
    const [searchQuery, setSearchQuery] = useState({
        ProductCategoryid: '',
        SubCategoryid: '',
        ItemCode: '',
        ItemName: '',
    });
  


    const [productCategory, setProductCategory] = useState([]);
    const [subproductCategory, setSubproductCategory] = useState([]);
    const [itemnames, setItemnames] = useState([]);
    const [fetchalldata, setFetchalldata] = useState([]);

    const [selectedrow, setselectedrow] = useState(false);
    const [batchdata, setbatchdata] = useState([]);




    
    const handleOnChange = (e) => {
        const { name, value } = e.target;
        
        if (name === 'ProductCategoryid') {

            setSearchQuery((prev)=>({
                ...prev,
                [name]:value,
                SubCategoryid:'',
                ItemCode: '',
                ItemName: '',
            }))
        }

        else if (name === 'SubCategoryid') {

            setSearchQuery((prev)=>({
                ...prev,
                [name]:value,
                ItemCode: '',
                ItemName: '',
            }))
        }
        else if (name === 'ItemCode') {
        

        if(value.includes('-')){

        const [ItemCode, ItemName] = value.split('-');

            setSearchQuery((prev)=>({
                ...prev,
                [name]:ItemCode,
                ItemName: ItemName,
            }))
        }
        else{
            setSearchQuery((prev)=>({
                ...prev,
                [name]:value,
                ItemName: '',
            }))
        }
        }

        else if (name === 'ItemName') {
        if(value.includes('-')){
        const [ItemCode, ItemName] = value.split('-');


            setSearchQuery((prev)=>({
                ...prev,
                [name]:ItemName,
                ItemCode: ItemCode,
            }))
        }
        else{
            setSearchQuery((prev)=>({
                ...prev,
                [name]:value,
                ItemCode: '',
            }))

        }
        }
      
    };
    

    useEffect(() => {
        const fetchProductCategory = async () => {
            try {
                const response = await axios.get(`${UrlLink}Inventory/Product_Category_Product_Details_link`);
                const data = response.data;
                console.log("productdata", data)
                if (Array.isArray(data)) {
                    setProductCategory(data);  // Assuming data is the array of categories
                } else {
                    setProductCategory([]);    // Reset state to an empty array if response is not as expected
                }
            } catch (error) {
                console.error('Error fetching product categories:', error);
            }
        };

        fetchProductCategory();
    }, [UrlLink]);



    useEffect(() => {
        if (!searchQuery.ProductCategoryid) {
            setSubproductCategory([]);
            return;
        }
        const fetchSubProductCategories = async () => {
            try {
                const response = await axios.get(
                    `${UrlLink}Inventory/get_SubProduct_byProduct?ProductCategoryid=${searchQuery.ProductCategoryid}`
                );
                const data = response.data;
                console.log("subproduct", data)
                if (Array.isArray(data)) {
                    setSubproductCategory(data);
                } else {
                    setSubproductCategory([]);
                }


            }
            catch (error) {
                console.error('Error fetching product categories:', error);
            }
        };

        fetchSubProductCategories();
    }, [UrlLink, searchQuery.ProductCategoryid])




    useEffect(() => {
        const fetchitems = async () => {
            try {
                const itemresponse = await axios.get(`${UrlLink}Inventory/Product_Master_data_link`);
                const data = itemresponse.data;
                console.log("item", data)
                if (Array.isArray(data)) {
                    setItemnames(data);
                } else {
                    setItemnames([]);
                }

            }
            catch (error) {
                console.error('Error fetching item details:', error);
            }

        };
        fetchitems();

    }, [UrlLink])



    useEffect(() => {
        const fetchAllData = async () => {
            try {
                const response = await axios.get(`${UrlLink}Inventory/Items_Details_by_ProductMaster`, {
                    params: searchQuery
                });
                const data = response.data;
                if (Array.isArray(data)) {
                    console.log("alldata", data)
                    setFetchalldata(data);
                } else {
                    setFetchalldata([]);
                }

            } catch (error) {
                console.error('Failed to fetch nurse station:', error);
            }

        };
        fetchAllData();

    }, [UrlLink, searchQuery])



    const AllItemColumn = [
        {
            key: "id",
            name: "S.NO",
            frozen: true
        },
        {
            key: "ProductCategoryname",
            name: "ProductCategoryname",
            frozen: true
        },
        {
            key: "SubCategoryname",
            name: "SubCategoryname",
        },
        {
            key: "ItemCode",
            name: "ItemCode",
        },
        {
            key: "ItemName",
            name: "ItemName",
        },

        {
            key: "TotalQuantity",
            name: "TotalQuantity",
        },
        {
            key: "AvailableQuantity",
            name: "AvailableQuantity",
            renderCell: ({ row }) => (
                <Button
                    className="cell_btn"
                    onClick={() => handleShowitems(row)}
                >
                    {row.AvailableQuantity}
                </Button>
            ),
        },
        {
            key: "SoldQuantity",
            name: "SoldQuantity",
        }


    ]


    const handleShowitems = async (row) => {
        console.log("row", row);
        if (row.ItemCode) {
            try {
                const response = await axios.get(`${UrlLink}Inventory/get_item_details_batchwise?itemcode=${row.ItemCode}`);
                if (response.data) {
                    setselectedrow(true);
                    if (Array.isArray(response?.data)) {
                        setbatchdata(response?.data);
                        // Correct assignment
                        const data = response.data;
                        console.log("databatchwise", data);
                    }
                } else {
                    setselectedrow(false);
                    setbatchdata([]);
                    dispatchvalue({ type: 'toast', value: { message: 'There is no data to view.', type: 'warn' } });
                }
            } catch (error) {

            }
        } else {
            setselectedrow(false);
            dispatchvalue({ type: 'toast', value: { message: 'There is no data to view.', type: 'warn' } });
        }
    };

    const BatchwiseColumn = [
        {
            key: "id",
            name: "S.NO",
            frozen: true
        },
        {
            key: "Itemcode",
            name: "ItemCode",
            frozen: true
        },
        {
            key: "itemname",
            name: "ItemName",
            frozen: true
        },
        {
            key: "Batch_No",
            name: "Batch No",
        },
        {
            key: "Manufacture_Date",
            name: "Manufacture Date",
        },
        {
            key: "Expiry_Date",
            name: "Expiry Date",
        }, {
            key: "AvailableQuantity",
            name: "Available Quantity",
        }
    ]



    const [SelectedFile, setSelectedFile] = useState(null);

const handleFileChange = (event) => {
    setSelectedFile(null);
    setSelectedFile(event.target.files[0]);
  };

  const handleCsvupload = () => {
    console.log(SelectedFile);
    const formData = new FormData();
    formData.append("file", SelectedFile);
    formData.append("created_by", userRecord?.username);

    if (SelectedFile) {
      axios
        .post(`${UrlLink}Inventory/All_Product_Files_Upload`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          console.log(response);
          // successMsg("File Processed Successfully");
          // fetch_testmasterdata();
          setSelectedFile(null);
          
          let data = response.data;

          let type = Object.keys(data)[0]
          let mess = Object.values(data)[0]
          const tdata = {
            message: mess,
            type: type
          }
          dispatchvalue({ type: 'toast', value: tdata })
        })
        .catch((error) => {
          console.log(error);
          alert(error);
        });
    } else {
      // userwarn("Choose File");
    }
  };



    return (
        <div className="Main_container_app">
            <h3>Current Stock List</h3>
            <br />

            <div className="RegisFormcon_1">
                <div className="RegisForm_1">
                    <label> Product Category <span>:</span> </label>
                    <select
                        name="ProductCategoryid"
                        value={searchQuery.ProductCategoryid}
                        onChange={handleOnChange}
                    >
                        <option value="">Select</option> {/* Default empty option */}
                        {productCategory
                            .filter((category) => category.Status === "Active")  // Filter for active categories
                            .map((category, index) => (
                                <option key={index} value={category.id}>
                                    {category.ProductCategory}
                                </option>
                            ))}
                    </select>
                </div>
                <div className="RegisForm_1">
                    <label> SubCategory <span>:</span> </label>
                    <select
                        name="SubCategoryid"
                        value={searchQuery.SubCategoryid}
                        onChange={handleOnChange}
                    >
                        <option value="">Select</option> {/* Default empty option */}
                        {subproductCategory.map((category, index) => (
                            <option key={index} value={category.SubCategory_Id}>
                                {category.SubCategoryName}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="RegisForm_1">
                    <label htmlFor="itemCode">Item Code <span>:</span></label>
                    <input
                        id="ItemCode"
                        name='ItemCode'
                        value={searchQuery.ItemCode}
                        list="itemCode-options"
                        onChange={handleOnChange}
                        autoComplete="off"
                    />
                    <datalist id="itemCode-options">
                        {itemnames.map((item, index) => (
                            <option key={`${index}-code`} value={`${item.ItemCode}-${item.ItemName}`}>
                                {item.ItemCode}
                            </option>
                        ))}
                    </datalist>

                </div>

                <div className="RegisForm_1">
                    <label htmlFor="ItemName">Item Name <span>:</span></label>
                    <input
                        id="ItemName"
                        name='ItemName'
                        value={searchQuery.ItemName}
                        list="ItemName-options"
                        onChange={handleOnChange}
                        autoComplete="off"
                    />
                    <datalist id="ItemName-options">
                        {itemnames.map((item, index) => (
                            <option key={`${index}-code`} value={`${item.ItemCode}-${item.ItemName}`}>
                                {item.ItemName}
                            </option>
                        ))}
                    </datalist>

                </div>

            </div>
            <br></br>

            {/* choose file */}
            <div style={{ display: "flex", alignItems: "center", }}>
                    <label>
                        {" "}
                        TestMaster Excel File<span>:</span>{" "}
                    </label>
                    <input
                        type="file"
                        accept=".xlsx, .xls, .csv"
                        id="Servicechoose"
                        required
                        style={{ display: "none" }}
                        onChange={handleFileChange}
                    />
                    


                    <label
                        htmlFor="Servicechoose"
                        title='Choose File'
                        className="RegisterForm_1_btns choose_file_update"
                    >
                        <PhotoCameraBackIcon />
                    </label>
                    <button
                        className="RegisterForm_1_btns choose_file_update"
                        onClick={handleCsvupload}
                        title='File Upload'
                    >
                        
                        <DriveFolderUploadIcon />
                
                    </button>
                </div>

                <br></br>

            {/* grid */}

            {
                fetchalldata.length !== 0 &&
                <>
                    <div className='RegisFormcon_1 jjxjx_'>
                        <ReactGrid columns={AllItemColumn} RowData={fetchalldata} />
                    </div>
                </>
            }


            {selectedrow && (
                <div className="loader" onClick={() => setselectedrow(false)}>
                    <div className="loader_register_roomshow" onClick={(e) => e.stopPropagation()}>
                        {batchdata.length > 0 && (
                            <div className="common_center_tag">
                                <span>Batchwise Item Details</span>
                            </div>
                        )}
                        <br />
                        <div className="Main_container_app">
                            <ReactGrid columns={BatchwiseColumn} RowData={batchdata} />
                        </div>
                    </div>
                </div>
            )}

            <ToastAlert Message={toast.message} Type={toast.type} />
            <ModelContainer />
        </div>
    )

}

export default CurrentStocklist