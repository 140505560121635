import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { BlockInvalidcharecternumber } from '../../OtherComponent/OtherFunctions';
import ToastAlert from '../../OtherComponent/ToastContainer/ToastAlert';
import axios from 'axios';
import PhotoCameraBackIcon from '@mui/icons-material/PhotoCameraBack';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ModelContainer from '../../OtherComponent/ModelContainer/ModelContainer';


const CustomerMaster = () => {

    const dispatchvalue = useDispatch();
    const navigate = useNavigate();
    const toast = useSelector(state => state.userRecord?.toast);
    const UrlLink = useSelector(state => state.userRecord?.UrlLink);
    const userRecord = useSelector((state) => state.userRecord?.UserData);
    const CustomerMasterStore = useSelector(state => state.Inventorydata?.CustomerMasterStore);

    const [CustomerMaster, setCustomerMaster] = useState({
        CustomerId: '',
        CustomerName: '',
        CustomerType: '',
        ContactPerson: '',
        ContactNumber: '',
        AlternateContactNumber: '',
        EmailAddress: '',
        Address: '',
        RegistrationNumber: '',
        GSTNumber: '',
        PANNumber: '',
        DruglicenseNo: '',
        PaymentTerms: '',
        CreditLimit: '',
        Notes: '',
        FileAttachment: null,
    })





    const formatLabel = (label) => {

        if (/[a-z]/.test(label) && /[A-Z]/.test(label) && !/\d/.test(label)) {
            return label
                .replace(/([a-z])([A-Z])/g, "$1 $2")
                .replace(/^./, (str) => str.toUpperCase());
        } else {
            return label;
        }
    };


    const getInputType = (name) => {

        if (['ContactNumber', 'AlternateContactNumber', 'CreditLimit', 'PaymentTerms'].includes(name)) return 'number';
        return 'text';
    };





    const handleInputChange = (e) => {

        const { name, value, type, checked } = e.target;

        if (name === 'ContactNumber' || name === 'AlternateContactNumber') {

            if (value.length > 11) {

                const tdata = {
                    message: 'ContactNumber only 11 degits numbers',
                    type: 'warn'
                };
                dispatchvalue({ type: 'toast', value: tdata });

            }

            else {
                setCustomerMaster((prev) => ({
                    ...prev,
                    [name]: value
                }))
            }

        }
        else if (['CustomerName', 'RegistrationNumber', 'GSTNumber', 'PANNumber', 'DruglicenseNo'].includes(name)) {

            setCustomerMaster((prev) => ({
                ...prev,
                [name]: value.toUpperCase()
            }))

        }

        else {
            setCustomerMaster((prev) => ({
                ...prev,
                [name]: type === 'checkbox' ? checked : value
            }))
        }


    }




    const handlefileOnchange = (e) => {

        const { files } = e.target


        if (files && files.length > 0) {
            let formattedValue = files[0];

            const allowedTypes = ['application/pdf', 'image/jpeg', 'image/png'];
            const maxSize = 5 * 1024 * 1024; // Example max size of 5MB
            if (!allowedTypes.includes(formattedValue.type) || formattedValue.type === '') {

                const tdata = {
                    message: 'Invalid file type. Please upload a PDF, JPEG, or PNG file.',
                    type: 'warn'
                };
                dispatchvalue({ type: 'toast', value: tdata });

            } else {
                if (formattedValue.size > maxSize) {
                    const tdata = {
                        message: 'File size exceeds the limit of 5MB.',
                        type: 'warn'
                    };
                    dispatchvalue({ type: 'toast', value: tdata });

                } else {
                    const reader = new FileReader();
                    reader.onload = () => {
                        setCustomerMaster((prev) => ({
                            ...prev,
                            FileAttachment: reader.result
                        }))

                    };
                    reader.readAsDataURL(formattedValue);




                }
            }

        } else {
            const tdata = {
                message: 'No file selected. Please choose a file to upload.',
                type: 'warn'
            };
            dispatchvalue({ type: 'toast', value: tdata });
        }

    }



    const Selectedfileview = (fileval) => {

        if (fileval) {
            let tdata = {
                Isopen: false,
                content: null,
                type: 'image/jpg'
            };
            if (['data:image/jpeg;base64', 'data:image/jpg;base64'].includes(fileval?.split(',')[0])) {
                tdata = {
                    Isopen: true,
                    content: fileval,
                    type: 'image/jpeg'
                };
            } else if (fileval?.split(',')[0] === 'data:image/png;base64') {
                tdata = {
                    Isopen: true,
                    content: fileval,
                    type: 'image/png'
                };
            } else if (fileval?.split(',')[0] === 'data:application/pdf;base64') {
                tdata = {
                    Isopen: true,
                    content: fileval,
                    type: 'application/pdf'
                };
            }

            dispatchvalue({ type: 'modelcon', value: tdata });
        } else {
            const tdata = {
                message: 'There is no file to view.',
                type: 'warn'
            };
            dispatchvalue({ type: 'toast', value: tdata });
        }
    }



    useEffect(() => {


        if (CustomerMasterStore && Object.keys(CustomerMasterStore).length !== 0) {
            console.log('CustomerMasterStore', CustomerMasterStore);

            const { id, ...rest } = CustomerMasterStore;

            setCustomerMaster((prev) => ({
                ...prev,
                ...rest,
            }))
        }

    }, [CustomerMasterStore])




    const saveCustomerdata = () => {

        let requiredfields = Object.keys(CustomerMaster).filter(f =>
            !['CustomerId', 'Notes', 'FileAttachment', 'CustomerType', 'CreditLimit', 'EmailAddress','RegistrationNumber','PANNumber','AlternateContactNumber' ].includes(f)
        );
        console.log("requiredfields", requiredfields);


        let missingfield = requiredfields.filter((field) => !CustomerMaster[field]);
        console.log("missingfield", missingfield);
        if (missingfield.length !== 0) {
            const tdata = {
                message: `Please fill out all required fields: ${missingfield.join(", ")}`,
                type: 'warn',
            };
            dispatchvalue({ type: 'toast', value: tdata });
        }

        else {
            const senddataaaa = {
                ...CustomerMaster,
                created_by: userRecord?.username
            }
            console.log(senddataaaa);

            axios.post(`${UrlLink}Inventory/Customer_Master_Link`, senddataaaa)
                .then((res) => {
                    console.log(res.data);
                    let resdata = res.data
                    let type = Object.keys(resdata)[0]
                    let mess = Object.values(resdata)[0]
                    const tdata = {
                        message: mess,
                        type: type,
                    }
                    dispatchvalue({ type: 'toast', value: tdata });
                    if (type === 'success') {
                        navigate("/Home/Master");
                        dispatchvalue({ type: "HrFolder", value: "CustomerMasterList" });
                        dispatchvalue({ type: "setPreviousFolder", value: null });
                        dispatchvalue({ type: "showMenu", value: true });
                    }
                })
                .catch((err) => {
                    console.log(err);
                })
        }


    }


    return (
        <>
            <div className="Main_container_app">
                <h3>Customer Master</h3>

                <div className="common_center_tag">
                    <span>Customer Details</span>
                </div>

                <br />

                <div className="RegisFormcon_1">
                    {Object.keys(CustomerMaster)
                        .filter((f) => !['CustomerId'].includes(f))
                        .map((StateName, Index) => (
                            <div className="RegisForm_1" key={Index + 'key'}>
                                <label htmlFor={StateName}>
                                    <div className="imp_v_star">
                                        {['CustomerName', 'ContactNumber', 'Address',  'GSTNumber', 'PaymentTerms','DruglicenseNo','ContactPerson'].includes(StateName) ? (
                                            <>
                                                {formatLabel(StateName)}
                                                <span className="requirreg12">*</span>
                                            </>
                                        )
                                            : (
                                                formatLabel(StateName)
                                            )}
                                    </div>
                                    {StateName === 'PaymentTerms' ? '(Due days)' : ''}
                                    <span>:</span>
                                </label>

                                {['Address', 'Notes'].includes(StateName) ? (
                                    <textarea
                                        id={StateName}
                                        name={StateName}
                                        value={CustomerMaster[StateName]}
                                        onChange={handleInputChange}
                                    />
                                ) : StateName === 'CustomerType' ? (
                                    <select
                                        id={StateName}
                                        name={StateName}
                                        value={CustomerMaster[StateName]}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Select</option>
                                        <option value="Manufacturer">Manufacturer</option>
                                        <option value="Distributor">Distributor</option>
                                        <option value="Wholesaler">Wholesaler</option>
                                        <option value="Retailer">Retailer</option>
                                        <option value="OEM">OEM (Original Equipment Manufacturer)</option>
                                        <option value="Vendor">Vendor</option>
                                    </select>
                                ) : StateName === 'FileAttachment' ? (
                                    <>
                                        <input
                                            type="file"
                                            name={StateName}
                                            accept="image/jpeg,image/png,application/pdf"
                                            required
                                            id={`${StateName}_Customer`}
                                            autoComplete="off"
                                            onChange={handlefileOnchange}
                                            style={{ display: 'none' }}
                                        />
                                        <div
                                            style={{
                                                width: '87px',
                                                display: 'flex',
                                                justifyContent: 'flex-start',
                                                gap: '10px',
                                            }}
                                        >
                                            <label
                                                htmlFor={`${StateName}_Customer`}
                                                className="RegisterForm_1_btns choose_file_update"
                                            >
                                                <PhotoCameraBackIcon />
                                            </label>
                                            <button
                                                type="button"
                                                className="RegisterForm_1_btns choose_file_update"
                                                onClick={() => Selectedfileview(CustomerMaster.FileAttachment)}
                                            >
                                                <VisibilityIcon />
                                            </button>
                                        </div>
                                    </>
                                ) : (
                                    <input
                                        type={getInputType(StateName)}
                                        onKeyDown={getInputType(StateName) === 'number' ? BlockInvalidcharecternumber : null}
                                        id={StateName}
                                        name={StateName}
                                        value={CustomerMaster[StateName]}
                                        onChange={handleInputChange}
                                    />
                                )}
                            </div>
                        ))}
                </div>

                <br />

                <div className="Main_container_Btn">
                    <button onClick={saveCustomerdata}>{CustomerMaster.CustomerId ? 'Update' : 'Save'}</button>
                </div>
            </div>
            <ToastAlert Message={toast.message} Type={toast.type} />
            <ModelContainer />
        </>
    );
}

export default CustomerMaster;
