import React, { useState, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import "./Homepage.css";
import { useDispatch, useSelector } from 'react-redux'

import axios from 'axios'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFlask,
  faHandsBound,
  faHospital,
  faUserTie,
  faIdCard,
  faHospitalUser,
  faUserDoctor,
  faBriefcaseMedical,
  faUserNurse,
  faBuildingShield,
  faHandHoldingMedical,
  faSackDollar,
  faPersonHiking,
  faClipboard,
  faBuildingWheat,
  faBedPulse,
  faXRay,
  faCashRegister,
  faSnowflake,
  faPersonBooth,
  faCartFlatbedSuitcase,
} from "@fortawesome/free-solid-svg-icons";

const HomePage = () => {
  const navigate = useNavigate();
  const SidebarToggle = useSelector(state => state.userRecord?.SidebarToggle)
  const UrlLink = useSelector(state => state.userRecord?.UrlLink)
  const Usersessionid = useSelector(state => state.userRecord?.Usersessionid)
  const UserData = useSelector(state => state.userRecord?.UserData)
  const [openSubMenu, setOpenSubMenu] = useState(null)
  const dispatchvalue = useDispatch()

  const [mainAccess, setMainAccess] = useState([])
  const [subAccess, setSubAccess] = useState([])


  useEffect(() => {
    if (!SidebarToggle) {
      setOpenSubMenu(null) // Close all submenus when sidebar collapses
    }
  }, [SidebarToggle])



  useEffect(() => {
    console.log(UserData, 'UserData')

    if (UserData) {
      const setAccess1 =
        (UserData.AccessOne &&
          UserData.AccessOne.split(',').map(item => item.trim())) ||
        []
      // const setAccess2 =
      //   (UserData.AccessTwo &&
      //     UserData.AccessTwo.split(',').map(item => item.trim())) ||
      //   []

      setMainAccess(setAccess1) // Update state for main access
      // setSubAccess(setAccess2) // Update state for sub access
    }
  }, [UserData]) // Dependency array

  const handleCardClick = (cardName, cardLink) => {
    dispatchvalue({ type: "activeCardName", value: cardName }); // Dispatch activeCardName to Redux
    navigate(cardLink); // Navigate to the corresponding page
  };

  return (
    <>
      <div className="home-page-container">
        <div className="cards-container">








          {mainAccess.includes('A') && (
            <div className="card_new_M" onClick={() => handleCardClick("Master", "/Home/Master")}>
              <FontAwesomeIcon icon={faPersonBooth} className="card-icon" />
              <p className="card-title">Master</p>
            </div>
          )}

          {mainAccess.includes('B') && (

            <div
              className="card_new_M"
              onClick={() => handleCardClick("Inventory", "/Home/InventoryMaster")}
            >
              <FontAwesomeIcon icon={faClipboard} className="card-icon" />
              <p className="card-title">Inventory</p>
            </div>
          )}

          {mainAccess.includes('C') && (

            <div className="card_new_M" onClick={() => handleCardClick("Cashier", "/Home/CashierFolder")}>
              <FontAwesomeIcon icon={faSackDollar} className="card-icon" />
              <p className="card-title">Cashier</p>
            </div>
          )}






        </div>
      </div>
    </>
  );
};

export default HomePage;
