
import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from "@mui/material/Button";
import VisibilityIcon from '@mui/icons-material/Visibility';
import ModelContainer from '../../OtherComponent/ModelContainer/ModelContainer';
import ListIcon from '@mui/icons-material/List';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from 'react-router-dom';
import ReactGrid from '../../OtherComponent/ReactGrid/ReactGrid';
import ToastAlert from '../../OtherComponent/ToastContainer/ToastAlert';
import LoupeIcon from "@mui/icons-material/Loupe";


const BillingList = () => {

    const dispatchvalue = useDispatch();
    const navigate = useNavigate();
    const UrlLink = useSelector(state => state.userRecord?.UrlLink);
    const toast = useSelector(state => state.userRecord?.toast);
    const userRecord = useSelector((state) => state.userRecord?.UserData);
    const [ShowItems, setShowItems] = useState(false)
    const [getstate, setgetstate] = useState(false)
    const [ItemsData, setItemsData] = useState([])
    const [GrnData, setGrnData] = useState([])

    const[Customerarr,setCustomerarr]=useState([])
    

    const [searchQuery, setsearchQuery] = useState({
            StatusCheck:'',
            DateType: '',
            CurrentDate: '',
            FromDate: '',
            ToDate: '',
            CustomerId: '',
            CustomerName: '',
    })


    
        useEffect(() => {
            if (searchQuery.DateType === 'Current') {
                const today = new Date();            
                const currentDate = today.toISOString().split('T')[0];
    
                setsearchQuery((prev) => ({
                    ...prev,
                    CurrentDate: currentDate
                }))
            } else {
                setsearchQuery((prev) => ({
                    ...prev,
                    CurrentDate: ''
                }))
            }
    
        }, [searchQuery.DateType])


        useEffect(()=>{

            axios.get(`${UrlLink}Inventory/Customer_Master_Link`)
            .then((res)=>{
                
                let getdata=res.data.customers;
                
                console.log('getdata------',getdata);
                
                setCustomerarr(getdata && Array.isArray(getdata) ? getdata : [])
            })
            .catch((err)=>{
                console.log(err);            
            })
    
        },[UrlLink])


        const handleonchange = (e) => {
            const { name, value } = e.target
    
    
            if(name === 'CustomerId'){
    
                let Finddata=Customerarr.find((ele)=>ele.id === value)
                if(Finddata){
                    setsearchQuery((prev)=>({
                    ...prev,
                    [name]:value,
                    CustomerName:'',
                  }))
                }
                else{
                    setsearchQuery((prev)=>({
                    ...prev,
                    [name]:value,
                    CustomerName:'',
              
                  }))
                }
                }
                if(name === 'CustomerName'){
            
                  let Finddata=Customerarr.find((ele)=>ele.CustomerName === value)
                  if(Finddata){
                    setsearchQuery((prev)=>({
                      ...prev,
                      [name]:value,
                      CustomerId:'',
                    }))
                  }
                  else{
                    setsearchQuery((prev)=>({
                      ...prev,
                      [name]:value,
                      CustomerId:'',
                
                    }))
                  }
                  }
             else{
                setsearchQuery((prev) => ({
                    ...prev,
                    [name]: value
                }))
             }     
           
        }


        useEffect(() => {
            axios.get(`${UrlLink}Cashier/Post_Billing_Details_Save_Data`,{
                params:searchQuery,
            })
            .then((res)=>{
              console.log('vvvvvvvvv',res.data);
              
              const getdata = res.data
                
              setGrnData(getdata && Array.isArray(getdata) ? getdata : [])
              
            })
            .catch((err)=>{
              console.log(err);        
            })
        }, [UrlLink, getstate,searchQuery])



        const handleNewMaster = () => {
            dispatchvalue({ type: 'BillingEditData', value: {} });
            navigate("/Home/CashierFolder");
            dispatchvalue({ type: "HrFolder", value: "PharmacyBillingNew" });
            dispatchvalue({ type: "setPreviousFolder", value: null }); 
            dispatchvalue({ type: "showMenu", value: true });
          };


        const ItemView = (row) => {
    
    
    
            if (row?.billing_items && row?.billing_items.length !== 0) {
                setShowItems(true)
                setItemsData(row?.billing_items)
            }
            else {
    
                const tdata = {
                    message: 'There is no data to view.',
                    type: 'warn'
                };
                dispatchvalue({ type: 'toast', value: tdata });
    
            }
    
        }
    
    
        const HandelEditdata = (row) => {
           
            console.log('00123',row);
            
    
            if (row) {
                dispatchvalue({ type: 'BillingEditData', value: row });

                navigate("/Home/CashierFolder");
                dispatchvalue({ type: "HrFolder", value: "PharmacyBillingNew" });
                dispatchvalue({ type: "setPreviousFolder", value: null }); 
                dispatchvalue({ type: "showMenu", value: true });
            }
    
        }
    
    
    
    
        const GRNOrderListColumn = [
    
            {
                key: 'id',
                name: 'S.No',
            },
            {
                key: 'InvoiceNo',
                name: 'Invoice No',
            },
            {
                key: 'Billing_Date',
                name: 'Billing Date',
            },
            {
                key: 'Customer_Name',
                name: 'Customer Name',
            },
            {
                key: 'billing_items',
                name: 'Billing items list',
                renderCell: (params) => (
                    <>
                        <Button className="cell_btn"
                            onClick={() => ItemView(params.row)}
                        >
                            <ListIcon className="check_box_clrr_cancell" />
                        </Button>
                    </>
                )
            },
            {
                key: 'Total_Items',
                name: 'Total Items',
            },
            {
                key: 'Total_Qty',
                name: 'Total Qty',
    
            },
            {
                key: 'Total_Final_Base_Amount',
                name: 'Base Amount',
    
            },
            {
                key: 'DiscountMethod',
                name: 'Discount Method',
                renderCell: (params) => (
                    params.row.DiscountMethod ? params.row.DiscountMethod : "-"
                )
            },
            {
                key: 'DiscountType',
                name: 'Discount Type',
                renderCell: (params) => (
                    params.row.DiscountType ? params.row.DiscountType : "-"
                )
    
            },
            {
                key: 'DiscountAmount',
                name: 'Discount Amount',
                renderCell: (params) => (
                    params.row.DiscountAmount ? params.row.DiscountAmount : "-"
                )
            },
            {
                key: 'Total_GSTAmount',
                name: 'Total GST Amount',
            },
            {
                key: 'Net_Amount',
                name: 'Net Amount',
            },
            {
                key: 'Round_Off',
                name: 'Round Off',
            },
            {
                key: 'Final_Net_Amount',
                name: 'Final Net Amount',
            },
            {
                key: 'Bill_Status',
                name: 'Payment Status',
            },
            {
                key: 'Created_By',
                name: 'Created By',
            },
            {
                key: 'Edit',
                name: 'Edit',
                renderCell: (params) => (
                    <>
                        {userRecord.EmployeeEditApprove === "Yes" && params.row.Bill_Status === 'Pending' ?
                            <Button className="cell_btn"
                                onClick={() => HandelEditdata(params.row)}
                            >
                                <EditIcon className="check_box_clrr_cancell" />
                            </Button> :
                            <>No Action</>}
                    </>
                )
            },

    
        ]
    
    
        const ItemColumn = [
            {
                key: 'id',
                name: 'S.No',
            },
            {
                key: 'ItemName',
                name: 'Item Name',
            },
            {
                key: 'Generic',
                name: 'Generic Name',
            },
            {
                key: 'HSNCode',
                name: 'HSN Code',
            },
            {
                key: 'Billing_Quantity',
                name: 'Billing Quantity',
            },
            {
                key: 'LeastSellableUnit',
                name: 'Sellable Unit',
            },
            {
                key: 'Billing_Total_Quantity',
                name: 'Billing Total Quantity',
            },
            {
                key: 'IsFOCAvailable',
                name: 'Is FOC Available',
                renderCell: (params) => (
                    params.row.IsFOCAvailable ? 'Yes' : 'No'
                )
            },
            {
                key: 'FOCQuantiy',
                name: 'FOC Quantiy',
                renderCell: (params) => (
                    params.row.FOCQuantiy ? params.row.FOCQuantiy : "Nill"
                )
            },
            {
                key: 'FOC_Total_Quantity',
                name: 'FOC Total Quantity',
                renderCell: (params) => (
                    params.row.FOC_Total_Quantity ? params.row.FOC_Total_Quantity : "Nill"
                )
            },
            {
                key: 'TotalQuantity',
                name: 'Total Quantity',
            },
            {
                key: 'Unit_Price',
                name: 'Unit Price',
            },
            {
                key: 'Taxable_Amount',
                name: 'Taxable Amount',
            },
            {
                key: 'Discount_Method',
                name: 'Discount Method',
            },
            {
                key: 'Discount_Type',
                name: 'Discount Type',
            },
            {
                key: 'Discount_Amount',
                name: 'Discount Amount',
            },
            {
                key: 'Final_Taxable_Amount',
                name: 'Final Taxable Amount',
            },
            {
                key: 'Tax_Percentage',
                name: 'Tax Percentage',
            },
            {
                key: 'Tax_Amount',
                name: 'Tax Amount',
            },
            {
                key: 'Final_Total_Amount',
                name: 'Final Total Amount',
            }    
        ]
    
    


  return (
    <>

        <div className="Main_container_app">
                <h3>Billing List</h3>
                <br />
                <div className="RegisFormcon_1">

                    <div className="RegisForm_1">
                        <label>Payment Status<span>:</span></label>

                        <select
                            name='StatusCheck'
                            value={searchQuery.StatusCheck}
                            onChange={handleonchange}
                        >
                            <option value=''>Select</option>
                            <option value='Pending'> Pending </option>
                            <option value='Complete'> Complete </option>
                        </select>
                    </div>

                    <div className="RegisForm_1">
                        <label>Date Type<span>:</span></label>
                        <select
                            name='DateType'
                            value={searchQuery.DateType}
                            onChange={handleonchange}
                        >
                            <option value=''>Select</option>
                            <option value='Current'>Current Date</option>
                            <option value='Customize'>Customize</option>
                        </select>
                    </div>

                    {searchQuery.DateType === 'Current' ?
                        <div className="RegisForm_1">
                            <label>Current Date<span>:</span></label>
                            <input
                                type='date'
                                name='CurrentDate'
                                value={searchQuery.CurrentDate}
                                onChange={handleonchange}
                                readOnly
                            />

                        </div>
                        :
                        <>
                            <div className="RegisForm_1">
                                <label>From Date<span>:</span></label>
                                <input
                                    type='date'
                                    name='FromDate'
                                    value={searchQuery.FromDate}
                                    onChange={handleonchange}
                                />

                            </div>


                            <div className="RegisForm_1">
                                <label>To Date<span>:</span></label>
                                <input
                                    type='date'
                                    name='ToDate'
                                    value={searchQuery.ToDate}
                                    onChange={handleonchange}
                                    min={searchQuery.FromDate}
                                />

                            </div>
                        </>}

                    <div className="RegisForm_1">
                        <label>Customer Id<span>:</span></label>
                        <input
                            type='text'
                            name='CustomerId'
                            value={searchQuery.CustomerId}
                            onChange={handleonchange}
                            list='CustomerIdList'
                        />
                        <datalist id='CustomerIdList'>
                            {
                                Customerarr.map((ele,ind)=>(
                                    <option key={ind+'key'} value={ele.CustomerId} ></option>
                                  ))

                            }
                        </datalist>

                    </div>


                    <div className="RegisForm_1">
                        <label>Customer Name<span>:</span></label>
                        <input
                            type='text'
                            name='CustomerName'
                            value={searchQuery.CustomerName}
                            onChange={handleonchange}
                            list='CustomerNameList'
                        />
                        <datalist id='CustomerNameList'>
                        {
                            Customerarr.map((ele,ind)=>(
                            <option key={ind+'key'} value={ele.CustomerName} ></option>
                            ))
                        }
                        </datalist>

                    </div>


                    <button
                        className="search_div_bar_btn_1"
                        onClick={handleNewMaster}
                        title="New Doctor Register"
                    >
                        <LoupeIcon />
                    </button>

                </div>

                <br />

                <ReactGrid columns={GRNOrderListColumn} RowData={GrnData} />
            </div>
            <ToastAlert Message={toast.message} Type={toast.type} />
            <ModelContainer />

            {ShowItems && ItemsData.length !== 0 && (
                <div className="loader" onClick={() => setShowItems(false)}>
                    <div className="loader_register_roomshow" onClick={(e) => e.stopPropagation()}>
                        <br />

                        <div className="common_center_tag">
                            <span>Item Details</span>
                        </div>
                        <br />
                        <br />

                        <ReactGrid columns={ItemColumn} RowData={ItemsData} />
                    </div>
                </div>
            )}

    </>
  )
}

export default BillingList;