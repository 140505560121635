import React, { useEffect, useRef } from "react";
import { useState } from "react";
import axios from "axios";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch, useSelector } from "react-redux";
import jsPDF from "jspdf";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { useReactToPrint } from "react-to-print";
import { useNavigate } from "react-router-dom";
import kghospital from "../../Assets/kghospital.jpeg";
import './GeneralBilling.css';
import ToastAlert from "../../OtherComponent/ToastContainer/ToastAlert";



const PharmacyBillingNew = () => {


    const navigate = useNavigate();
    const componentRef = useRef();
    const dispatchvalue = useDispatch();

    const toast = useSelector(state => state.userRecord?.toast);
  
    const UserData = useSelector((state) => state.userRecord?.UserData);
  
    const UrlLink = useSelector((state) => state.userRecord?.UrlLink);
    
    const userRecord = useSelector((state) => state.userRecord?.UserData);

    const BillEditdata = useSelector((state) => state.Inventorydata?.BillingEditData);

    const today = new Date();
    const currentDate = today.toISOString().split('T')[0];
    
    
    const [Billing_date, setBilling_date] = useState(currentDate);
    
    const [SelectedCustomer, setSelectedCustomer] = useState({
      InvoiceId:'',
      CustomerId: "",
      CustomerName: "",
      GSTnumber: "",
      PhoneNumber: "",
      CustomerAddress: "",
    });


    const [ItemSearch, setItemSearch] = useState({
      SearchItemName:"",
      SearchItemCode:"",
    });

    const [shelfview,setshelfview]=useState({
      RackName:'',
      ShelfName:''
    })
  
    const[Customerarr,setCustomerarr]=useState([])

    const [Itemarr,setItemarr]=useState([])

    const [getStockid_Name, setgetStockid_Name] = useState([]);

    const [isPrintButtonVisible, setIsPrintButtonVisible] = useState(true);

   const blockInvalidChar = (e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
    
  
    const [Billing_itemtable, setBilling_itemtable] = useState([]);
  
    const [NetAmount_CDmethod, setNetAmount_CDmethod] = useState({
      Method: "",
      Type:'',
      Amount: "",
    });
  
    
    const [ClinicDetials, setClinicDetials] = useState({
      ClinicLogo: null,
      ClinicName: "",
      ClinicGST: "",
      ClinicAddress: "",
      ClinicCity: "",
      ClinicState: "",
      ClinicCode: "",
      ClinicMobileNo: "",
      DrugLicenseNumber: "",
      ClinicMailID: "",
    });
  
    const [PostInvoice, setPostInvoice] = useState(null);

    const [clickedItemName, setClickedItemName] = useState("");


    const [Single_row_data, setSingle_row_data] = useState({
      ItemId: "",
      ItemName: "",
      Generic: "",
      HSNCode: "",
      BatchNo: "",
      Exp_Date: "",
      Original_AvailableQuantity:"",
      AvailableQuantity: "",
      Billing_Quantity: "",
      LeastSellableUnit:'',
      Billing_Total_Quantity: "",
      IsFOCAvailable:false,
      FOCQuantiy:'',
      FOC_Total_Quantity: "",
      TotalQuantity:'',
      Unit_Price: "",
      Amount: "",
      CD_Method:'',
      CD_Type: "",
      Cash_Discount: "",
      GST: "",
      Original_total: "",
      Total: "",
      CGST: "",
      SGST: "",
      GSTAmount: "",
    });


    const [summary, setSummary] = useState({
      totalItems: 0,
      totalQty: 0,
      totalBase: 0,
      Discount_method: "",
      Discount_type: "",
      Discount: 0,
      Amount: 0,
      GSTAmount: 0,
      totalAmount: 0,
      Roundoff: 0,  
      totalAmountt: 0,
    });
  

    useEffect(()=>{
      if(ItemSearch.SearchItemName !==''){
      axios.get(`${UrlLink}Cashier/Item_Names_All_Data_Link?SearchItemName=${ItemSearch.SearchItemName}&SearchItemCode=${ItemSearch.SearchItemCode}`)
      .then((res)=>{
        let Itemgetdata=res.data;

        
        if(Itemgetdata && Array.isArray(Itemgetdata) && Itemgetdata.length !==0){
          setItemarr(Itemgetdata)
        }
        else if (Itemgetdata && Object.keys(Itemgetdata).length !==0){
          setSingle_row_data((prev) => ({
            ...prev,
            ItemId:Itemgetdata.ItemCode,
            ItemName: Itemgetdata.ItemName,
            Generic: Itemgetdata.GenericName,
            HSNCode:Itemgetdata.HSNCode,
          }));
        }
      })
      .catch((err)=>{
          console.log(err);            
      })
      }
    },[UrlLink,ItemSearch.SearchItemName,ItemSearch.SearchItemCode])

  useEffect(()=>{
    if(BillEditdata && Object.keys(BillEditdata).length !==0){
      console.log('BillEditdata----137',BillEditdata);

      const {id,Bill_Status,Created_By,Location,...rest} =BillEditdata;

      setBilling_date(rest?.Billing_Date)

      setSelectedCustomer({
        InvoiceId:rest.InvoiceNo,
        CustomerId:rest.CustomerId,
        CustomerName:rest.Customer_Name,
        GSTnumber:rest.GSTnumber,
        PhoneNumber:rest.PhoneNumber,
        CustomerAddress:rest.CustomerAddress,
      })

      setBilling_itemtable(rest?.billing_items ? rest?.billing_items : [] )

    }
  },[BillEditdata])




  useEffect(() => {
    axios
      .get(`${UrlLink}Masters/Clinic_Detials_link`)
      .then((response) => {
        const clinicData = response.data[0];
        if (clinicData) {
          const addressComponents = [clinicData.DoorNo, clinicData.Area].filter(
            (component) => component
          );

          setClinicDetials((prev) => ({
            ...prev,
            ClinicAddress: addressComponents.join(", "),
            ClinicGST: clinicData.GSTNo || prev.ClinicGST,
            ClinicCity: clinicData.City || prev.ClinicCity,
            ClinicState: clinicData.State || prev.ClinicState,
            ClinicCode: clinicData.Pincode || prev.ClinicCode,
            ClinicMobileNo: clinicData.PhoneNo || prev.ClinicMobileNo,
            DrugLicenseNumber: clinicData.DrugLicenseNumber || prev.DrugLicenseNumber,
            ClinicMailID: clinicData.Mail || prev.ClinicMailID,
          }));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  
  }, [userRecord?.location, UrlLink]);


    
  useEffect(()=>{
      axios.get(`${UrlLink}Inventory/Customer_Master_Link`)
      .then((res)=>{
        let getdata=res.data.customers;
        setCustomerarr(getdata && Array.isArray(getdata) ? getdata : [])
      })
      .catch((err)=>{
          console.log(err);            
      })
  },[UrlLink])

  useEffect(() => {
    
    if(UrlLink && Single_row_data.ItemId !== '' && Single_row_data.ItemName !== ''){
      
      const senddata={
        SearchItemName:Single_row_data.ItemName,
        SearchItemCode:Single_row_data.ItemId,
      }

      axios.get(`${UrlLink}Cashier/get_Shelf_Names_For_Product`,{params:senddata})
      .then((res)=>{
        console.log('uuu-11111-???',res.data);
        if(res?.data && res?.data?.Rack_Name){
          setshelfview({
            RackName:res?.data?.Rack_Name,
            ShelfName:res?.data?.Shelf_Name 
          })
        }
      })
      .catch((err)=>{
        console.log(err);        
      })

      
      
      axios
        .get(
          `${UrlLink}Cashier/Batchno_Item_Names_All_Link`,{
            params:senddata
          }
        )
        .then((response) => {
          console.log('uuu-00988-???',response.data);

          let Itemgetdata = response.data
          if(Itemgetdata && Array.isArray(Itemgetdata) && Itemgetdata.length !==0){
            setgetStockid_Name(Itemgetdata)
          }
          else{
            setgetStockid_Name([])
          }
        })
        .catch((error) => {
          console.error(error);
        });
      }
      else{
        setgetStockid_Name([])
      }
    
  }, [UrlLink,Single_row_data.ItemId,Single_row_data.ItemName,Single_row_data.BatchNo]);


  useEffect(() => {
    
    if(UrlLink && ItemSearch.SearchItemName !== '' && ItemSearch.SearchItemCode !==''&& Single_row_data?.BatchNo !==''){
      
      let checkBatchNo=Billing_itemtable.find(ele => "" + ele.ItemId === "" + Single_row_data.ItemId && ele.BatchNo === Single_row_data.BatchNo)

      
      if(!Single_row_data?.S_No && checkBatchNo){

        setSingle_row_data((prevData) => ({
          ...prevData,
          BatchNo:'',
        }));

        const tdata = {
          message: `This Item : ${Single_row_data.ItemName} and Batch No : ${Single_row_data.BatchNo} alredy selected`,
          type: 'warn',
        };
        dispatchvalue({ type: 'toast', value: tdata });

      
      }
      else{
        const senddata={
          ...ItemSearch,
          SearchBatchNo:Single_row_data?.BatchNo 
        }
        
        axios
          .get(
            `${UrlLink}Cashier/Nurse_Item_Names_All_Link?`,{
              params:senddata
            }
          )
          .then((response) => {
            console.log('uuu-00988-???',response.data);
  
            let Itemgetdata = response.data
            if (Itemgetdata && Object.keys(Itemgetdata).length !==0){       
              
              // let checkAvqty=Billing_itemtable.find(ele => ele.BatchNo === Single_row_data.BatchNo)
  
              // let reenderQty = 0
              // if(checkAvqty){
              //   reenderQty =Itemgetdata.AvailableQuantity - checkAvqty.TotalQuantity
              // }
  
              setSingle_row_data((prev) => ({
                ...prev,
                Exp_Date: Itemgetdata.Expiry_Date,
                Original_AvailableQuantity:Itemgetdata.AvailableQuantity,
                Unit_Price: Itemgetdata.Selling_Price_Without_Tax,
                GST:Itemgetdata.GST,
                LeastSellableUnit:Itemgetdata.LeastSellableUnit,
              }));
            
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }  
     
      }
      else{
        setgetStockid_Name([])
      }
    
  }, [UrlLink,ItemSearch,Single_row_data.BatchNo]);



  

      useEffect(() => {

        let Billing_TotalQuantity = +Single_row_data.LeastSellableUnit * +Single_row_data.Billing_Quantity || 0;
        let FreeTotalQuantiy = +Single_row_data.LeastSellableUnit * +Single_row_data.FOCQuantiy || 0;
        
        let TotalQty = Billing_TotalQuantity + FreeTotalQuantiy
        
        let AvailQty = +Single_row_data.Original_AvailableQuantity
        
        let balaceAvalQty= AvailQty - TotalQty

        console.log('balaceAvalQty----2233333',balaceAvalQty);
        

        let Unit_Price = parseFloat(Single_row_data.Unit_Price) || 0;
        let CD_Type = Single_row_data.CD_Type || 0;
        let CD_Amount = +Single_row_data.Cash_Discount || 0;

        let GST = Single_row_data.GST || 0;
        let Amount = Unit_Price * Billing_TotalQuantity || 0;
        let discountAmount = 0;
        let GSTAmount=0
        let Total=0


        GSTAmount = (Amount * GST) / 100 || 0;          
        discountAmount= Amount 
        Total = Amount + GSTAmount || 0;

        if(Single_row_data.CD_Method === 'BeforeTax' )
        {

        if (CD_Type == "Percentage" && CD_Amount !==0) {
          discountAmount = Amount - (Amount * CD_Amount) / 100;
          GSTAmount = (discountAmount * GST) / 100 || 0;
          Total = discountAmount + GSTAmount || 0;
        } else if (CD_Type == "Cash" && CD_Amount !==0) {
          discountAmount = Amount - CD_Amount;
          GSTAmount = (discountAmount * GST) / 100 || 0;
          Total = discountAmount + GSTAmount || 0;
        }


        }
        else if(Single_row_data.CD_Method === 'AfterTax'){

          if (CD_Type == "Percentage" && CD_Amount !==0) {
            Total = Total - (Total * CD_Amount) / 100;
          } else if (CD_Type == "Cash" && CD_Amount !==0) {
            Total = Total - CD_Amount;
          }

        }


        setSingle_row_data((prevData) => ({
          ...prevData,
          AvailableQuantity:balaceAvalQty,
          Billing_Total_Quantity:Billing_TotalQuantity,
          FOC_Total_Quantity:FreeTotalQuantiy,
          TotalQuantity:TotalQty,
          Amount: parseFloat(Amount).toFixed(2),
          Original_total: parseFloat(discountAmount).toFixed(2),
          Total: parseFloat(Total).toFixed(2),
          GSTAmount: parseFloat(GSTAmount).toFixed(2),
        }));
      }, [
        Single_row_data.Billing_Quantity,
        Single_row_data.Unit_Price,
        Single_row_data.CD_Type,
        Single_row_data.Cash_Discount,
        Single_row_data.GST,
        Single_row_data.FOCQuantiy,
        Single_row_data.CD_Method,
        Single_row_data.Original_AvailableQuantity      
      ]);



    

      useEffect(()=>{

        if(Billing_itemtable.length !==0){
          let totalItems = 0;
          let totalQty = 0;
          let totalBase = 0;
          let totalOriginal = 0;
          let totalDiscount = 0; 
          let totalAmount = 0;
          let GSTAmount = 0;

          totalItems = Billing_itemtable.length;

          Billing_itemtable.forEach((item) => {
            
            let itemDiscount = 0;

            if (item.CD_Method === "BeforeTax") {
              if(item.CD_Type === "Percentage"){
              itemDiscount =(parseFloat(item.Amount) * parseFloat(item.Cash_Discount)) / 100 || 0;
            } else {
              itemDiscount = parseFloat(item.Cash_Discount) || 0;
            }
            }else if (item.CD_Method === "AfterTax"){
                if(item.CD_Type === "Percentage"){
                  itemDiscount =(parseFloat(item.Total) * parseFloat(item.Cash_Discount)) / 100 || 0;
                } else {
                  itemDiscount = parseFloat(item.Total) || 0;
                }
            }
            
            totalQty += +item.TotalQuantity;
            totalDiscount +=itemDiscount;
            totalBase += parseFloat(item.Amount) || 0;
            totalOriginal += parseFloat(item.Original_total) || 0;
            totalAmount += parseFloat(item.Total) || 0;
            GSTAmount += parseFloat(item.GSTAmount) || 0;
          });


          if (NetAmount_CDmethod.Method !=='' && NetAmount_CDmethod.Type !=='' && +NetAmount_CDmethod.Amount !==0){

            let updatedDiscount =0

            if (NetAmount_CDmethod.Method === 'BeforeTax') {
          
              if (NetAmount_CDmethod.Type === 'Cash') {
                updatedDiscount  = +NetAmount_CDmethod.Amount;
              }
              else if(NetAmount_CDmethod.Type === 'Percentage')
              {
                updatedDiscount  = totalOriginal * (+NetAmount_CDmethod.Amount / 100);

              }

              const totalTaxableAmount = totalOriginal - updatedDiscount;
              const totalGST = (totalTaxableAmount * GSTAmount) / totalOriginal;
              const totalWithGst = totalTaxableAmount + totalGST;

              let roundedAmount = Math.round(totalWithGst);
              let difference = roundedAmount - totalWithGst;


              setSummary((prev)=>({
                ...prev,
                totalItems:totalItems,
                totalQty:totalQty,
                totalBase:totalBase,
                Discount:updatedDiscount,
                Amount:totalTaxableAmount,
                GSTAmount:totalGST.toFixed(2),
                totalAmount:totalWithGst.toFixed(2),
                Roundoff: difference.toFixed(2),
                totalAmountt: roundedAmount.toFixed(2),
              })) 

            }
            
            else if(NetAmount_CDmethod.Method === 'AfterTax'){

              if (NetAmount_CDmethod.Type === 'Cash') {
                updatedDiscount  = +NetAmount_CDmethod.Amount;
              }
              else if(NetAmount_CDmethod.Type === 'Percentage')
              {
                updatedDiscount  = totalAmount * (+NetAmount_CDmethod.Amount / 100);

              }


              const totalWithGst = totalAmount - updatedDiscount;

              let roundedAmount = Math.round(totalWithGst);
              let difference = roundedAmount - totalWithGst;
    
                setSummary((prev)=>({
                  ...prev,
                  totalItems:totalItems,
                  totalQty:totalQty,
                  totalBase:totalBase,
                  Discount:updatedDiscount,
                  Amount:totalOriginal,
                  GSTAmount:GSTAmount.toFixed(2),
                  totalAmount:totalWithGst.toFixed(2),
                  Roundoff: difference.toFixed(2),
                  totalAmountt: roundedAmount.toFixed(2),
                })) 


            }
          
          }

          else{

            
          let roundedAmount = Math.round(totalAmount);
          let difference = roundedAmount - totalAmount;

            setSummary((prev)=>({
              ...prev,
              totalItems:totalItems,
              totalQty:totalQty,
              totalBase:totalBase,
              Discount:totalDiscount,
              Amount:totalOriginal,
              GSTAmount:GSTAmount.toFixed(2),
              totalAmount:totalAmount.toFixed(2),
              Roundoff: difference.toFixed(2),
              totalAmountt: roundedAmount.toFixed(2),
            })) 

          }
              
        
          }

      },[Billing_itemtable,NetAmount_CDmethod])
  

   

    const HandleCustomer = (e) => {
      const { name, value } = e.target

      if (name === "CustomerName") {

          let fil_cos=Customerarr.find(ele => ele.CustomerName === value)

          if(fil_cos && Object.keys(fil_cos).length !==0){

            setSelectedCustomer((prev) => ({
              ...prev,
              CustomerId:fil_cos.CustomerId,
              CustomerName:value,
              GSTnumber:fil_cos.GSTNumber,
              PhoneNumber:fil_cos.ContactNumber,
              CustomerAddress:fil_cos.Address,
            }));

          }else{
          setSelectedCustomer((prev) => ({
            ...prev,
            CustomerId:'',
            CustomerName:value,
            GSTnumber:'',
            PhoneNumber:'',
            CustomerAddress:'',
          }));
        }

      
      }
  
      else if (name === "PhoneNumber") {
        if (value.length <= 10) {

          let fil_cos=Customerarr.find(ele => +ele.ContactNumber === +value)

          if(fil_cos && Object.keys(fil_cos).length !==0){

            setSelectedCustomer((prev) => ({
              ...prev,
              CustomerId:fil_cos.CustomerId,
              CustomerName:fil_cos.CustomerName,
              GSTnumber:fil_cos.GSTNumber,
              PhoneNumber:value,
              CustomerAddress:fil_cos.Address,
            }));

          }else{
          setSelectedCustomer((prev) => ({
            ...prev,
            CustomerId:'',
            CustomerName:'',
            GSTnumber:'',
            PhoneNumber:value,
            CustomerAddress:'',
          }));
        }
        }
        else {
          alert("Enter the 10 digit Number")
        }
      }
      else {
        setSelectedCustomer((prev) => ({
          ...prev,
          [name]: value
        }))
      }
    }



    const Clearitemforbill = () => {

      setshelfview({
      RackName:'',
      ShelfName:''
      })
      setSingle_row_data({
        ItemId:'',
        ItemName: "",
        Generic: "",
        HSNCode: "",
        BatchNo: "",
        Exp_Date: "",
      Original_AvailableQuantity:"",
        AvailableQuantity: "",
        Billing_Quantity:'',
        LeastSellableUnit:'',
        Billing_Total_Quantity: "",
        IsFOCAvailable:false,
        FOCQuantiy:'',
        FOC_Total_Quantity:'',
        TotalQuantity:'',
        Unit_Price: "",
        Amount: "",
        CD_Method:'',
        CD_Type: "",
        Cash_Discount: "",
        GST: "",
        Original_total: "",
        Total: "",
        GSTAmount: "",
      });
    };


    const resetStates = () => {
      setSelectedCustomer({
        InvoiceId:'', 
        CustomerId: "",
        CustomerName: "",
        GSTnumber: "",
        PhoneNumber: "",
        CustomerAddress: "",
      });
      setItemSearch({ 
      SearchItemName:"",
      SearchItemCode:"",
      });
      setSummary({});
      setBilling_itemtable([]);
      setPostInvoice(null);
      setIsPrintButtonVisible(true);
      setgetStockid_Name([])
      Clearitemforbill()
    };
  

    const set_handleSearch = (e) => {
      const { name, value } = e.target;
      
      console.log('pppp-valueeee',value);
      
      if(value.includes('|'))
      {

        let Iname=value.split(' | ')[0]
        let ItemCode=value.split(' | ')[1]


        setItemSearch((prev) => ({
          ...prev,
          [name]:Iname,
          SearchItemCode:ItemCode,
        }));
        Clearitemforbill();
      }
      else{
        setItemSearch((prev) => ({
          ...prev,
          [name]:value,
          SearchItemCode:'',
        }));
        Clearitemforbill();
      }
    };



    const set_handleInputChange = (event) => {
      const { name, value } = event.target;

      if (name === "CD_Method") {
        setSingle_row_data((prevData) => ({
          ...prevData,
          [name]: value,
          CD_Type: "",
          Cash_Discount: "",
        }));
      }
     else if (name === "CD_Type") {
          setSingle_row_data((prevData) => ({
            ...prevData,
            [name]: value,
            Cash_Discount: "",
          }));
        }
        else if (name === "IsFOCAvailable") {
          setSingle_row_data((prevData) => ({
            ...prevData,
            [name]: value === 'Yes',
            FOCQuantiy:'',
            FOC_Total_Quantity:'',
          }));
        }
        else if (name === "Billing_Quantity") {

          let AvailQty = Single_row_data.Original_AvailableQuantity

          if(Single_row_data.LeastSellableUnit && +Single_row_data.LeastSellableUnit === 1)
          {
          if (AvailQty < value) {
            alert(`Available Quantity is ${AvailQty}`);
          } else {
            setSingle_row_data((prevData) => ({
              ...prevData,
              [name]: value,
            }));
          }
        }else if(Single_row_data.LeastSellableUnit && +Single_row_data.LeastSellableUnit !== 1)
        {

          let multivalue = +Single_row_data.LeastSellableUnit * value 
            if (AvailQty < multivalue) {
              alert(`Available Quantity is ${AvailQty}`);
            } else {
              setSingle_row_data((prevData) => ({
                ...prevData,
                [name]: value,
              }));
            }
        }
        }
        else if (name === "FOCQuantiy") {
          
          let AvailQty = Single_row_data.AvailableQuantity

          if(Single_row_data.LeastSellableUnit && +Single_row_data.LeastSellableUnit === 1)
          {
          if (AvailQty < value) {
            alert(`Available Quantity is ${AvailQty}`);
          } else {
            setSingle_row_data((prevData) => ({
              ...prevData,
              [name]: value,
            }));
          }
        }else if(Single_row_data.LeastSellableUnit && +Single_row_data.LeastSellableUnit !== 1)
        {

          let multivalue = +Single_row_data.LeastSellableUnit * value 
            if (AvailQty < multivalue) {
              alert(`Available Quantity is ${AvailQty}`);
            } else {
              setSingle_row_data((prevData) => ({
                ...prevData,
                [name]: value,
              }));
            }
        }
        }

        else{
        setSingle_row_data((prevData) => ({
          ...prevData,
          [name]: value,
        }));
        }
      
    };
  


 

    const Additemforbill = (No) => {

      let requiredfields = ['Billing_Quantity']
    

      if( Single_row_data.CD_Type !== "" &&
        Single_row_data.Cash_Discount === ""){
          requiredfields.push('Cash_Discount')
        }

      if( Single_row_data.IsFOCAvailable ){
            requiredfields.push('FOCQuantiy')
          }

      if( Single_row_data.IsFOCAvailable && Single_row_data.FOCQuantiy !=='' ){
        requiredfields=requiredfields.filter(ele => ele !=='Billing_Quantity')
      }
      
      let missingfield = requiredfields.filter((field) => !Single_row_data[field]);
      if (missingfield.length !== 0) {
        const tdata = {
            message: `Please fill out all required fields: ${missingfield.join(", ")}`,
            type: 'warn',
        };
        dispatchvalue({ type: 'toast', value: tdata });
    } 
    else{
      const Samedata = Billing_itemtable.findIndex(
        (repeat) => repeat.S_No === No
      );

      if (Samedata !== -1) {
        let upppdata = [...Billing_itemtable];
        upppdata[Samedata] = { S_No: No, ...Single_row_data };
        setBilling_itemtable(upppdata);

          Clearitemforbill()
          setItemSearch((prev) => ({
            ...prev,
            SearchItemName: '',
            SearchItemCode:"",
          }));
      }
      else {
        let dattt = Billing_itemtable.find(
          (p) => '' + p.ItemId === '' + Single_row_data.ItemId && p.BatchNo === Single_row_data.BatchNo
        );
        if (dattt) {
          const tdata = {
            message: `The Item Name already exists`,
            type: 'warn',
        };
        dispatchvalue({ type: 'toast', value: tdata });
        } else {
          const S_No = Billing_itemtable.length + 1;
          console.log("addddsiiiii", Single_row_data);

          setBilling_itemtable((prevBillingItemTable) => [
            ...prevBillingItemTable,
            { S_No, ...Single_row_data },
          ]);

          Clearitemforbill()
          setItemSearch((prev) => ({
            ...prev,
            SearchItemName: '',
            SearchItemCode:"",
          }));
        }
      }


    }   

    }



    const Editbillingitem = (single_Edit_data) => {
      console.log(single_Edit_data, "single_Edit_data");
 
      setItemSearch((prev) => ({
        ...prev,
        SearchItemName: single_Edit_data.ItemName,
        SearchItemCode:single_Edit_data?.ItemId,
      }));
  
      setSingle_row_data((prevData) => ({
        ...prevData,
        ItemId: single_Edit_data?.ItemId || "",
        ItemName: single_Edit_data?.ItemName || "",
        Original_AvailableQuantity:single_Edit_data?.Original_AvailableQuantity || "",
        AvailableQuantity: single_Edit_data?.AvailableQuantity || "",
        Generic: single_Edit_data?.Generic || "",
        HSNCode: single_Edit_data?.HSNCode  || "",
        BatchNo: single_Edit_data?.BatchNo || "",
        Exp_Date: single_Edit_data?.Exp_Date || "",
        Billing_Quantity:single_Edit_data?.Billing_Quantity || "",
        LeastSellableUnit:single_Edit_data?.LeastSellableUnit || "",
        Billing_Total_Quantity: single_Edit_data?.Billing_Total_Quantity || "",
        IsFOCAvailable: single_Edit_data?.IsFOCAvailable || false,
        FOCQuantiy: single_Edit_data?.FOCQuantiy || "",
        FOC_Total_Quantity:single_Edit_data.FOC_Total_Quantity,
        TotalQuantity: single_Edit_data?.TotalQuantity || "",
        Unit_Price: single_Edit_data?.Unit_Price || "",
        Amount: single_Edit_data?.Amount || "",
        CD_Method:single_Edit_data?.CD_Method || "",
        CD_Type: single_Edit_data?.CD_Type || "",
        Cash_Discount: single_Edit_data?.Cash_Discount || "",
        GST: single_Edit_data?.GST || "",
        Original_total: single_Edit_data?.Original_total || "",
        Total: single_Edit_data?.Total || "",
        GSTAmount: single_Edit_data?.GSTAmount || "",
        S_No: single_Edit_data?.S_No,
      }));
    };

    const deletebillingitem = (S_No) => {
      let Temp_delarr = Billing_itemtable.filter((ele) => ele.S_No !== S_No);
  
      // Add the modified array back to the state with the new S_No
      setBilling_itemtable(
        Temp_delarr.map((item, index) => ({ ...item, S_No: index + 1 }))
      );
    };




    const numberToWords = (number) => {
      let num = parseInt(number.toString().split(".")[0]);
  
      if (num === 0) {
        return "Zero";
      }
  
      const units = [
        "",
        "One",
        "Two",
        "Three",
        "Four",
        "Five",
        "Six",
        "Seven",
        "Eight",
        "Nine",
      ];
      const teens = [
        "Eleven",
        "Twelve",
        "Thirteen",
        "Fourteen",
        "Fifteen",
        "Sixteen",
        "Seventeen",
        "Eighteen",
        "Nineteen",
      ];
      const tens = [
        "",
        "",
        "Twenty",
        "Thirty",
        "Forty",
        "Fifty",
        "Sixty",
        "Seventy",
        "Eighty",
        "Ninety",
      ];
  
      const convert = (num) => {
        // Check if num is NaN or not a valid number
        if (isNaN(num) || num < 0 || !Number.isInteger(num)) {
          return "Invalid input";
        }
  
        if (num === 0) return "Zero";
        if (num < 10) return units[num];
        if (num < 20) return teens[num - 10];
        if (num < 100)
          return (
            tens[Math.floor(num / 10)] +
            (num % 10 !== 0 ? " " + units[num % 10] : "")
          );
        if (num < 1000)
          return units[Math.floor(num / 100)] + " Hundred " + convert(num % 100);
        if (num < 1000000)
          return (
            convert(Math.floor(num / 1000)) + " Thousand " + convert(num % 1000)
          );
        return (
          convert(Math.floor(num / 1000000)) +
          " Million " +
          convert(num % 1000000)
        );
      };
  
      return convert(num);
    };

  
    const handlePrint = useReactToPrint({
      contentRef: componentRef,
      onBeforePrint: async () => {
        console.log("Preparing to print...");
      },
      onAfterPrint: async () => {
      
            console.log("Print complete.");
            setIsPrintButtonVisible(true);
            resetStates();
            navigate("/Home/CashierFolder");
            dispatchvalue({ type: "HrFolder", value: "BillingList" });
      },
    });


    useEffect(() => {
      if (PostInvoice && !isPrintButtonVisible) {
        console.log('PostInvoice_____', PostInvoice, isPrintButtonVisible);
        console.log('PostInvoice_____2222', componentRef.current);
        
        if (componentRef) {
          setTimeout(() => {
            handlePrint(); 
          }, 500);
        } else {
          console.error("There is nothing to print, componentRef is null.");
        }
      }
    }, [PostInvoice, isPrintButtonVisible, handlePrint]);

    const Submitalldata = () => {

      let requiredfields =[
        'CustomerName',
        'PhoneNumber'
      ]


      if (SelectedCustomer.InvoiceId){
        requiredfields.push('InvoiceId')
      }

      const missingFields = requiredfields.filter(
        (field) => !SelectedCustomer[field]
     )


     if (missingFields.length !== 0) {

      const tdata = {
          message: `Please fill out all required fields: ${missingFields.join(", ")}`,
          type: 'warn',
      }
      dispatchvalue({ type: 'toast', value: tdata });
    }
    else if(Billing_itemtable.length === 0){

      const tdata = {
        message: `Please enter the billing items`,
        type: 'warn',
    }
    dispatchvalue({ type: 'toast', value: tdata });

    }
    else{


      let sendData = {
        SelectedCustomer:SelectedCustomer,
        Billing_date: Billing_date,
        Billing_itemtable:Billing_itemtable,
        NetAmount_CDmethod:NetAmount_CDmethod,
        summary: summary,
        User_Name: UserData.username,
        location: UserData.location,
      };

      

      axios.post(`${UrlLink}Cashier/Post_Billing_Details_Save_Data`,sendData)
      .then((res)=>{
        console.log('vvvvvvvvv',res.data); 
        
        let getdata =res.data
        
        console.log('getdata--0000',getdata);

        if(getdata && getdata?.Invoice){

        setPostInvoice(getdata?.Invoice)
        setIsPrintButtonVisible(false)
        }
        
       
  

      })
      .catch((err)=>{
        console.log(err);        
      })

    }


      


      
     
   
        
 
    };


    


  


      const forPrintData = () => {
        return (
          <div ref={componentRef}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                minHeight: "100vh",
              }}
            >
              <table
                className="print-table33"
              // style={{ flexGrow: 1,  }}
              >
                {/* Header Section */}
                <thead
                  className="print_header"
                  style={{
                    width: "100%",
                    borderCollapse: "collapse",
                    height: "150px",
                  }}
                >
                  <tr>
                    <td colSpan="7" style={{ border: "none" }}>
                      <div className="New_billlling_invoice_head">
                        <div className="new_billing_logo_con">
                          <img src={kghospital} alt="Medical logo" />
                        </div>
                        <div className="new_billing_address_1">
                          <span>{ClinicDetials.ClinicName}</span>
                          <div>
                            <span>
                              {[ClinicDetials.ClinicAddress]
                                .filter((detail) => detail)
                                .join(", ")}
                            </span>
                            <span>
                              {[
                                ClinicDetials.ClinicCity,
                                ClinicDetials.ClinicState,
                                ClinicDetials.ClinicCode,
                              ]
                                .filter((detail) => detail)
                                .join(", ")}
                            </span>
                          </div>
                          <div>
                            {[
                              ClinicDetials.ClinicMobileNo,
                              ClinicDetials.DrugLicenseNumber,
                              ClinicDetials.ClinicMailID,
                            ]
                              .filter((detail) => detail)
                              .join(" , ")}
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td
                      colSpan="7"
                      style={{
                        textAlign: "center",
                        fontWeight: "600",
                        color: "black",
                        fontSize: '18px',
                      }}
                    >
                      Pharmacy Billing
    
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="7">
                      <div className="new_billing_address">
                        <div className="new_billing_address_2">
                          <div className="new_billing_div phrmy_newbilg wdwdwswdw">
                            <label>
                             Customer Name<span>:</span>
                            </label>
                            <h4>{SelectedCustomer.CustomerName}</h4>
                          </div>
    
                          <div className="new_billing_div phrmy_newbilg wdwdwswdw">
                            <label>
                              GST Number <span>:</span>
                            </label>
                            <h4>{SelectedCustomer.GSTnumber}</h4>
                          </div>
                          <div className="new_billing_div phrmy_newbilg mvfg_p7u">
                            <label>
                              {" "}
                              Phone Number <span>:</span>
                            </label>
                            <h4>{SelectedCustomer.PhoneNumber}</h4>
                          </div>  
    
    
                        </div>
    
                        <div className="new_billing_address_2">
                          <div className="new_billing_div phrmy_newbilg mvfg_p7u">
                            <label>
                              Invoice No <span>:</span>
                            </label>
                            <h4>{PostInvoice}</h4>
                          </div>
                          <div className="new_billing_div phrmy_newbilg mvfg_p7u">
                            <label>
                              GSTIN No <span>:</span>
                            </label>
                            <h4>{ClinicDetials.ClinicGST}</h4>
                          </div>
                          <div className="new_billing_div phrmy_newbilg mvfg_p7u">
                            <label>
                              Drug License No <span>:</span>
                            </label>
                            <h4> DL497465 </h4>
                          </div>
    
                          <div className="new_billing_div phrmy_newbilg mvfg_p7u">
                            <label>
                              Date <span>:</span>
                            </label>
                            <h4>{Billing_date}</h4>
                          </div>
                          
                        </div>
                      </div>
                    </td>
    
    
    
                  </tr>
    
                </thead>
                <br />
                <tbody >
                  <tr>
                    <td colSpan="7" className="prin_nnrmll_table">
                      <table style={{ width: "100%", borderCollapse: "collapse" }}>
                        <thead>
                          <tr>
                            <th>S.No</th>
                            <th>Item Name</th>
                            <th>Batch/HSN No</th>
                            <th>Exp Date</th>
                            <th>Quantity</th>
                            <th>Unit Price</th>
                            <th>Amount</th>
                            <th>Discount</th>
                            {/* <th>Amount</th> */}
                            <th>CGST %</th>
                            <th>SGST %</th>
                            <th>GSTAmount</th>
                            <th>Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Billing_itemtable.map((medicineInfo, index) => {
                            const CGST = medicineInfo.GST / 2 || 0;
                            const SGST = medicineInfo.GST / 2 || 0;
                            return (
                              <tr key={index}>
                                <td>{medicineInfo.S_No}</td>
                                <td>{medicineInfo.ItemName}</td>
                                <td>
                                  {medicineInfo.BatchNo}/<br />
                                  {medicineInfo.HSNCode}
                                </td>
                                <td>{medicineInfo.Exp_Date}</td>
                                <td>{medicineInfo.Billing_Quantity}</td>
                                <td>{medicineInfo.Unit_Price}</td>
                                <td>{medicineInfo.Amount}</td>
                                {medicineInfo.CD_Type === "Percentage" ? (
                                  <td>{medicineInfo.Cash_Discount} % </td>
                                ) : medicineInfo.CD_Type === "Cash" ? (
                                  <td> Rs. {medicineInfo.Cash_Discount}</td>
                                ) : (
                                  <td>No Discount</td>
                                )}
                                {/* <td>{medicineInfo.Original_total}</td> */}
                                <td>{CGST}%</td>
                                <td>{SGST}%</td>
                                <td>{medicineInfo.GSTAmount}</td>
                                <td>{medicineInfo.Total}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </td>
                  </tr>
    
    
    
                </tbody>
                <div>
                  <div colSpan="7">
                    <div className="new_billing_invoice_detials">
                      <div className="invoice_detials_total_1">
                        <div className="total_con_bill">
                          <div className="bill_body_new_phar">
                            <label>
                              Amount In Words <span>:</span>
                            </label>
                            <span>
                              {numberToWords(+summary.totalAmountt)}{" "}
                            </span>
                          </div>
                          <div className="bill_body_new_phar">
                            <label>
                              Billed By <span>:</span>
                            </label>
                            <p>{userRecord?.username} </p>
                          </div>
                        </div>
    
    
    
                        <div>
                          <div className="bill_body_new_phar">
                            <label>
                              Gross Amount <span>:</span>
                            </label>
                            <h4>{summary.Amount}</h4>
                          </div>
                          <div className="bill_body_new_phar">
                            <label>
                              GST <span>:</span>
                            </label>
                            <h4>{summary.GSTAmount}</h4>                  </div>
                          <div className="bill_body_new_phar">
                            <label>
                              {" "}
                              Discount <span>:</span>
                            </label>
                            <h4>{summary.Discount}</h4>
                          </div>
    
                          <div className="bill_body_new_phar">
                            <label>
                              {" "}
                              Net Amount <span>:</span>
                            </label>
                            <h4>{summary.totalAmount}</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
    
                <tfoot className="print_footerr">
                  <tr className="jjxcdsjjej_"></tr>
    
                  <tr className="ehdhe_9ikw">
                    <td className="shshxhxs_secfooter">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <p className="disclaimer23">
                          This page is created automatically without a signature.
                        </p>
                      </div>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
    
    
          </div>
        );
      };

  return (
    
      <>

      {isPrintButtonVisible ? (

<div className="Main_container_app">
      <div className="new-patient-registration-form">

          <div className="QuickStock_container_header">
            <h4>
              <ShoppingCartIcon />
              Billing
            </h4>
          </div>

          <div className="mannual-header-with uiwe_uywg66">
            <div className="jkewdkx70_866">
              <br />
              <div className="invoice_firstpart added_ivce_fistprt">
                <div className="RegisFormcon">
                  
                  
                <div className="added_regisFrm1">
                    <label htmlFor="Date">
                      Date<span>:</span>
                    </label>
                    <input
                      type="Date"
                      name="Billing_date"
                      value={Billing_date} 
                      onChange={(e) =>
                        setBilling_date(e.target.value)
                      }
                    />
                  </div>


                 {SelectedCustomer?.InvoiceId && <div className="added_regisFrm1">
                      <label htmlFor="GSTnumber">
                      Invoice Number <span>:</span>
                      </label>
                      <input
                        disabled
                        type="text"
                        value={SelectedCustomer.InvoiceId}
                        name="InvoiceId"
                        onChange={HandleCustomer}
                      />
                    </div>}

                  <div className="added_regisFrm1">
                    <label htmlFor="CustomerName">
                      Customer Name <span>:</span>
                    </label>
                    <input
                      type="text"
                      value={SelectedCustomer.CustomerName}
                      name="CustomerName"
                      onChange={HandleCustomer}
                      list="CustomerName"
                      disabled={SelectedCustomer.InvoiceId}
                    />
                    <datalist id="CustomerName">
                    {Customerarr.length !==0 &&
                      Customerarr.map((item, index) => (
                        <option
                          key={index}
                          value={`${item.CustomerName || ""}`}
                        ></option>
                      ))}
                  </datalist>
                  </div>

                  <div className="added_regisFrm1">
                    <label htmlFor="PhoneNumber">
                      Phone Number <span>:</span>
                    </label>
                    <input
                      type="number"
                      value={SelectedCustomer.PhoneNumber}
                      name="PhoneNumber"
                      onChange={HandleCustomer}
                      list="PhoneNumber"
                      disabled={SelectedCustomer.InvoiceId}
                    />
                    <datalist id="PhoneNumber">
                    {Customerarr.length !==0 &&
                      Customerarr.map((item, index) => (
                        <option
                          key={index}
                          value={`${item.ContactNumber || ""}`}
                        ></option>
                      ))}
                  </datalist>
                  </div>

                  <div className="added_regisFrm1">
                      <label htmlFor="GSTnumber">
                        GST Number <span>:</span>
                      </label>
                      <input
                        disabled
                        type="text"
                        value={SelectedCustomer.GSTnumber}
                        name="GSTnumber"
                        onChange={HandleCustomer}
                      />
                    </div>


          

                 

                 
                   

                </div>
<br />

                <div className="RegisFormcon">
                <div className="added_regisFrm1">
                    <label htmlFor="CustomerAddress">
                      Address <span>:</span>
                    </label>
                    <textarea
                      disabled
                      type="text"
                      value={SelectedCustomer.CustomerAddress}
                      name="CustomerAddress"
                      onChange={HandleCustomer}
                    />
                  </div>

                  </div>


              </div>
            </div>

            <div className="jkewdkx70_866" style={{marginTop:'15px'}}>
            <div className="added_ivce_hed" >
                <h4>SEARCH PRODUCT DETAILS</h4>
              </div>

              <div className="RegisFormcon" style={{marginTop:'10px'}}>
                <div className="added_regisFrm1">
                  <label htmlFor="SearchItemName">Item Name<span className="requirreg12">*</span></label>
                  <input
                    list="SearchItemName"
                    name="SearchItemName"
                    value={ItemSearch.SearchItemName}
                    onChange={set_handleSearch}
                    autoComplete="off"
                  />
                  <datalist id="SearchItemName">
                    {Itemarr && Itemarr.length > 0 &&
                      Itemarr.map((item, index) => (
                        <option
                          key={index}
                          value={`${item.ItemName} | ${item.ItemCode}`}
                        >
                        </option>
                      ))}
                  </datalist>
                </div>
              
               {shelfview.ShelfName && <>
                <div className="RegisFormcon">
                <div className="added_regisFrm1">

                <label htmlFor="ShelfName">Rack Name <span>:</span> </label>
                <input
                    value={shelfview.RackName}
                    disabled
                  />
                </div>
                </div>


                <div className="RegisFormcon">
                <div className="added_regisFrm1">
                <label htmlFor="ShelfName">Shelf Name <span>:</span> </label>
                <input
                    value={shelfview.ShelfName}
                    disabled
                  />
                </div>
                </div>
                </> }


              </div>

              

            </div>


            {Single_row_data.ItemId !=='' && (
              <div className="jkewdkx70_866">
                <div className="Add_items_Purchase_Master added_ivce_hed">
                  <h4>ITEM DETAILES</h4>
                </div>

                <div className="RegisFormcon" >

                  <div className="added_regisFrm1" >
                    <label htmlFor="browser">Item Name<span>:</span></label>
                    <input
                      name="ItemName"
                      value={Single_row_data.ItemName}
                      onChange={set_handleInputChange}
                      autoComplete="off"
                      disabled
                    />
                  </div>
                 
                  <div className="added_regisFrm1">
                    <label htmlFor="Generic">Generic Name<span>:</span></label>
                    <input
                      name="Generic"
                      id="Generic"
                      list="Genericss"
                      value={Single_row_data.Generic}
                      onChange={set_handleInputChange}
                      disabled
                    />
                  </div>
                  <div className="added_regisFrm1">
                    <label htmlFor="BatchNo">BatchNo<span className="requirreg12">*</span></label>
                    <input
                      name="BatchNo"
                      id="BatchNo"
                      value={Single_row_data.BatchNo}
                      onChange={set_handleInputChange}
                      list="BatchNolist"
                    />
                    <datalist id="BatchNolist">
                    {getStockid_Name.length !==0 &&
                      getStockid_Name.map((item, index) => (
                        <option
                          key={index}
                          value={`${item.Batch_No}`}
                        >
                          {`Ava.Qty:${item.AvailableQuantity} |
                           MRP:${parseFloat(item.MRP).toFixed(2)}`}
                        </option>
                      ))}
                  </datalist>
                  </div>
                  <div className="added_regisFrm1">
                    <label htmlFor="Exp_Date">Expiry Date</label>
                    <input
                      name="Exp_Date"
                      id="Exp_Date"
                      value={Single_row_data.Exp_Date}
                      onChange={set_handleInputChange}
                      disabled
                    />
                  </div>
                  <div className="added_regisFrm1">
                    <label htmlFor="AvailableQuantity">Available Quantity<span>:</span></label>
                    <input
                      name="AvailableQuantity"
                      id="AvailableQuantity"
                      value={Single_row_data.AvailableQuantity || "-"}
                      onChange={set_handleInputChange}
                      readOnly
                    />
                  </div>
                  <div className="added_regisFrm1 added_regisFrm1_small_inpt" >
                    <label htmlFor="Billing_Quantity">Billing Quantity<span className="requirreg12">*</span></label>
                    <input
                      name="Billing_Quantity"
                      id="Billing_Quantity"
                      value={Single_row_data.Billing_Quantity}
                      onChange={set_handleInputChange}
                    /> 
                    <input
                    name="LeastSellableUnit"
                    id="LeastSellableUnit"
                    value={Single_row_data.LeastSellableUnit}
                    onChange={set_handleInputChange}
                    disabled
                    />
                    <input
                      name="Billing_Total_Quantity"
                      id="Billing_Total_Quantity"
                      value={Single_row_data.Billing_Total_Quantity}
                      onChange={set_handleInputChange}
                     disabled
                    />
                  </div>
       

                  <div className="added_regisFrm1 added_regisFrm1_Yes_No" >
                  
                  <label>IsFOC Available <span>:</span></label>
                  <div style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    width: "150px",
                    gap: "10px",
                  }}
                  className="wdyuwyud_js7">
                  <label style={{ width: 'auto' }} htmlFor='IsFOCAvailable_yes'>
                  <input
                    required
                    id='IsFOCAvailable_yes'
                    type="radio"
                    name="IsFOCAvailable"
                    value='Yes'

                    checked={Single_row_data.IsFOCAvailable}
                    onChange={set_handleInputChange}
                    />
                  Yes
                  </label>
                  <label style={{ width: 'auto' }} htmlFor='IsFOCAvailable_No'>
                    <input
                        required
                        id='IsFOCAvailable_No'
                        type="radio"
                        name="IsFOCAvailable"
                        value='No'
                      
                        checked={!Single_row_data.IsFOCAvailable}
                        onChange={set_handleInputChange}
                    />
                    No
                    </label>

                  </div>
                  </div>

                  {Single_row_data.IsFOCAvailable && 
                  <div className="added_regisFrm1 added_regisFrm1_small_inpt" >
                    <label htmlFor="FOCQuantiy">FOC Quantiy<span className="requirreg12">*</span></label>
                    <input
                      name="FOCQuantiy"
                      id="FOCQuantiy"
                      value={Single_row_data.FOCQuantiy}
                      onChange={set_handleInputChange}
                      disabled={!Single_row_data.IsFOCAvailable}
                    />
                     <input
                      name="LeastSellableUnit"
                      id="LeastSellableUnit"
                      value={Single_row_data.LeastSellableUnit}
                      onChange={set_handleInputChange}
                      disabled
                    />
                     <input
                      name="FOC_Total_Quantity"
                      id="FOC_Total_Quantity"
                      value={Single_row_data.FOC_Total_Quantity}
                      onChange={set_handleInputChange}
                      disabled
                    />
                  </div>}


                  <div className="added_regisFrm1" >
                    <label htmlFor="TotalQuantity">Total Quantity<span>:</span></label>
                    <input
                      name="TotalQuantity"
                      id="TotalQuantity"
                      value={Single_row_data.TotalQuantity}
                      onChange={set_handleInputChange}
                      type="number"
                      disabled
                    />
                  </div>
                  <div className="added_regisFrm1" >
                    <label htmlFor="Unit_Price">Unit Price<span>:</span></label>
                    <input
                      name="Unit_Price"
                      id="Unit_Price"
                      value={Single_row_data.Unit_Price}
                      onChange={set_handleInputChange}
                      readOnly
                    />
                  </div>
                  <div className="added_regisFrm1">
                    <label htmlFor="Amount">Amount<span>:</span></label>
                    <input
                      name="Amount"
                      id="Amount"
                      value={Single_row_data.Amount}
                      onChange={set_handleInputChange}
                      readOnly
                    />
                  </div>
                  <div className="added_regisFrm1 added_regisFrm1_frselect" >
                    <label htmlFor="CD_Method">Discount Method<span className="requirreg12">*</span></label>
                    <select
                      name="CD_Method"
                      value={Single_row_data.CD_Method}
                      onChange={set_handleInputChange}
                    >
                      <option value="">Select</option>
                      <option value="BeforeTax">Before Tax</option>
                      <option value="AfterTax">After Tax</option>
                    </select>
                  </div>
                  <div className="added_regisFrm1 added_regisFrm1_frselect" >
                    <label htmlFor="CD_Type">Discount Type<span className="requirreg12">*</span></label>
                    <select
                      name="CD_Type"
                      value={Single_row_data.CD_Type}
                      onChange={set_handleInputChange}
                      disabled={Single_row_data.CD_Method === ''}
                    >
                      <option value="">Select</option>
                      <option value="Cash">Cash</option>
                      <option value="Percentage">Percentage</option>
                    </select>
                  </div>
                  <div className="added_regisFrm1" >
                    <label htmlFor="Cash_Discount">Discount<span className="requirreg12">*</span></label>
                    <input
                      name="Cash_Discount"
                      id="Cash_Discount"
                      value={Single_row_data.Cash_Discount}
                      onChange={set_handleInputChange}
                      disabled={Single_row_data.CD_Type === ''}
                    />
                  </div>
                  <div className="added_regisFrm1">
                    <label htmlFor="Original_total">Final Amount<span>:</span></label>
                    <input
                      name="Original_total"
                      id="Original_total"
                      value={Single_row_data.Original_total}
                      onChange={set_handleInputChange}
                      readOnly
                    />
                  </div>
                  <div className="added_regisFrm1" >
                    <label htmlFor="GST">GST<span>:</span></label>
                    <input
                      name="GST"
                      id="GST"
                      value={Single_row_data.GST}
                      onChange={set_handleInputChange}
                      readOnly
                    />
                  </div>
                  <div className="added_regisFrm1" >
                    <label htmlFor="Total">Total<span>:</span></label>
                    <input
                      name="Total"
                      id="Total"
                      value={Single_row_data.Total}
                      onChange={set_handleInputChange}
                      readOnly
                    />
                  </div>
                </div>

                <div className="Register_btn_con added_Register_btn_con" style={{marginTop:'15px'}}>
                  <button
                    className="RegisterForm_1_btns added_RegisterForm_1_btns"
                    onClick={() => Additemforbill(Single_row_data.S_No)}
                  >
                    {Single_row_data.S_No ? 'Update' : 'Add'}
                  </button>
                  <button
                    className="RegisterForm_1_btns added_RegisterForm_1_btns"
                    onClick={() => Clearitemforbill(Single_row_data.S_No)}
                  >
                    Clear
                  </button>
                </div>
              </div>
            )}

          </div>
          
          <div className="Selected-table-container444 DEWSDXWED" style={{marginTop:'10px'}}>
            <table className="selected-medicine-table222 EDWEDE">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Item Name</th>
                  <th>Batch/HSN No</th>
                  <th>Exp Date</th>
                  <th>Quantity</th>
                  <th>FOC Quantiy</th>
                  <th>Total Quantity</th>
                  <th>Unit Price</th>
                  <th>Amount</th>
                  <th>Discount Method</th>
                  <th>Discount</th>
                  {/* <th>Amount</th> */}
                  <th>GST %</th>
                  <th>GSTAmount</th>
                  <th>Total</th>

                  <th className="hideDataForPrint">Action</th>
                </tr>
              </thead>
              {console.log("Billing_itemtableee", Billing_itemtable)}
              <tbody>
                {Billing_itemtable.map((medicineInfo, index) => {
                  const CGST = medicineInfo.GST / 2 || 0;
                  const SGST = medicineInfo.GST / 2 || 0;

                  return (
                    <tr key={index}>
                      <td>{medicineInfo.S_No}</td>
                      <td
                        onClick={() =>
                          setClickedItemName(medicineInfo.ItemName)
                        }
                        style={{ cursor: "pointer", color: "blue" }}
                      >
                        {medicineInfo.ItemName}
                      </td>
                      <td>
                        {medicineInfo.BatchNo}/{medicineInfo.HSNCode}
                      </td>
                      <td>{medicineInfo.Exp_Date}</td>
                      <td>{medicineInfo.Billing_Quantity}{'*'}{medicineInfo.LeastSellableUnit}{'='}{medicineInfo.Billing_Total_Quantity}</td>
                      <td>
                      {+medicineInfo.FOCQuantiy !== 0
                          ? `${medicineInfo.FOCQuantiy} * ${medicineInfo.LeastSellableUnit} = ${medicineInfo.FOC_Total_Quantity}`
                          : ''}
                      </td>
                      <td>{medicineInfo.TotalQuantity}</td>
                      <td>{medicineInfo.Unit_Price}</td>
                      <td>{medicineInfo.Amount}</td>
                      <td>{medicineInfo.CD_Method}</td>
                      {medicineInfo.CD_Type === "Percentage" ? (
                        <td>{medicineInfo.Cash_Discount} % </td>
                      ) : medicineInfo.CD_Type === "Cash" ? (
                        <td> Rs. {medicineInfo.Cash_Discount}</td>
                      ) : (
                        <td>No Discount</td>
                      )}
                      <td>{medicineInfo.GST}%</td>
                      <td>{medicineInfo.GSTAmount}</td>
                      <td>{medicineInfo.Total}</td>
                      <td>
                        <div className="Action_btns">
                          <button
                            className="delnamebtn"
                            onClick={() => {
                              Editbillingitem(medicineInfo);
                            }}
                          >
                            <EditIcon />
                          </button>
                          <button
                            className="delnamebtn"
                            onClick={() => {
                              deletebillingitem(medicineInfo.S_No);
                            }}
                          >
                            <DeleteIcon />
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <br></br>

          <div className="summary-container addded_sumry_contre">
            <div className="RegisFormcon" style={{ justifyContent: "center", gap: '10px' }}>
              <div className="added_regisFrm1">
                <label htmlFor="">
                  Discount Method<span>:</span>{" "}
                </label>
                <select
                  disabled={Billing_itemtable.reduce((acc, e) => acc + +e.Cash_Discount, 0) !== 0}
                  name="CDMethod"
                  value={NetAmount_CDmethod.Method}
                  onChange={(e) => {
                    setNetAmount_CDmethod((prev) => ({
                      ...prev,
                      Method: e.target.value,
                      Type:'',
                      Amount: "",
                    }));
                  }}
                >
                  <option value="">Select</option>
                  <option value="BeforeTax">Before Tax</option>
                  <option value="AfterTax">After Tax</option>
                </select>
              </div>
              <div className="added_regisFrm1">
                <label htmlFor="">
                  Discount Type<span>:</span>{" "}
                </label>
                <select
                  name="Type"
                  value={NetAmount_CDmethod.Type}
                  disabled={NetAmount_CDmethod.Method === ''}
                  onChange={(e) => {
                    setNetAmount_CDmethod((prev) => ({
                      ...prev,
                      Type: e.target.value,
                      Amount: "",
                    }));
                  }}
                >
                  <option value="">Select</option>
                  <option value="Cash">Cash</option>
                  <option value="Percentage">Percentage</option>
                </select>
              </div>
              <div className="added_regisFrm1">
                <label htmlFor="">
                  Cash Discount<span>:</span>
                </label>
                <input
                  type="number"
                  onKeyDown={blockInvalidChar}
                  name="CashDiscount"
                  value={NetAmount_CDmethod.Amount}
                  onChange={(e) => {
                    if (NetAmount_CDmethod.Method !== "") {
                      setNetAmount_CDmethod((prev) => ({
                        ...prev,
                        Amount: e.target.value,
                      }));
                    } else {
                      alert("Please Choose an Discount Method");
                    }
                  }}
                  disabled={NetAmount_CDmethod.Type === ''}
                />
              </div>
            </div>
          </div>

          <div className="summary-container addded_sumry_contre">

            <div className="edcwjkediu87ccc">
              <div className="ewscjusd_s89i8i">
                <div className="ewrfewfew233">

                  <div className="edwqw_c2">


                    <label>
                      Amount in Words <span>:</span>{" "}
                    </label>
                    <h4
                      style={{ color: "#808080b5", padding: "0px 0px 0px 5px" }}
                    >
                      {numberToWords(+summary.totalAmount || 0)}{" "}
                    </h4>


                  </div>

                  <div className="edwqw_c2">

                    <label>
                      Billed By <span>:</span>{" "}
                    </label>
                    <h4
                      style={{ color: "#808080b5", padding: "0px 0px 0px 5px" }}
                    >
                      {UserData?.username}
                    </h4>

                  </div>


                </div>


              </div>


              <div>
                <div className="ewscjusd_s89i8">
                  <div
                    className="RegisFormconBill"
                    style={{
                      justifyContent: "center",
                      rowGap: "8px",
                      paddingTop: "5px",
                    }}
                  >
                    <div className="clm-itm-stl">
                      <label>Gross Amount :</label>
                      <input value={summary.totalBase} readOnly />
                    </div>
                    <div className="clm-itm-stl">
                      <label>GST :</label>
                      <input value={summary.GSTAmount} readOnly />
                    </div>
                    <div className="clm-itm-stl">
                      <label>Discount :</label>
                      <input value={summary.Discount} readOnly />
                    </div>
                    <div className="clm-itm-stl">
                      <label>Item Amount :</label>
                      <input
                        style={{ backgroundColor: "yellow" }}
                        value={summary.totalAmount}
                        readOnly
                      />
                    </div>

                    <div className="clm-itm-stl">
                      <label>Round-Off :</label>
                      <input value={summary.Roundoff} readOnly />
                    </div>

                    <div className="clm-itm-stl">
                      <label>Net Amount :</label>
                      <input
                        style={{ backgroundColor: "red" }}
                        value={summary.totalAmountt}
                        readOnly
                      />
                    </div>
                  </div>


                </div>



              </div>
            
            </div>
            <br />


           


          </div>

          {isPrintButtonVisible && (
            <div className="Register_btn_con added_Register_btn_con">
              <button
                className="RegisterForm_1_btns added_RegisterForm_1_btns"
                onClick={Submitalldata}
              >
                Save
              </button>
            </div>
          )}

      </div>
      </div>

      ) : (
        forPrintData()
      )}
      
      <ToastAlert Message={toast.message} Type={toast.type} />

      </>



  )
}

export default PharmacyBillingNew;