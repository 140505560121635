
import React, { useCallback, useEffect, useState } from 'react'
import LoupeIcon from "@mui/icons-material/Loupe";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import EditIcon from "@mui/icons-material/Edit";
import Button from "@mui/material/Button";
import ToastAlert from '../../OtherComponent/ToastContainer/ToastAlert';
import ReactGrid from '../../OtherComponent/ReactGrid/ReactGrid';



const UserRegistrationList = () => {


    const navigate = useNavigate();

    const dispatchvalue = useDispatch();

    const today = new Date();
    const currentDate = today.toISOString().split('T')[0];

    const UrlLink = useSelector(state => state.userRecord?.UrlLink);
    const toast = useSelector(state => state.userRecord?.toast);
    const userRecord = useSelector((state) => state.userRecord?.UserData);
    

    const [Userarry, setUserarry] = useState([])

    const [Render, setRender] = useState(false)


    const [searchBy, setSearchBy] = useState({
        Status: '',
        Name: '',
        Mobile: ''
    })


    useEffect(() => {
        axios.get(`${UrlLink}Masters/employee_rigistraion_userrigistor`, {
            params: searchBy
        })
            .then((res) => {
                console.log('ggg', res.data);
                setUserarry(res.data && Array.isArray(res.data) && res.data.length !== 0 ? res.data : [])
            })
            .catch((err) => {
                console.log(err);
            })
    }, [UrlLink, Render, searchBy])


    const handleChange = (e) => {
        const { name, value } = e.target;

        setSearchBy((prev) => ({
            ...prev,
            [name]: value
        }))

    }

    const handleNewMaster = () => {
        dispatchvalue({ type: 'EmployeeListId', value: {} });

        navigate("/Home/Master");
        dispatchvalue({ type: "HrFolder", value: "UserRegistration" });
        dispatchvalue({ type: "setPreviousFolder", value: null });
        dispatchvalue({ type: "showMenu", value: true });

    };


    const HandleEditdata = (row) => {
        dispatchvalue({ type: 'EmployeeListId', value: row });

        navigate("/Home/Master");
        dispatchvalue({ type: "HrFolder", value: "UserRegistration" });
        dispatchvalue({ type: "setPreviousFolder", value: null });
        dispatchvalue({ type: "showMenu", value: true });
    }


    const HandleEditStatus = (row) => {

        if (row) {

            let senddata = {
                EditId: row.id
            }

            axios.post(`${UrlLink}Masters/employee_rigistraion_userrigistor`, senddata)
                .then((res) => {
                    console.log(res.data);

                    const resres = res.data
                    let type = Object.keys(resres)[0]
                    let mess = Object.values(resres)[0]
                    const tdata = {
                        message: mess,
                        type: type,
                    }
                    dispatchvalue({ type: 'toast', value: tdata });
                    setRender(!Render)
                })
                .catch((err) => {
                    console.log(err);
                })
        }

    }


    const UserarryColumn = [
        {
            key: 'id',
            name: 'Id',
            frozen: true
        },
        {
            key: 'name',
            name: 'Name',
            frozen: true
        },
        {
            key: 'gender',
            name: 'Gender',
        },
        {
            key: 'mobile',
            name: 'Mobile',
        },
        {
            key: 'email',
            name: 'Email',
        },
        {
            key: 'designation',
            name: 'Designation',
        },
        {
            key: 'user_name',
            name: 'User Name',
        },
        {
            key: 'Status',
            name: 'Status',
            renderCell: (params) => (
                <>
                   { userRecord.EmployeeEditApprove === "Yes" ? <Button className="cell_btn" onClick={() => HandleEditStatus(params.row)}>
                        {params.row.Status === true ? 'Active' : 'InActive'}
                    </Button>
                    :
                    <>{params.row.Status === true ? 'Active' : 'InActive'}</>}
                </>
            )
        },
        {
            key: 'Action',
            name: 'Action',
            renderCell: (params) => (
                <>
                     { userRecord.EmployeeEditApprove === "Yes" ? params.row.Status === true ?
                       
                       <Button className="cell_btn" onClick={() => HandleEditdata(params.row)}>
                            <EditIcon className="check_box_clrr_cancell" />
                        </Button> :
                        <Button className="cell_btn" >
                            No Action
                        </Button>   : <> </>}
                </>
            )
        }

    ]

    return (
        <>
            <div className="Main_container_app">
                <h3>User Registration List</h3>
                <br />





                <div className="RegisFormcon_1">


                    <div className="RegisForm_1">
                        <label htmlFor="Name">
                            Status
                            <span>:</span>
                        </label>

                        <select
                            id="Status"
                            name="Status"
                            value={searchBy.Status}
                            onChange={handleChange}
                        >
                            <option value='' >Select</option>
                            <option value='Active'>Active</option>
                            <option value='InActive'>InActive</option>
                        </select>

                    </div>

                    <div className="RegisForm_1">
                        <label htmlFor="Name">
                            Name
                            <span>:</span>
                        </label>
                        <input
                            type="tel"
                            id="Name"
                            name="Name"
                            value={searchBy.Name}
                            onChange={handleChange}
                        />
                    </div>

                    <div className="RegisForm_1">
                        <label htmlFor="mobile">
                            Mobile
                            <span>:</span>
                        </label>
                        <input
                            type="tel"
                            id="Mobile"
                            name="Mobile"
                            value={searchBy.Mobile}
                            onChange={handleChange}
                        />
                    </div>


                    <button
                        className="search_div_bar_btn_1"
                        onClick={handleNewMaster}
                        title="New Doctor Register"
                    >
                        <LoupeIcon />
                    </button>

                </div>


                <ReactGrid columns={UserarryColumn} RowData={Userarry} />

            </div>

            <ToastAlert Message={toast.message} Type={toast.type} />

        </>
    )
}

export default UserRegistrationList