import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { differenceInYears, subYears, format } from "date-fns";
import ToastAlert from "../../OtherComponent/ToastContainer/ToastAlert";

const UserRegistration = () => {

    const navigate = useNavigate();

    const UrlLink = useSelector(state => state.userRecord?.UrlLink);
    const toast = useSelector(state => state.userRecord?.toast);
    const userRecord = useSelector((state) => state.userRecord?.UserData);
    // const UsercreateEmpdata = useSelector(state => state.userRecord?.UsercreateEmpdata);
    // const UserListId = useSelector(state => state.userRecord?.UserListId);
    const EmployeeListId = useSelector((state) => state.userRecord?.EmployeeListId);

    console.log('EmployeeListId????', EmployeeListId);

    const today = new Date();
    const currentDate = today.toISOString().split('T')[0];

    const dispatchvalue = useDispatch();

    const [errors, setErrors] = useState();

    const [formData, setFormData] = useState({
        name: "",
        gender: "",
        date_of_birth: "",
        age: "",
        mobile: "",
        alt_mobile: "",
        email: "",
        designation: "",
        address: "",
        user_name: "",
        user_password: "",
        update_password: "",
        approveedit: "No",

    });
    const [UserRegistershow, setUserRegistershow] = useState([])
    const [ParentData, setParentData] = useState([]);
    console.log("ParentData", ParentData);
    const [ChildData, setChildData] = useState([]);
    console.log("ChildData", ChildData)
    const [loading, setLoading] = useState(false); // For loading state

    const [checkedItems, setCheckedItems] = useState([
        {
            key: '1',
            value: 'A',
            label: 'Master',
            check: false,
            children: [
                { key: '1-1', value: 'A1-1', label: 'HospitalClinicMaster', check: false },
                { key: '1-2', value: 'A1-2', label: 'UserRegistrationList', check: false },
                { key: '1-3', value: 'A1-3', label: 'MedicinerackMaster', check: false },
                { key: '1-4', value: 'A1-4', label: 'TrayManagementList', check: false },
                { key: '1-5', value: 'A1-5', label: 'Productcategory', check: false },
                { key: '1-6', value: 'A1-6', label: 'InventorySubMasters', check: false },
                { key: '1-7', value: 'A1-7', label: 'ProductMasterList', check: false },
                { key: '1-8', value: 'A1-8', label: 'SupplierMasterList', check: false },
                { key: '1-9', value: 'A1-9', label: 'CustomerMasterList', check: false },

            ]
        },

        {
            key: '2',
            value: 'B',
            label: 'Inventory',
            check: false,
            children: [
                { key: '2-1', value: 'B2-1', label: 'PurchaseOrderList', check: false },
                { key: '2-2', value: 'B2-2', label: 'ItemwisePurchaseOrder', check: false },
                { key: '2-3', value: 'B2-3', label: 'GoodsReceiptNoteList', check: false },
                { key: '2-4', value: 'B2-4', label: 'QuickGoodsRecieptNote', check: false },
                { key: '2-5', value: 'B2-5', label: 'PurchaseReturnList', check: false },
                { key: '2-6', value: 'B2-6', label: 'SerialNoQuelist', check: false },
                { key: '2-7', value: 'B2-7', label: 'SerialNoReport', check: false },
                { key: '2-8', value: 'B2-8', label: 'CurrentStockList', check: false },
                { key: '2-9', value: 'B2-9', label: 'SalesReturnList', check: false },

            ]
        },
        {
            key: '3',
            value: 'C',
            label: 'Cashier',
            check: false,
            children: [
                { key: '3-1', value: 'C3-1', label: 'BillingList', check: false },
                { key: '3-2', value: 'C3-2', label: 'SupplierPayList', check: false },
                { key: '3-3', value: 'C3-3', label: 'SupplierPaidList', check: false },
                { key: '3-4', value: 'C3-4', label: 'CustomerPayList', check: false },
                { key: '3-5', value: 'C3-5', label: 'CustomerPaidList', check: false },


            ]
        },
    ]);

    const handleParentChange = (index) => {
        setCheckedItems((prev) => {
            const updated = [...prev];
            updated[index].check = !updated[index].check;
            if (updated[index].children.length > 0) {
                updated[index].children.forEach((child) => {
                    if (updated[index].check) {
                        child.check = true;
                        setChildData((prev) => [...prev, child.value]);
                    } else {
                        child.check = false;
                        setChildData((prev) => prev.filter((value) => value !== child.value));
                    }
                });
            }
            if (updated[index].check) {
                setParentData((prev) => [...prev, updated[index].value]);
            } else {
                setParentData((prev) => prev.filter((value) => value !== updated[index].value));
            }
            return updated;
        });
    };

    const handleChildChange = (parentIndex, childIndex) => {
        setCheckedItems((prev) => {
            const updated = [...prev];
            updated[parentIndex].children[childIndex].check = !updated[parentIndex].children[childIndex].check;
            if (updated[parentIndex].children[childIndex].check) {
                setChildData((prev) => [...prev, updated[parentIndex].children[childIndex].value]);
            } else {
                setChildData((prev) => prev.filter((value) => value !== updated[parentIndex].children[childIndex].value));
            }
            if (updated[parentIndex].children.some((child) => child.check)) {
                updated[parentIndex].check = true;
                setParentData((prev) => {
                    if (!prev.includes(updated[parentIndex].value)) {
                        return [...prev, updated[parentIndex].value];
                    }
                    return prev;
                });
            } else {
                updated[parentIndex].check = false;
                setParentData((prev) => prev.filter((value) => value !== updated[parentIndex].value));
            }
            return updated;
        });
    };


    useEffect(() => {
        if (EmployeeListId && Object.keys(EmployeeListId).length !== 0) {
            const { Access, SubAccess, Status,...rest } = EmployeeListId;
            setFormData(rest)

            const formattedAccess = Array.isArray(Access) ? Access : (Access ? Access.split(",") : []);
            const formattedSubAccess = Array.isArray(SubAccess) ? SubAccess : (SubAccess ? SubAccess.split(",") : []);

            setCheckedItems((prev) =>
                prev.map((parent) => {
                    const isParentChecked = formattedAccess.includes(parent.value);

                    const updatedChildren = parent.children.map((child) => ({
                        ...child,
                        check: formattedSubAccess.includes(child.value),
                    }));

                    const isAnyChildChecked = updatedChildren.some((child) => child.check);

                    return {
                        ...parent,
                        check: isParentChecked || isAnyChildChecked,
                        children: updatedChildren,
                    };
                })
            );

            setParentData(formattedAccess);
            setChildData(formattedSubAccess);
        }
    }, [EmployeeListId]);



    const handleChange = (e) => {
        const { name, value, pattern } = e.target;


        if (name === 'mobile' || name === 'alt_mobile') {

            if (value.length > 10) {

                const tdata = {
                    message: 'ContactNumber only 10 degits numbers',
                    type: 'warn'
                };
                dispatchvalue({ type: 'toast', value: tdata });

            }

            else {
                setFormData({
                    ...formData,
                    [name]: value,
                });
            }

        }

        if (name === "date_of_birth") {
            const selectedDate = new Date(value);
            const age = differenceInYears(new Date(), selectedDate);
            setFormData((prev) => ({
                ...prev,
                date_of_birth: value,
                age: age >= 0 && age <= 100 ? age : "",
            }));
            return;
        }

        if (name === "age") {
            const intAge = parseInt(value, 10);
            if (!isNaN(intAge) && intAge >= 0 && intAge <= 100) {
                const dobDate = subYears(new Date(), intAge);
                setFormData((prev) => ({
                    ...prev,
                    age: intAge,
                    date_of_birth: format(dobDate, "yyyy-MM-dd"),
                }));
            } else {
                setFormData((prev) => ({
                    ...prev,
                    age: value,
                    date_of_birth: "",
                }));
            }
            return;
        }


        else {

            setFormData({
                ...formData,
                [name]: value,
            });
        }

        if (pattern && value) {

            const validateField = (value, pattern) => {
                if (!value) {
                    return 'Required';
                }
                if (pattern && !new RegExp(pattern).test(value)) {
                    return 'Invalid';
                } else {
                    return 'Valid';
                }


            };

            const error = validateField(value, pattern);
            console.log('kkkk', error);

            setErrors(error);

        }






    };

    const handleSubmit = async (e) => {
        e.preventDefault();


        let requiredFields = [
            'name',
            'mobile',
            'email',
            'user_name',
        ]

        if (Object.keys(EmployeeListId).length === 0) {
            requiredFields.push('user_password')
        }

        const missingFields = requiredFields.filter(
            (field) => !formData[field]
        )

        if (missingFields.length !== 0) {

            const tdata = {
                message: `Please fill out all required fields: ${missingFields.join(", ")}`,
                type: 'warn',
            }
            dispatchvalue({ type: 'toast', value: tdata });

            return;
        }

        if (errors !== '') {
            const exist = errors === 'Invalid'
            if (exist) {

                const tdata = {
                    message: `please fill the field required pattern  :  ${errors}`,
                    type: 'warn'
                }
                dispatchvalue({ type: 'toast', value: tdata });

                return;


            }
        }

        setLoading(true); // Set loading to true when form submission starts

        let senddata = {
            ...formData,
            created_by: userRecord.username,
            location: userRecord.location,
            ChildData: ChildData.join(','),
            ParentData: ParentData.join(','),
        }

        axios.post(`${UrlLink}Masters/employee_rigistraion_userrigistor`, senddata)
            .then((res) => {
                const resres = res.data
                let type = Object.keys(resres)[0]
                let mess = Object.values(resres)[0]
                const tdata = {
                    message: mess,
                    type: type,
                }
                dispatchvalue({ type: 'toast', value: tdata });


                if (type === 'success') {
                    setFormData({
                        name: "",
                        gender: "",
                        date_of_birth: "",
                        age: "",
                        mobile: "",
                        alt_mobile: "",
                        email: "",
                        designation: "",
                        address: "",
                        user_name: "",
                        user_password: "",
                        update_password: "",
                        approveedit: "No",
                    })


                    navigate("/Home/Master");
                    dispatchvalue({ type: "HrFolder", value: "UserRegistrationList" });

                }

            })
            .catch((error) => {
                console.log(error);
            })

        setLoading(false);







    };

    return (
        <>
            <div className="Main_container_app">
                <h3>User Registration</h3>
                <br />
                <div className="RegisFormcon_1">
                    {/* Name */}
                    <div className="RegisForm_1">
                        <label htmlFor="name">
                            <div className="imp_v_star">
                                Name<span className="requirreg12">*</span>
                            </div>
                            <span>:</span>
                        </label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                        />
                    </div>

                    {/* Gender */}
                    <div className="RegisForm_1">
                        <label htmlFor="gender">
                            <div className="imp_v_star">
                                Gender
                            </div>
                            <span>:</span>
                        </label>
                        <select
                            id="gender"
                            name="gender"
                            value={formData.gender}
                            onChange={handleChange}
                        >
                            <option value="">Select</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                            <option value="other">Other</option>
                        </select>
                    </div>

                    {/* Date of Birth */}
                    <div className="RegisForm_1">
                        <label htmlFor="date_of_birth">
                            <div className="imp_v_star">
                                Date of Birth
                            </div>
                            <span>:</span>
                        </label>
                        <input
                            type="date"
                            id="date_of_birth"
                            name="date_of_birth"
                            value={formData.date_of_birth}
                            onChange={handleChange}
                            max={currentDate}
                        />
                    </div>

                    {/* Age */}
                    <div className="RegisForm_1">
                        <label htmlFor="age">
                            <div className="imp_v_star">
                                Age
                            </div>
                            <span>:</span>
                        </label>
                        <input
                            type="number"
                            id="age"
                            name="age"
                            value={formData.age}
                            onChange={handleChange}
                        />
                    </div>

                    {/* Mobile */}
                    <div className="RegisForm_1">
                        <label htmlFor="mobile">
                            <div className="imp_v_star">
                                Mobile<span className="requirreg12">*</span>
                            </div>
                            <span>:</span>
                        </label>
                        <input
                            type="tel"
                            id="mobile"
                            name="mobile"
                            value={formData.mobile}
                            onChange={handleChange}
                        />
                    </div>

                    {/* Alternate Mobile */}
                    <div className="RegisForm_1">
                        <label htmlFor="alt_mobile">
                            <div className="imp_v_star">
                                Alternate Mobile
                            </div>
                            <span>:</span>
                        </label>
                        <input
                            type="tel"
                            id="alt_mobile"
                            name="alt_mobile"
                            value={formData.alt_mobile}
                            onChange={handleChange}
                        />
                    </div>

                    {/* Email */}
                    <div className="RegisForm_1">
                        <label htmlFor="email">
                            <div className="imp_v_star">
                                Email<span className="requirreg12">*</span>
                            </div>
                            <span>:</span>
                        </label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                            required
                            value={formData.email}
                            onChange={handleChange}
                            className={errors === 'Invalid' ? 'invalid' : errors === 'Valid' ? 'valid' : ''}
                        />
                    </div>

                    {/* Designation */}
                    <div className="RegisForm_1">
                        <label htmlFor="designation">
                            <div className="imp_v_star">
                                Designation
                            </div>
                            <span>:</span>
                        </label>
                        <input
                            type="text"
                            id="designation"
                            name="designation"
                            value={formData.designation}
                            onChange={handleChange}
                        />
                    </div>

                    {/* Address */}
                    <div className="RegisForm_1">
                        <label htmlFor="address">
                            <div className="imp_v_star">
                                Address
                            </div>
                            <span>:</span>
                        </label>
                        <textarea
                            id="address"
                            name="address"
                            value={formData.address}
                            onChange={handleChange}
                        ></textarea>
                    </div>

                    {/* Username */}

                </div>

                <br />


                <div className="RegisFormcon_1">

                    <div className="RegisForm_1">
                        <label htmlFor="user_name">
                            <div className="imp_v_star">
                                Username<span className="requirreg12">*</span>
                            </div>
                            <span>:</span>
                        </label>
                        <input
                            type="text"
                            id="user_name"
                            name="user_name"
                            value={formData.user_name}
                            onChange={handleChange}
                        />
                    </div>

                    {/* Password */}
                    {!Object.keys(EmployeeListId).length > 0 ? <div className="RegisForm_1">
                        <label htmlFor="user_password">
                            <div className="imp_v_star">
                                Password<span className="requirreg12">*</span>
                            </div>
                            <span>:</span>
                        </label>
                        <input
                            type="password"
                            id="user_password"
                            name="user_password"
                            value={formData.user_password}
                            onChange={handleChange}
                        />
                    </div>
                        :
                        <div className="RegisForm_1">
                            <label htmlFor="update_password">
                                <div className="imp_v_star">
                                    Update Password
                                </div>
                                <span>:</span>
                            </label>
                            <input
                                type="password"
                                id="update_password"
                                name="update_password"
                                value={formData.update_password}
                                onChange={handleChange}
                                placeholder="Optional"
                            />
                        </div>}



                    {/* approve edit */}
                    <div className="RegisForm_1">
                        <label>
                            Approve Edit <span>:</span>
                        </label>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                width: "120px",
                                gap: "10px",
                            }}
                        >
                            <label style={{ width: "auto" }} htmlFor="ActiveStatus_yes">
                                <input
                                    required
                                    type="radio"
                                    name="approveedit"
                                    value="Yes"
                                    style={{ width: "15px" }}
                                    checked={formData.approveedit === "Yes"}
                                    onChange={handleChange}
                                />
                                Yes
                            </label>
                            <label style={{ width: "auto" }} htmlFor="ActiveStatus_no">
                                <input
                                    required
                                    type="radio"
                                    name="approveedit"
                                    value="No"
                                    style={{ width: "15px" }}
                                    checked={formData.approveedit === "No"}
                                    onChange={handleChange}
                                />
                                No
                            </label>
                        </div>
                    </div>




                </div>

                <br></br>
                <div>
                    <h2 style={{ textAlign: "center", margin: "10px 0", color: "gray", fontSize: "clamp(12px, 1.5vw, 24px)" }}>
                        Access
                    </h2>
                    <div className="DivCenter_container" style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
                        <div className="displayuseraccess displayuseraccess_2233">
                            {checkedItems.map((item, indx) => (
                                <div key={indx} className="displayuseraccess_child" style={{ marginBottom: "20px", width: "30%" }}>
                                    <div style={{ display: "flex", alignItems: "center", marginBottom: "5px" }}>
                                        <input
                                            type="checkbox"
                                            id={item.key}
                                            checked={item.check}
                                            onChange={() => handleParentChange(indx)}
                                        />
                                        <label htmlFor={item.key} className="par_acc_lab" style={{ marginLeft: "10px" }}>
                                            {item.label}
                                        </label>
                                    </div>
 
                                    {item.check && (
                                        <div style={{ display: "flex", flexDirection: "column", marginLeft: "20px" }}>
                                            {item.children.map((child, ind1) => (
                                                <div key={ind1} style={{ display: "flex", alignItems: "center", marginBottom: "5px" }}>
                                                    <input
                                                        type="checkbox"
                                                        id={child.key}
                                                        checked={child.check}
                                                        onChange={() => handleChildChange(indx, ind1)}
                                                    />
                                                    <label htmlFor={child.key} className="chi_acc_lab" style={{ marginLeft: "10px" }}>
                                                        {child.label}
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>


                <div className="Main_container_Btn">
                    <button type="submit" onClick={handleSubmit} disabled={loading}>
                        {Object.keys(EmployeeListId).length > 0 ? 'Update' : 'Save'}
                    </button>
                </div>
            </div>

            <ToastAlert Message={toast.message} Type={toast.type} />
        </>
    );
};

export default UserRegistration;
