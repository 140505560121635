import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import './App.css';
import reportWebVitals from './reportWebVitals';
import { HashRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { Mystore } from './DataStore/Store';


const resizeObserverError = () => {
  const resizeObserverErrDiv = document.getElementById("webpack-dev-server-client-overlay-div");
  const resizeObserverErrStyle = document.getElementById("webpack-dev-server-client-overlay");
  if (resizeObserverErrDiv) resizeObserverErrDiv.style.display = "none";
  if (resizeObserverErrStyle) resizeObserverErrStyle.style.display = "none";
};

window.addEventListener("error", (e) => {
  if (e.message === "ResizeObserver loop completed with undelivered notifications.") {
    e.stopImmediatePropagation();
    resizeObserverError();
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={Mystore}>
  <Router>
    <App />
    </Router>
  </Provider>
);

reportWebVitals();
