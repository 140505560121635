import { combineReducers } from "redux";
import Userdata from "./Userdata";
import Inventory from "./Inventory";


const Rootreducer = combineReducers({
  userRecord: Userdata,
  Inventorydata:Inventory
});
export default Rootreducer;
