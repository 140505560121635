import React, { useCallback, useEffect, useState } from 'react'
import { formatLabel, HandelViewFiledata } from '../../OtherComponent/OtherFunctions';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import LoupeIcon from "@mui/icons-material/Loupe";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from '@mui/icons-material/Visibility';
import ReactGrid from '../../OtherComponent/ReactGrid/ReactGrid';
import ToastAlert from '../../OtherComponent/ToastContainer/ToastAlert';
import ModelContainer from '../../OtherComponent/ModelContainer/ModelContainer';


const CustomerMasterList = () => {

    const dispatchvalue = useDispatch();
    const toast = useSelector(state => state.userRecord?.toast);
    const UrlLink = useSelector(state => state.userRecord?.UrlLink);
    const navigate = useNavigate();
    const userRecord = useSelector((state) => state.userRecord?.UserData);
    


    const[Customerarr,setCustomerarr]=useState([])
    const [getdata, setgetdata] = useState(false);


       const [SearchQuery, setSearchQuery] = useState({
            CustomerId: '',
            CustomerName: '',
            CustomerType: '',
            ContactNumber: '',
        });
    

    // console.log('Customerarr',Customerarr);
    
    const handleNewMaster = () => {
        dispatchvalue({ type: 'CustomerMasterStore', value: {} });
      
        navigate("/Home/Master");
        dispatchvalue({ type: "HrFolder", value: "CustomerMaster" });
        dispatchvalue({ type: "setPreviousFolder", value: null }); 
        dispatchvalue({ type: "showMenu", value: true });
    };





    useEffect(()=>{

        axios.get(`${UrlLink}Inventory/Customer_Master_Link`, { params: { ...SearchQuery, forfilter: true } })
        .then((res)=>{
            // console.log('sss',res.data.customers);   
            
            let getdata=res.data.customers;

            setCustomerarr(getdata && Array.isArray(getdata) ? getdata : [])
        })
        .catch((err)=>{
            console.log(err);            
        })

    },[UrlLink,getdata,SearchQuery])



    const handlestatuschange = useCallback((datas) => {
        axios.post(`${UrlLink}Inventory/Customer_Master_Link`, { CustomerId: datas.CustomerId, StatusEdit: true })
            .then((res) => {
                console.log(res.data);
                let resdata = res.data
                let type = Object.keys(resdata)[0]
                let mess = Object.values(resdata)[0]
                const tdata = {
                    message: mess,
                    type: type,
                }
                dispatchvalue({ type: 'toast', value: tdata });
                setgetdata(prev => !prev)
            })
            .catch((err) => {
                console.log(err);
            })
    }, [])



        const HandelViewFiledata = useCallback((fileval) => {
    
    
            if (fileval) {
                let tdata = {
                    Isopen: false,
                    content: null,
                    type: 'image/jpg'
                };
                if (['data:image/jpeg;base64', 'data:image/jpg;base64'].includes(fileval?.split(',')[0])) {
                    tdata = {
                        Isopen: true,
                        content: fileval,
                        type: 'image/jpeg'
                    };
                } else if (fileval?.split(',')[0] === 'data:image/png;base64') {
                    tdata = {
                        Isopen: true,
                        content: fileval,
                        type: 'image/png'
                    };
                } else if (fileval?.split(',')[0] === 'data:application/pdf;base64') {
                    tdata = {
                        Isopen: true,
                        content: fileval,
                        type: 'application/pdf'
                    };
                }
    
    
                dispatchvalue({ type: 'modelcon', value: tdata });
            } else {
                const tdata = {
                    message: 'There is no file to view.',
                    type: 'warn'
                };
                dispatchvalue({ type: 'toast', value: tdata });
            }
    
    
        }, [dispatchvalue])




        
            const HandelEditdata = useCallback((row) => {
                if (row.Status === 'Inactive') {
                    const tdata = {
                        message: `${row.CustomerId} - ${row.CustomerName} is currently inactive. You need to activate it before editing.`,
                        type: 'warn'
                    };
                    dispatchvalue({ type: 'toast', value: tdata });
                } else {
                    const {
                        Status,
                        id,
                        ...rest
                    } = row;
                    dispatchvalue({ type: 'CustomerMasterStore', value: { ...rest} });

                    navigate("/Home/Master");
                    dispatchvalue({ type: "HrFolder", value: "CustomerMaster" });
                    dispatchvalue({ type: "setPreviousFolder", value: null }); 
                    dispatchvalue({ type: "showMenu", value: true });
                }
            }, [dispatchvalue, navigate])




    const Listcolumns =[
        {
            key:'id',
            name:'S.No',
            frozen: true
        },
        {
            key:'CustomerName',
            name:'Customer Name',
            frozen: true
        },
        {
            key:'ContactPerson',
            name:'Contact Person',
        },
        {
            key:'ContactNumber',
            name:'Contact Number',
        },
        {
            key:'EmailAddress',
            name:'Email Address',
        },
        {
            key:'FileAttachment',
            name: 'Document',
            renderCell: (params) => (
                <>
                    <Button className="cell_btn"
                        onClick={() => HandelViewFiledata(params.row.FileAttachment)}
                    >
                        <VisibilityIcon className="check_box_clrr_cancell" />
                    </Button>
                </>
            )
        },
        {
            key:'Status',
            name:'Status',
            renderCell: (params) => (

                <>
                { userRecord.EmployeeEditApprove === "Yes" ?
                <Button className="cell_btn"
                    onClick={() => handlestatuschange(params.row)}
                >
                    {params.row.Status}
                </Button>
                :
                <> {params.row.Status} </>}
                </>
            )
        },
        {
            key:'Action',
            name:'Action',
            renderCell: (params) => (
                <>
                 { userRecord.EmployeeEditApprove === "Yes" ?
                    <Button className="cell_btn"
                        onClick={() => HandelEditdata(params.row)}
                    >
                        <EditIcon className="check_box_clrr_cancell" />
                    </Button>
                     :<></>}
                </>
            )
        }
    ]


    return (
        <>
            <div className="Main_container_app">
                <h3>Customer Master List</h3>
                <br />
                <div className="RegisFormcon_1">
            {
                Object.keys(SearchQuery).map((field, indx) => (
                    <div className="RegisForm_1" key={indx + 'key'}>
                        <label htmlFor={field}>
                            {formatLabel(field)}
                            <span>:</span>
                        </label>
                        {
                            ['CustomerType'].includes(field) ?
                                <select
                                    id={field}
                                    name={field}
                                    value={SearchQuery[field]}
                                    onChange={(e) => setSearchQuery(prev => ({ ...prev, [field]: e.target.value }))}

                                >
                                    <option value="">Select</option>
                                    {field === 'CustomerType' &&
                                        ['Manufacturer', 'Distributor', 'Wholesaler', 'Retailer', 'OEM', 'Vendor'].map((ele, ind) => (
                                            <option key={ind} value={ele}>{ele === 'OEM' ? 'Original Equipment Manufacturer' : ele}</option>
                                        ))
                                    }
                                  

                                </select>
                                :
                                < input
                                    type={'text'}
                                    id={field}
                                    name={field}
                                    value={SearchQuery[field]}
                                    onChange={(e) => setSearchQuery(prev => ({ ...prev, [field]: e.target.value }))}
                                />
                        }
                    </div>
                ))
            }
        </div>
        <br />

                <div className="Main_container_Btn">
                    <button onClick={handleNewMaster}>
                        <LoupeIcon />
                    </button>
                </div>

                <br />
                <ReactGrid columns={Listcolumns} RowData={Customerarr} />

            </div>

            <ToastAlert Message={toast.message} Type={toast.type} />
            <ModelContainer />
        </>
    )
}

export default CustomerMasterList;