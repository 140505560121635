import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import PurchaseOrderList from "./PurchaseOrder/PurchaseOrderList";
import ItemwisePurchaseOrder from "./PurchaseOrder/ItemwisePurchaseOrder";
import GoodsReceiptNoteList from "./GRN/GoodsReceiptNoteList";
import QuickGoodsRecieptNote from "./GRN/QuickGoodsRecieptNote";
import PurchaseReturnList from "./PurchaseReturn/PurchaseReturnList";
import SerialNoQuelist from "./SerialNoForProduct/SerialNoQuelist";
import SerialNoReport from "./SerialNoForProduct/SerialNoReport";

import CurrentStocklist from "./StockDetials/CurrentStocklist";
import PurchaseOrder from "./PurchaseOrder/PurchaseOrder";
import GoodsReceiptNote from "./GRN/GoodsReceiptNote";
import PurchaseReturn from "./PurchaseReturn/PurchaseReturn";
import SerialNoAssignPage from "./SerialNoForProduct/SerialNoAssignPage";
import SalesReturnList from "./SalesReturn/SalesReturnList";
import SalesReturn from "./SalesReturn/SalesReturn";

const InventoryFolder = () => {

  const activeHrFolder = useSelector((state) => state.userRecord?.HrFolder)
  const showMenu = useSelector((state) => state.userRecord?.showMenu);
  const dispatchvalue = useDispatch()

  // const [activeFolder, setActiveFolder] = useState("InventoryLocation");
  const [showFolders, setShowFolders] = useState(false);


  const UserData = useSelector(state => state.userRecord?.UserData)

  const [subAccess, setSubAccess] = useState([])


  const Allpage=[
    { Id: 'B2-1', label: 'PurchaseOrderList'},
    { Id: 'B2-2', label: 'ItemwisePurchaseOrder'},
    { Id: 'B2-3', label: 'GoodsReceiptNoteList'},
    { Id: 'B2-4', label: 'QuickGoodsRecieptNote'},
    { Id: 'B2-5', label: 'PurchaseReturnList'},
    { Id: 'B2-6', label: 'SerialNoQuelist'},
    { Id: 'B2-7', label: 'SerialNoReport'},
    { Id: 'B2-8', label: 'CurrentStockList'},
    { Id: 'B2-9', label: 'SalesReturnList'}

]

  useEffect(() => {
    console.log(UserData, 'UserData')

    if (UserData) {

      const setAccess2 =
        (UserData.AccessTwo &&
          UserData.AccessTwo.split(',').map(item => item.trim())) ||
        []

      setSubAccess(setAccess2) // Update state for sub access
    }
  }, [UserData]) // Dependency array


  const activeFolder = useSelector((state) => state.userRecord?.activeFolder);

  const handleFolderClick = (folderName) => {

    dispatchvalue({ type: "setPreviousFolder", value: activeFolder }); // Save current folder
    dispatchvalue({ type: "setActiveFolder", value: folderName }); // Navigate to new folder
    dispatchvalue({ type: "showMenu", value: false });  // Close menu when navigating to homepage

  };

  // useEffect(() => {

  //   if (Allpage && subAccess && subAccess.length !== 0) {


  //     const filteredLabels = Allpage
  //     .filter((page) => subAccess.includes(page.Id))
  //     .map((page) => page.label);

  //   let Openpage = 'PurchaseOrderList'
  //   if (filteredLabels.length !== 0){

  //     Openpage=filteredLabels[0] 
    
  //   }

  //     dispatchvalue({ type: "setActiveFolder", value: Openpage });
  //     dispatchvalue({ type: "HrFolder", value: "" }); // Reset HrFolder
  //   }
  //   setShowFolders(true);

  // }, [subAccess, Allpage , dispatchvalue]);


  useEffect(() => {
 
    console.log(activeHrFolder, 'activeHrFolder');
 
    if (activeHrFolder !== "") {
      dispatchvalue({ type: "setActiveFolder", value: activeHrFolder });
      dispatchvalue({ type: "HrFolder", value: "" }); // Reset HrFolder
    }
    setShowFolders(true);
 
  }, [activeHrFolder, dispatchvalue]);



  const renderFolderContent = () => {
    switch (activeFolder) {
      case "PurchaseOrderList":
        return <PurchaseOrderList />;
      case "PurchaseOrder":
        return <PurchaseOrder />;
      case "ItemwisePurchaseOrder":
        return <ItemwisePurchaseOrder />;
      case "GoodsReceiptNoteList":
        return <GoodsReceiptNoteList />;
      case "QuickGoodsRecieptNote":
        return <QuickGoodsRecieptNote />;
      case "PurchaseReturnList":
        return <PurchaseReturnList />;
      case "SerialNoQuelist":
        return <SerialNoQuelist />;
      case "SerialNoReport":
        return <SerialNoReport />;
      case "GoodsReceiptNote":
        return <GoodsReceiptNote />;
      case "SerialNoAssignPage":
        return <SerialNoAssignPage />;
      case "PurchaseReturn":
        return <PurchaseReturn />;
      case "SalesReturnList":
        return <SalesReturnList />;
      case "SalesReturn":
        return <SalesReturn />;

      case "CurrentStockList":
        return <CurrentStocklist />
      default:
        return <PurchaseOrderList />;
    }
  };
  return (
    <div className="folder-container">
      <div className={`folder-box-container ${showFolders ? "animate-show" : ""}`}>


        {showMenu && subAccess.includes('B2-1') && (


          <div onClick={() => handleFolderClick("PurchaseOrderList")}
            className="folder-box" >Purchase Order List</div>

        )}


        {showMenu && subAccess.includes('B2-2') && (


          <div onClick={() => handleFolderClick("ItemwisePurchaseOrder")}
            className="folder-box" >Itemwise Purchase Order</div>

        )}


        {showMenu && subAccess.includes('B2-3') && (


          <div onClick={() => handleFolderClick("GoodsReceiptNoteList")}
            className="folder-box" >Goods Receipt Note List</div>

        )}


        {showMenu && subAccess.includes('B2-4') && (


          <div onClick={() => handleFolderClick("QuickGoodsRecieptNote")}
            className="folder-box" >Quick Goods Reciept Note</div>

        )}





        {showMenu && subAccess.includes('B2-6') && (


          <div onClick={() => handleFolderClick("SerialNoQuelist")}
            className="folder-box" >SerialNo Quelist</div>

        )}


        {showMenu && subAccess.includes('B2-7') && (


          <div onClick={() => handleFolderClick("SerialNoReport")}
            className="folder-box" >SerialNo Report</div>

        )}
        {showMenu && subAccess.includes('B2-5') && (


          <div onClick={() => handleFolderClick("PurchaseReturnList")}
            className="folder-box" >Purchase Return List</div>

        )}

        {showMenu && subAccess.includes('B2-9') && (


          <div onClick={() => handleFolderClick("SalesReturnList")}
            className="folder-box" >Sales Return List</div>

        )}




        {showMenu && subAccess.includes('B2-8') && (
          <div onClick={() => handleFolderClick("CurrentStockList")}
            className="folder-box" >Current StockList</div>

        )}


      </div>

      {renderFolderContent()}
    </div>
  );
};

export default InventoryFolder;
