
import React, {useEffect, useState } from 'react';
import axios from 'axios';
import {useSelector ,useDispatch} from 'react-redux';
import ToastAlert from '../../OtherComponent/ToastContainer/ToastAlert';
import { useNavigate } from 'react-router-dom';


const TrayManagement = () => {

    const UrlLink = useSelector(state => state.userRecord?.UrlLink);

    const toast = useSelector(state => state.userRecord?.toast);
    const userRecord = useSelector((state) => state.userRecord?.UserData);

    const navigate = useNavigate();

    const dispatchvalue = useDispatch();



    const [ShelfArray,setShelfArray]=useState([])

    const [RackArray,setRackArray]=useState([])

    const [ProductArray, setProductArray] = useState([])


    const[mapingState,setmapingState]=useState({
        RackName:'',
        ShelfName:'',
    })



    const [Productmapstate, setProductmapstate] = useState({        
        ItemCode: '',
        ItemName: '',
        ProductCategory:'',
        SubCategory: '',
    });



    useEffect(()=>{

    axios.get(`${UrlLink}Inventory/GET_Product_Detials_For_Tray_link`)
    .then((res)=>{
        // console.log('eeeeee',res.data);
        let data = res.data;

        if(data && Array.isArray(data)){
            setProductArray(data)
        }
    })
    .catch((err)=>{
        console.log(err);        
    })

    },[UrlLink])








  useEffect(()=>{


    const params = {
            SearchLocation: 1, 
            StoreLocation: 1,
            Statuschek:'Statuschek',
        };

        axios.get(`${UrlLink}Masters/Rack_Detials_link`, { params })
        .then((res)=>{
            console.log('kkkk',res.data);

            let data =res.data

            if(Array.isArray(data) && data.length !==0){
                setRackArray(data)
            }
            else{
                setRackArray([])
            }

        })
        .catch((err)=>{
            console.log(err);
        })
    
        if(mapingState.RackName !==''){

        const params = {
            SearchLocation: 1, 
            StoreLocation: 1,  
            RackName: mapingState.RackName,
            Statuschek:'Statuschek',
            BookingStatus:'Available'
        };
    
        axios.get(`${UrlLink}Masters/Shelf_Detials_link`, { params })
        .then((res)=>{
            // console.log(res.data);
            let data =res.data
    
                if(Array.isArray(data) && data.length !==0){
                    setShelfArray(data)
                }
                else{
                    setShelfArray([])
                }
    
        })
        .catch((err)=>{
            console.log(err);
        })
    
        }
    

  },[UrlLink,mapingState])






    const formatLabel = (label) => {

        if (/[a-z]/.test(label) && /[A-Z]/.test(label) && !/\d/.test(label)) {
            return label
                .replace(/([a-z])([A-Z])/g, "$1 $2")
                .replace(/^./, (str) => str.toUpperCase());
        } else {
            return label;
        }
    };


    const HandelChange =(e)=>{

        const {name,value}=e.target

        if(name === 'RackName'){
        
        setmapingState((prev)=>({
            ...prev,
            [name]:value,
            ShelfName: '',
        }))
        }
        else if(name === 'ShelfName'){
           
            setmapingState((prev)=>({
                ...prev,
                [name]:value,
            }))
        }
        else{
            setmapingState((prev)=>({
                ...prev,
                [name]:value
            }))  
        }

    }

    const HandelItemChange =(e)=>{

        const {name,value}=e.target

       if(name === 'ItemCode'){

            let findItem=ProductArray.find((ele)=>+ele.Item_Code === +value)
            
            if(findItem){
            setProductmapstate((prev)=>({
                ...prev,
                [name]:value,
                ItemName: findItem.Item_Name,
                ProductCategory: findItem.Product_Category,
                SubCategory: findItem.Sub_Category,
            }))
            }
            else{
                setProductmapstate((prev)=>({
                    ...prev,
                    [name]:value,
                    ItemName: '',
                    ProductCategory: '',
                    SubCategory: '',
                }))
            }
    
        }
        else if(name === 'ItemName'){

            let findItem=ProductArray.find((ele)=>ele.Item_Name === value)
            
            if(findItem){
            setProductmapstate((prev)=>({
                ...prev,
                [name]:value,
                ItemCode: findItem.Item_Code,
                ProductCategory: findItem.Product_Category,
                SubCategory: findItem.Sub_Category,
            }))
            }
            else{
                setProductmapstate((prev)=>({
                    ...prev,
                    [name]:value,
                    ItemCode: '',
                    ProductCategory:'',
                    SubCategory: '',
                }))
            }
    
        }
        else{

            setProductmapstate((prev)=>({
                ...prev,
                [name]:value,
            }))
        }

    }


    const HandelSave =()=>{

        if(mapingState.ShelfName === '' || Productmapstate.ItemCode === '')
        {   
            const tdata = {
                        message: 'ShelfName and ItemName mandatory',
                        type:'warn',
                    }
        
            dispatchvalue({ type: 'toast', value: tdata });
        }
        else{ 
                let Senddata={
                    ...mapingState,
                    ...Productmapstate,
                    created_by: userRecord?.username || '' ,
                    StoreLocation: 1,  
                }


                axios.post(`${UrlLink}Masters/Difine_Tray_For_Products`,Senddata)
                .then((res)=>{
                    console.log(res.data)
        
                    let resdata =res.data
                    let type = Object.keys(resdata)[0]
                    let mess = Object.values(resdata)[0]
                    const tdata = {
                        message: mess,
                        type: type,
                    }
        
                    dispatchvalue({ type: 'toast', value: tdata });
        
                    if(type ==='success'){
                  
                    navigate("/Home/Master");
                    dispatchvalue({ type: "HrFolder", value: "TrayManagementList" });

                   
                    }
                    else if(type ==='Update'){
                        
                    let ItemConfirmed = window.confirm(mess);

                    if(ItemConfirmed){

                        let Senddata={
                            ...mapingState,
                            ...Productmapstate,
                            created_by: userRecord?.username || '' ,
                            ItemConfirmed:ItemConfirmed,
                            StoreLocation: 1,  
                        }
        
        
                        axios.post(`${UrlLink}Masters/Difine_Tray_For_Products`,Senddata)
                        .then((res)=>{
                            console.log(res.data)
                                            
                            let resdata =res.data
                            let type = Object.keys(resdata)[0]
                            let mess = Object.values(resdata)[0]
                            const tdata = {
                                message: mess,
                                type: type,
                            }
                
                            dispatchvalue({ type: 'toast', value: tdata });
                
                            if(type ==='success'){
                                navigate("/Home/Master");
                                dispatchvalue({ type: "HrFolder", value: "TrayManagementList" });

                            }

                        })
                        .catch((err)=>{
                            console.log(err);                            
                        })



                    }
                    
                    }
        
                })
                .catch((err)=>{
                    console.log(err);
                    
                })
            
    
            
            
            
        }
       
        
    
    }



  return (
   <>
   <div className="Main_container_app">
   <h3>Shelf Management</h3>
   <div className="common_center_tag">
        <span>Shelf Detailes</span>
    </div>
    <br/>

   <div className="RegisFormcon_1">
    {
        Object.keys(mapingState).filter((ele)=>ele !== 'id' ).map((StateName,ind)=>{
        
            let OprationArray=[]
            
            switch(StateName){
                case 'RackName':
                    OprationArray=RackArray;
                    break;
                case 'ShelfName':
                    OprationArray=ShelfArray;
                    break;
                default:
                    OprationArray=[]

            }

            
        return( 
            <div className="RegisForm_1" key={ind+'key'}>
                <label htmlFor={StateName}>{formatLabel(StateName)} <span>:</span></label>

               <select
               id={StateName}
               name={StateName}
               value={mapingState[StateName]}
               onChange={HandelChange}
               disabled={ (mapingState.RackName === '' &&  StateName ==='ShelfName')  }
               >
                <option value=''>Select</option>
                {
                OprationArray.map((option,index)=>(
                    <option key={index} value={option.id}>
                        {StateName === 'RackName' ?option.RackName :
                        StateName === 'ShelfName' ?option.Shelf_Name:''}

                    </option>
                ))
                }
               </select>
            </div>
        )})
    }
   </div>

   <br/>
   <div className="common_center_tag">
        <span>Product Detailes</span>
    </div>
    <br/>


    <div className="RegisFormcon_1">

    {Object.keys(Productmapstate).map((StateName,Ind)=>(

    <div className="RegisForm_1" key={Ind+'key'}>
        <label htmlFor={StateName}>{formatLabel(StateName)} <span>:</span></label>
        {
            <>
            <input
            id={StateName}
            name={StateName}
            value={Productmapstate[StateName]}
            onChange={HandelItemChange}
            type='text'
            list={StateName+'List'}
            disabled={['ProductCategory','SubCategory'].includes(StateName)}
            />
            <datalist
            id={StateName+'List'}
            >
            {
            ProductArray.map((option,index)=>(
            <option key={index+'key'} value={StateName === 'ItemCode' ? option.Item_Code : option.Item_Name}>                         
            </option>
            ))
            } 
            </datalist>
            </>
        }

    
    </div>

    ))
        
    }

    </div>
     <br/>
     <div className="Main_container_Btn">
        <button 
        onClick={HandelSave}
         >
           {mapingState.id?'Update':'Save'} 
        </button>
        </div>

   </div>
   <ToastAlert Message={toast.message} Type={toast.type} />
   </>
  )
}

export default TrayManagement
